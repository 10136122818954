import classNames from 'classnames'
import './ChefsRow.scss'
import { ChefAvatar } from 'src/componentsV2/MenuMealCard/components/ChefAvatar/ChefAvatar'

export const ChefsRow = ({
  chefs,
  size = 'medium',
  condensedCard = false,
  colorText = 'dark',
  entityType = 'bundle'
}) => {
  const chefsRowTitle =
    chefs.length > 1
      ? entityType === 'WINE_BUNDLE'
        ? 'By Multiple wineries'
        : 'By Multiple Chefs'
      : chefs[0]?.name
  return (
    <div
      className={classNames('chefs-row-container', {
        '--no-padding': condensedCard
      })}>
      <div className="chef-row-images">
        {chefs?.map((chef, index) => (
          <ChefAvatar
            key={index}
            size={size}
            inlineStyles={{
              zIndex: chefs.length - index
            }}
            image={chef.image}
            fallbackImage={chef.fallbackImage}
            className={`${
              index === chefs.length - 1
                ? `-no-offset-${size}`
                : `-offset-${size}`
            }`}
            onClick={() => {}}
          />
        ))}
      </div>
      <div className={`chef-row-title -${size} -${colorText}`}>
        {chefsRowTitle}
      </div>
    </div>
  )
}
