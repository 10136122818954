import { formatFixedPrice } from '../../utils/utils'
import { getMealPriceByBox } from 'src/utils/menu'
import { Meal } from 'src/types/meal'

interface GetAddButtonTextParams {
  meal: Meal
  planIsCompleted: boolean
  isExtra: boolean
  isFlexUser: boolean
  noExtraMealPlanIsCompleted: boolean
  currentBOMBox: any
  showPremiumFee: boolean
  useBOMMultiplePrices?: boolean
  isUnityPassActive?: boolean
}

interface GetCondensedPriceTextParams {
  meal: Meal
  planIsCompleted: boolean
  isExtra: boolean
  isFlexUser: boolean
  noExtraMealPlanIsCompleted: boolean
  currentBOMBox: any
  useBOMMultiplePrices?: boolean
  isUnityPassActive?: boolean
}

const formatPriceText = {
  addMeal: (price: number) => `Add meal for ${formatFixedPrice(price)}`,
  addExtra: (price: number) => `Add extra for ${formatFixedPrice(price)}`,
  addFor: (price: number) => `Add for ${formatFixedPrice(price)}`,
  addMealPlusFee: (fee: number) => `Add meal + ${formatFixedPrice(fee)}`
}

interface CommonPriceLogicParams {
  meal: Meal
  planIsCompleted: boolean
  isExtra: boolean
  isFlexUser: boolean
  noExtraMealPlanIsCompleted: boolean
  currentBOMBox: any
  useBOMMultiplePrices?: boolean
  isUnityPassActive?: boolean
  showPremiumFee?: boolean
  isCondensed?: boolean
}

const handleBOMBoxPrice = (
  meal: Meal,
  currentBOMBox: any,
  finalPrice: number,
  useBOMMultiplePrices?: boolean
) => {
  let mealPrice = finalPrice
  if (useBOMMultiplePrices && meal.prices?.length) {
    const boxPrice = getMealPriceByBox(meal.prices, currentBOMBox)
    mealPrice = boxPrice?.finalPrice ?? finalPrice
  }
  return mealPrice
}

const getPriceText = ({
  meal,
  planIsCompleted,
  isExtra,
  isFlexUser,
  noExtraMealPlanIsCompleted,
  currentBOMBox,
  useBOMMultiplePrices = false,
  isUnityPassActive = false,
  showPremiumFee = false,
  isCondensed = false
}: CommonPriceLogicParams) => {
  const finalPrice = meal.finalPrice ?? meal.price + (meal.premium_fee ?? 0)

  switch (true) {
    case !!meal.isFreePremiumMeal:
      return isCondensed ? '' : 'Select meal'
    case !!currentBOMBox:
      const mealPrice = handleBOMBoxPrice(
        meal,
        currentBOMBox,
        finalPrice,
        useBOMMultiplePrices
      )
      return formatPriceText.addFor(mealPrice)
    case noExtraMealPlanIsCompleted && !isExtra:
      return isCondensed ? '' : 'Add meal'
    case isUnityPassActive &&
      !meal.premium_special &&
      (meal.premium_fee ?? 0) > 0 &&
      !planIsCompleted:
      return formatPriceText.addMealPlusFee(meal.premium_fee ?? 0)
    case isFlexUser && isExtra:
      return formatPriceText.addFor(finalPrice)
    case isExtra:
      return formatPriceText.addExtra(finalPrice)
    case planIsCompleted:
      return formatPriceText.addMeal(finalPrice)
    case isFlexUser && (meal.premium_fee ?? 0) > 0:
      return formatPriceText.addFor(finalPrice)
    case (meal.premium_fee ?? 0) > 0:
      if (isCondensed) {
        return formatPriceText.addMealPlusFee(meal.premium_fee ?? 0)
      }
      return showPremiumFee
        ? formatPriceText.addMealPlusFee(meal.premium_fee ?? 0)
        : 'Add meal'
    case isFlexUser:
      return formatPriceText.addMeal(finalPrice)
    default:
      return isCondensed ? '' : 'Add meal'
  }
}

export const getAddButtonText = (params: GetAddButtonTextParams) => {
  return getPriceText({ ...params, isCondensed: false })
}

export const getCondensedPriceText = (params: GetCondensedPriceTextParams) => {
  return getPriceText({ ...params, isCondensed: true })
}
