import { createSelector } from 'reselect'
import { pathOr } from 'ramda'

export const getCurrentFilters = createSelector(
  state => state.filters,
  filters => ({
    diets: filters.diets,
    preferences: filters.preferences,
    proteins: filters.proteins,
    cuisines: filters.cuisines
  })
)

export const getCurrentChefFilter = createSelector(
  state => state.filters,
  filters => filters.chef || []
)

export const hidePremiumFilter = createSelector(
  state => state.filters,
  filters => filters.hidePremiumFilter || false
)

export const getCurrentSortBy = createSelector(
  state => state.filters,
  filters => filters.sortBy
)

export const getCurrentSelectedProfilePreferences = createSelector(
  state => state.filters,
  filters => filters.selectedProfilePreference
)

export const getCurrentQuickFilter = createSelector(
  state => state.filters,
  filters => filters.quickFilterSelected
)

export const getSearchInput = createSelector(
  state => state.filters,
  filters => filters.searchInput
)

export const getRangeMacronutrientsFilters = createSelector(
  state => state.filters,
  filters => filters.macronutrientsRangeFilters
)

export const getSortingTypes = state =>
  pathOr(null, ['filters', 'sortingTypes'], state)

export const getMenuSortingSelected = state =>
  pathOr(null, ['filters', 'menuSortingSelected'], state)

export const getFilteredMeals = state =>
  pathOr([], ['filters', 'filteredMeals'], state)

export const getCategoryId = state =>
  pathOr([], ['filters', 'selectedCategoryId'], state)

export const getAlreadyRequestedMeals = state => {
  const res = pathOr([], ['menu', 'alreadyRequestedMeals', 'meals'], state)
  return res
}

export const getMenuSelectedLabel = state =>
  pathOr([], ['filters', 'menuSelected'], state)

export const getFullMenuProductTypeFilters = state =>
  pathOr([], ['filters', 'fullMenuProductTypes'], state)

export const getFullMenuProductTypeSelectedFilters = state =>
  pathOr([], ['filters', 'fullMenuProductTypesSelected'], state)

export const getMealsFilters = state => {
  if (!state.filters.filterTypes) {
    return {
      diets: [],
      preferences: [],
      proteins: [],
      cuisines: []
    }
  }

  return {
    diets: state.filters.filterTypes.diets,
    preferences: state.filters.filterTypes.preferences,
    proteins: state.filters.filterTypes.proteins,
    cuisines: state.filters.filterTypes.cuisines
  }
}

export const getSettings = state =>
  pathOr([], ['api', 'subscription', 'settings'], state)

export const getMenuSelected = createSelector(
  state => state.filters,
  filters => filters.menuSelected
)

export const getFullMenuCategories = createSelector(
  state => state.filters,
  filters => filters.menuItems
)

export const getFullMenuSelectedCategory = createSelector(
  state => state.filters,
  filters => filters.menuSelected
)

export const getMenuItems = createSelector(
  state => state.filters,
  filters => filters.menuItems
)

export const getFullMenuProductTypes = createSelector(
  state => state.filters,
  filters => filters.fullMenuProductTypes
)
