import './PauseDeflectionModal.scss'

import { CuiButton, CuiModal } from 'front-lib'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useAtom } from 'jotai'

import { planMealsQtySelector } from 'src/components/DeliveryCard/selectors'
import { RoutePaths } from 'src/routes/routePaths'
import { track } from 'src/shared/tracking'
import {
  PAUSE_DEFLECTION_REASON,
  PAUSE_PLAN_TARGET_MEAL_QTY,
  getPauseDeflectionChangePlanTitle
} from 'src/pages/myPlan/components/PausePlanSettings/utils'
import { getDevice, getDeviceOS } from 'src/utils/utils'
import { getUserAddresses } from 'src/modules/MyAccount/components/Settings/selectors'
import { getAddress } from 'src/modules/MyDeliveries/utils'
import { getAllowPlanChange } from 'src/redux-api-bridge/selectors'
import { pauseDeflectionModalAtom } from 'src/atoms/modals/pauseDeflectionModal.atom'
import { useChangePlanModal } from 'src/hooks/useChangePlanModal'
import { usePausePlan } from 'src/hooks/usePausePlan'

enum Option {
  CHANGE_ADDRESS = 'Change Address',
  CHANGE_PLAN = 'Change Plan'
}

export function PauseDeflectionModal() {
  const { t } = useTranslation()
  const planMealsQty = useSelector(state => planMealsQtySelector(state))
  const userAddresses = useSelector(getUserAddresses)
  const addressLabel = userAddresses ? getAddress(userAddresses[0]) : '-'
  const allowPlanChange = useSelector(getAllowPlanChange)
  const [{ isOpen, pauseDuration }, setPauseDeflectionModal] = useAtom(
    pauseDeflectionModalAtom
  )
  const { open } = useChangePlanModal()
  const { pausePlan } = usePausePlan()

  const history = useHistory()

  useEffect(() => {
    track('Overlay Viewed', {
      overlay_name: 'Manage Your Order - Pause',
      overlay_class: 'modal',
      overlay_source: 'custom',
      overlay_type: 'button',
      page_name: 'My Plan',
      overlay_trigger: 'manual',
      overlay_action: 'open',
      device: getDevice(),
      device_os: getDeviceOS()
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function trackOptionClicked(option: Option) {
    let action
    if (option === 'Change Plan') {
      action =
        planMealsQty > PAUSE_PLAN_TARGET_MEAL_QTY
          ? 'Downgrade Plan'
          : 'Change Plan'
    }
    track('Overlay Clicked', {
      overlay_name: 'Manage Your Order - Pause',
      overlay_class: 'modal',
      overlay_source: 'custom',
      overlay_type: 'button',
      page_name: 'My Plan',
      overlay_trigger: 'manual',
      overlay_options: `${action}, Change Address`,
      overlay_action: action,
      overlay_step: '1/1',
      device: getDevice(),
      device_os: getDeviceOS()
    })
  }

  function handleClose() {
    track('Overlay Closed', {
      overlay_name: 'Manage Your Order - Pause',
      overlay_class: 'modal',
      overlay_source: 'custom',
      overlay_type: 'button',
      page_name: 'My Plan',
      overlay_trigger: 'manual',
      device: getDevice(),
      device_os: getDeviceOS()
    })

    closeModal()
  }

  async function handleChangePlan() {
    await trackOptionClicked(Option.CHANGE_PLAN)
    closeModal()
    open()
  }

  async function handleChangeAddress() {
    trackOptionClicked(Option.CHANGE_ADDRESS)
    closeModal()
    history.push(RoutePaths.ACCOUNT)
  }

  function handlePausePlan() {
    pausePlan({ reason: PAUSE_DEFLECTION_REASON, weeks: pauseDuration })
    closeModal()
  }

  function closeModal() {
    setPauseDeflectionModal({ isOpen: false, pauseDuration: 0 })
  }

  return (
    <CuiModal
      overlayClassName="pause-deflection-modal"
      isOpen={isOpen}
      onRequestClose={handleClose}>
      <div className="title">{t('pauseDeflection.title')}</div>
      <div className="options">
        {allowPlanChange && (
          <div className="option">
            <div
              className="left change-plan-image"
              style={{
                backgroundImage: `url(https://cu-product-media.s3.amazonaws.com/media/manage-order/change-plan.jpeg)`
              }}
            />
            <div className="right">
              <div className="title">
                {t(
                  getPauseDeflectionChangePlanTitle({
                    planMealsQty
                  })
                )}
              </div>
              <div className="subtitle">
                {t('pauseDeflection.changePlan.subtitle_1')}
              </div>
              <div className="divider" />
              <div className="subtitle subtitle-2">
                {t('pauseDeflection.changePlan.subtitle_2')}
              </div>
              <div className="footer">
                <div className="subtitle-highlight">
                  {planMealsQty} {t('pauseDeflection.changePlan.unit_quantity')}
                </div>
                <button className="cta" onClick={handleChangePlan}>
                  {t('pauseDeflection.changePlan.cta')}
                </button>
              </div>
            </div>
          </div>
        )}

        <div className="option">
          <div
            className="left change-address-image"
            style={{
              backgroundImage: `url(https://cu-product-media.s3.amazonaws.com/media/manage-order/change-address.jpeg)`
            }}
          />
          <div className="right">
            <div className="title">
              {t('pauseDeflection.changeAddress.title')}
            </div>
            <div className="subtitle">
              {t('pauseDeflection.changeAddress.subtitle_1')}
            </div>
            <div className="divider" />
            <div className="subtitle subtitle-2">
              {t('pauseDeflection.changeAddress.subtitle_2')}
            </div>
            <div className="footer">
              <div className="subtitle-highlight">{addressLabel}</div>
              <button className="cta" onClick={handleChangeAddress}>
                {t('pauseDeflection.changeAddress.cta')}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="pause-plan-container">
        <CuiButton className="pause-cta" onClick={handlePausePlan}>
          Pause Plan
        </CuiButton>
        <CuiButton className="cancel-cta" onClick={handleClose}>
          Cancel
        </CuiButton>
      </div>
    </CuiModal>
  )
}
