import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { CoachmarkTooltip } from 'src/componentsV2/Coachmark/CoachmarkTooltip'
import { CoachmarkContextValues, CoachmarkState } from 'src/types/coachmark'
import localStorage from 'src/utils/localStorage'
import { useLocalStorage } from './useLocalStorage'

const CoachmarkContext = createContext<CoachmarkContextValues | null>(null)

const PADDING_Y = 6
const PADDING_X = 10

export const CoachmarkProvider = ({ children }: any) => {
  const [coachmark, setCoachmark] = useState<CoachmarkState | null>(null)
  const [coachmarks, setCoachmarks] = useState<Array<CoachmarkState>>([])
  const [showed, setShowed] = useState(false)
  const [shouldShow, setShouldShow] = useLocalStorage<boolean | null>(
    `${coachmark?.id}-coachmark` ?? '',
    null
  )

  const dom = useMemo(() => {
    if (coachmark?.selector) {
      return document
        .getElementById(coachmark.selector)
        ?.getBoundingClientRect()
    }
    return coachmark?.ref?.current?.getBoundingClientRect()
  }, [coachmark])

  useEffect(() => {
    if (showed) {
      return
    }

    if ((shouldShow === false || !coachmark) && coachmarks.length > 0) {
      const newArr = [...coachmarks]
      const newCoachmark = newArr.shift()

      setCoachmarks(prev => {
        const newArr = [...prev]
        newArr.shift()
        return newArr
      })

      if (newCoachmark) {
        setCoachmark(newCoachmark)
      }
    }
  }, [coachmark, coachmarks, showed, shouldShow])

  useEffect(() => {
    if (shouldShow && dom) {
      document.body.classList.add('no-scroll')
    } else {
      document.body.classList.remove('no-scroll')
    }
  }, [shouldShow, dom])

  const addCoachmark = (cm: CoachmarkState) => {
    const idx = coachmarks.findIndex(({ id }) => id === cm.id)

    if (idx === -1) {
      return setCoachmarks(prev => [...prev, cm])
    }

    setCoachmarks(prev => {
      const newArr = [...prev]
      newArr[idx] = cm
      return newArr
    })
  }

  const removeCoachmark = () => {
    setShouldShow(false)
    setShowed(true)
    setCoachmark(null)
    document.body.classList.remove('no-scroll')
  }

  const onClick = () => {
    removeCoachmark()
    coachmark?.onClick()
  }

  const onClose = () => {
    removeCoachmark()
    coachmark?.onClose()
  }

  const computeStyles = useCallback(() => {
    if (!dom) {
      return {}
    }

    return {
      top: dom.top - PADDING_Y,
      left: dom.left - PADDING_X,
      height: dom.height + PADDING_Y * 2,
      width: dom.width + PADDING_X * 2
    }
  }, [dom])

  return (
    <CoachmarkContext.Provider
      value={{ coachmark, addCoachmark, shouldShow, showed }}>
      {children}
      {coachmark && dom && shouldShow && (
        <>
          <div
            className="coachmark-overlay"
            style={computeStyles()}
            onClick={onClick}>
            <div onClick={e => e.stopPropagation()}>
              <CoachmarkTooltip
                coachmark={coachmark}
                onClick={onClick}
                onClose={onClose}
              />
            </div>
            <div className="coachmark-overlay-arrow"></div>
          </div>
          <div className="coachmark-overlay-helper" onClick={onClose}></div>
        </>
      )}
    </CoachmarkContext.Provider>
  )
}

export const useCoachmark = (coachmark: CoachmarkState, skip?: boolean) => {
  const ctx = useContext(CoachmarkContext)
  const localStorageId = `${coachmark.id}-coachmark`

  if (!ctx) {
    throw Error('Missing coachmark provider')
  }

  if (localStorage.get(localStorageId) === null) {
    localStorage.set(localStorageId, true)
  }

  useEffect(() => {
    if (skip) return

    if (document.getElementsByClassName('ReactModal__Overlay').length > 0) {
      return
    }

    ctx.addCoachmark(coachmark)

    // if (!ctx.showed && ctx.coachmark !== coachmark) {
    //   ctx?.setCoachmark(coachmark)
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return null
}
