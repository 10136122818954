import moment from 'moment'
import {
  getUpcomingDeliveries,
  getReschedulableDays
} from 'src/modules/MyDeliveries/selectors'
import { OrderComponent, UpcomingDelivery } from 'src/types/upcomingDelivery'
import {
  getCartByDate,
  getMeals,
  getMealsFromCart,
  getRecommendations
} from 'src/modules/MyDeliveries/utils'
import { getCarts } from 'src/redux-api-bridge/order/selectors'
import {
  copyStatusSelector,
  orderStatusSelector
} from 'src/components/DeliveryCard/selectors'
import { COPY_STATUS } from 'src/components/DeliveryCard/constants'
import { ORDER_STATUS } from 'src/components/DeliveryCard/constants'
import { MerchandiseSetWrapper } from 'src/types/merchandiseSet'
import { AppState } from 'src/types/appState'
import { getRing } from 'src/modules/MyDeliveries/selectors'

export const selectedDeliverySelector = (
  state: AppState
): UpcomingDelivery | null => {
  const deliveries: UpcomingDelivery[] = getUpcomingDeliveries(state)
  const index = selectedDeliveryIndexSelector(state)
  return deliveries[index]
}

export const isRescheduleAvaiableSelector = (
  state: AppState,
  date: string | undefined
) => {
  const reschedulableDays = getReschedulableDays(state)
  const dateMoment = moment(date)
  const startOfWeek = dateMoment.clone().startOf('week')
  const endOfWeek = dateMoment.clone().endOf('week')

  const avaiableReschedules = reschedulableDays.filter(day => {
    if (day.date === dateMoment.format('YYYY-MM-DD')) {
      return true
    }
    const date = moment(day.date)
    return date.isSameOrAfter(startOfWeek) && date.isSameOrBefore(endOfWeek)
  })

  return avaiableReschedules?.length > 0
}

export const selectedDeliveryMealsSelector = (state): any[] => {
  const delivery = selectedDeliverySelector(state)
  const carts = getCarts(state)

  if (!delivery) {
    return []
  }
  if (delivery.order) {
    return getMeals(delivery.order.items)
  }
  if (delivery.recommendation) {
    return getRecommendations(delivery.recommendation.meals || [])
  }
  const cart = getCartByDate(carts, delivery.date)
  if (cart) {
    return getMealsFromCart(cart)
  }
  return []
}

export const selectedDeliveryIndexSelector = (state: AppState): number => {
  return state.selectedDelivery.index
}

export const isDeliveryEmptySelector = (state: AppState) => {
  const delivery = selectedDeliverySelector(state)
  const meals = selectedDeliveryMealsSelector(state)
  return delivery?.menuAvailable && meals.length === 0
}

export const isPartialCartSelector = (state): boolean => {
  const delivery = selectedDeliverySelector(state)
  const carts = getCarts(state)
  const cart = getCartByDate(carts, delivery?.date)
  return cart && !delivery?.recommendation && !delivery?.order
}

export const isDeliveryOnHolidaySelector = (state: AppState) => {
  const delivery = selectedDeliverySelector(state)
  return delivery?.holidayMessage
}

export const showDeliverySelector = (state: AppState) => {
  const delivery = selectedDeliverySelector(state)
  const meals = selectedDeliveryMealsSelector(state)
  return delivery && meals.length > 0
}

export const orderStatusForTrackingSelector = (state: AppState) => {
  const delivery = selectedDeliverySelector(state)
  if (!delivery) {
    return ''
  }
  const orderStatus = orderStatusSelector(state, delivery.displayDate)
  if (orderStatus === ORDER_STATUS.PAUSED) {
    return 'paused'
  }
  const copyStatus = copyStatusSelector(state, delivery.displayDate)
  switch (copyStatus) {
    case COPY_STATUS.EMPTY_ORDER:
      return 'to-be-completed'
    case COPY_STATUS.HOLIDAY:
    case COPY_STATUS.NO_MENU:
      return 'without-menu'
    case COPY_STATUS.ORDER_CONFIRMED:
      return 'confirmed'
    case COPY_STATUS.PARTIAL_ORDER:
    case COPY_STATUS.ORDER_FULLFILLED_BUT_NOT_REQUESTED:
      return 'partial-cart'
    case COPY_STATUS.ORDER_IN_SHIPPING_DAY:
      return 'shipped'
    case COPY_STATUS.ORDER_REQUESTED:
    case COPY_STATUS.ORDER_REQUEST_PENDING:
      return 'confirmed-pending'
    case COPY_STATUS.ORDER_SKIPPED:
      return 'skipped'
    default:
      return ''
  }
}

export const ctaCopySelector = (state: AppState): string => {
  const delivery = selectedDeliverySelector(state)

  if (!delivery) {
    return ''
  }

  // TODO clean after Jan 5th 2025
  const ring = getRing(state)
  const ringId = ring?.id
  const UNAVAILABLE_RINGS = [11, 12, 19, 28, 35, 45, 49]
  const isRingUnavailable = UNAVAILABLE_RINGS.includes(ringId)

  const hideReschedule =
    isRingUnavailable &&
    moment() < moment('2025-01-06') &&
    moment(delivery.date) >= moment('2024-12-22') &&
    moment(delivery.date) <= moment('2024-12-29')

  const copyStatus = copyStatusSelector(state, delivery.displayDate)

  switch (copyStatus) {
    case COPY_STATUS.EMPTY_ORDER:
      return 'Browse Menu'
    case COPY_STATUS.PARTIAL_ORDER:
      return 'Browse Menu'
    case COPY_STATUS.ORDER_FULLFILLED_BUT_NOT_REQUESTED:
      if (delivery.recommendation) {
        return 'Review Order'
      }
      return 'Browse Menu'
    case COPY_STATUS.ORDER_REQUESTED:
    case COPY_STATUS.ORDER_REQUEST_PENDING:
      return 'Edit Meals'
    case COPY_STATUS.ORDER_CONFIRMED:
    case COPY_STATUS.ORDER_IN_SHIPPING_DAY:
      return 'Track Order'
    case COPY_STATUS.ORDER_SKIPPED:
      return 'Unskip Order'
    case COPY_STATUS.HOLIDAY:
      return hideReschedule ? '' : 'Reschedule'
    case COPY_STATUS.NO_MENU:
    default:
      return ''
  }
}

const merchandiseForSelectedDeliverySelector = (
  state: AppState
): MerchandiseSetWrapper | undefined => {
  const delivery = selectedDeliverySelector(state)
  if (!delivery) {
    return undefined
  }
  const merch = state.merchandiseSets.data.find(
    item => item.deliveryDate === delivery.date
  )
  return merch
}

export const showChooseMealsMsgSelector = (state: AppState): boolean => {
  const merch = merchandiseForSelectedDeliverySelector(state)
  const isLoading = isLoadingMerchandiseSelector(state)
  return !isLoading && !merch
}

export const shouldFetchMerchandiseSelector = (state: AppState): boolean => {
  const merch = merchandiseForSelectedDeliverySelector(state)
  return !merch
}

export const isLoadingMerchandiseSelector = (state: AppState): boolean => {
  return state.merchandiseSets.inFlight || state.ordersSwimlanes.isLoading
}

export const selectedDeliveryHasProductsSelector = (
  state: AppState
): boolean => {
  const delivery = selectedDeliverySelector(state)
  if (!delivery) {
    return false
  }
  const cart = state.orders.data[delivery.date]?.items || []
  const hasCart = cart.length > 0
  const hasRecommendation = delivery.recommendation != null
  const hasOrder = delivery.order != null
  return hasCart || hasRecommendation || hasOrder
}

export const selectedDeliveryHasDrinksOrderSelector = (
  state: AppState
): OrderComponent | undefined => {
  const delivery = selectedDeliverySelector(state)
  const drinksOrder = delivery?.orderComponents?.find(
    order => order?.provider === 'drinksAnywhere'
  )
  return drinksOrder
}
