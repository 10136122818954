import { useEffect } from 'react'
import { CuiModal, CuiButton } from 'front-lib'
import { useAtom } from 'jotai'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useExperiment } from 'src/hooks/useExperiment'

import { deflectionWithActivePromoModalAtom } from 'src/atoms/modals/deflectionWithPromoActiveModal.atom'
import { useSkipOrder } from 'src/hooks/useSkipOrder'
import { usePausePlan } from 'src/hooks/usePausePlan'
import { useCancelSubscription } from 'src/hooks/useCancelSubscription'
import { useDeflectionWithPromoCopies } from './hooks/useDeflectionWithPromoCopies'
import { getNextMenuUpcomingDay } from 'src/modules/MyPlan/selectors'
import tracking from 'src/shared/tracking'
import { FF_SKIP_DEFLECTION_COPY_TEST } from 'src/constants/feature-flags'

import './DeflectionWithActivePromoModal.scss'

// Extend the feature flag to match the expected Experiment interface
const experimentConfig = {
  ...FF_SKIP_DEFLECTION_COPY_TEST,
  variantsMap: {
    disabled: 'disabled',
    control: FF_SKIP_DEFLECTION_COPY_TEST.variantsMap.control,
    treatment1: FF_SKIP_DEFLECTION_COPY_TEST.variantsMap.treatment1
  }
}

export function DeflectionWithPromoActiveModal() {
  const history = useHistory()
  const nextMenu = useSelector(getNextMenuUpcomingDay)
  const { variant } = useExperiment(experimentConfig)
  const [
    { isOpen, payload, type, credits, promotions },
    setDeflectionWithPromoActiveModal
  ] = useAtom(deflectionWithActivePromoModalAtom)

  const {
    title,
    description,
    detail,
    cancelText,
    confirmText
  } = useDeflectionWithPromoCopies({ payload, type, credits, promotions })

  const { skipOrder } = useSkipOrder({ date: payload.displayDate })
  const { cancelSubscription } = useCancelSubscription()
  const { pausePlan } = usePausePlan()

  useEffect(() => {
    const promotionData = (promotions || []).map(promotion => {
      return {
        name: promotion.offer,
        activationId: promotion.promotionActivationId,
        expiration: promotion.expirationDate
      }
    })

    tracking.track('Overlay Viewed', {
      overlay_name: `Growth - Activation - Skip&Pause Deflection Strategy with Promo Available`,
      overlay_type: type,
      overlay_promotion: promotionData,
      overlay_credit: credits,
      ab_test_name: FF_SKIP_DEFLECTION_COPY_TEST.experiment.name,
      ab_test_variant: variant || 'control'
    })
    // eslint-disable-next-line
  }, [])

  function handleConfirm() {
    switch (type) {
      case 'cancel':
        cancelSubscription(payload)
        break
      case 'pause':
        pausePlan(payload)
        break
      case 'skip':
        skipOrder()
        break
      default:
        console.log('Unknown action type:', type || 'unknown')
    }
    handleClose()
  }

  function handleCancel() {
    tracking.track('Button Clicked', {
      button_name: cancelText,
      button_action: 'use_promo',
      overlay_name: `Growth - Activation - Skip&Pause Deflection Strategy with Promo Available`,
      overlay_type: type,
      ab_test_name: FF_SKIP_DEFLECTION_COPY_TEST.experiment.name,
      ab_test_variant: variant || 'control'
    })

    handleClose()
    const date = payload.date || nextMenu?.date
    history.push(`/meals?date=${date}`)
  }

  function handleClose() {
    setDeflectionWithPromoActiveModal({
      isOpen: false,
      payload: {},
      type: '',
      credits: '',
      promotions: []
    })
  }

  return (
    <CuiModal
      isOpen={isOpen}
      mediumSize
      onRequestClose={handleClose}
      className={`deflection-with-active-promo ${type}-deflection`}>
      <div className="deflection-content">
        <div>
          <div className="deflection-title">{title}</div>
          <div className="deflection-description">
            {description}
            {detail && <div className="deflection-description">{detail}</div>}
          </div>
        </div>

        <div className="deflection-actions">
          <CuiButton fill="outline" onClick={handleConfirm}>
            {confirmText}
          </CuiButton>
          <CuiButton onClick={handleCancel}>{cancelText}</CuiButton>
        </div>
      </div>
    </CuiModal>
  )
}
