import { getChefs } from 'src/componentsV2/MenuBundleCard/utils'
import * as api from '../../api'

import * as actions from './actions'
import { ADDONS_CATEGORIES, MEALS_CATEGORY } from 'src/constants/menu'

export const getMealDetail = meal_id => dispatch => {
  dispatch(actions.getMealDetail())
  return api
    .getMealDetail({ meal_id })
    .then(async ({ data }) => {
      const mealData = mealMapper(data.meal)
      dispatch(
        actions.getMealDetailSuccess({
          ...mealData
        })
      )
    })
    .catch(err => dispatch(actions.getMealDetailFail(err)))
}

export const getMealLiteDetail = inventoryId => dispatch => {
  dispatch(actions.getMealDetail())
  return api
    .getMealsLiteDetail(inventoryId)
    .then(async ({ data }) => {
      if (data?.mealsLite?.bundle || data?.mealsLite?.meal) {
        const isBundle = !!data?.mealsLite?.bundle
        const mealData = isBundle
          ? bundleMapper({ ...data.mealsLite.bundle, inventoryId: inventoryId })
          : mealMapper({ ...data.mealsLite.meal, inventoryId: inventoryId })

        dispatch(actions.getMealDetailSuccess(mealData))
      } else {
        dispatch(actions.getMealDetailFail())
      }
    })
    .catch(err => dispatch(actions.getMealDetailFail(err)))
}

export const getMealNutritionInfo = inventoryId => dispatch => {
  dispatch(actions.getMealNutritionInfo(inventoryId))
  return api
    .getMealNutritionInfo(inventoryId)
    .then(({ data }) => {
      if (data?.mealDetailsLite) {
        dispatch(
          actions.getMealNutritionInfoSuccess({
            labelNutritionalFacts: {
              servingsPerPackage: data.mealDetailsLite.servingsPerPackage,
              servingSize: data.mealDetailsLite.servingSize,
              nutrients: data.mealDetailsLite.nutrients
            },
            mealId: inventoryId
          })
        )
      } else {
        dispatch(actions.getMealNutritionInfoFail(inventoryId))
      }
    })
    .catch(() => dispatch(actions.getMealNutritionInfoFail(inventoryId)))
}

export const setLikeMeal = magentoId => dispatch => {
  dispatch(actions.setLikeMeal(magentoId))
}

export const cleanLikeMeal = () => dispatch => {
  dispatch(actions.cleanLikeMeal())
}

const mealMapper = meal => {
  const newMeal = {
    magento_id: meal.id || meal.mealExternalId,
    id: meal.id || meal.mealExternalId,
    meal_story: meal.mealStory,
    reviews_count: meal.reviews,
    premium_special: meal.premiumSpecial,
    nutritionInfo: meal.nutritionalFacts,
    image: { full_path: meal.image },
    calories: meal.nutritionalFacts && Number(meal.nutritionalFacts.calories),
    ingredients_statement: meal.ingredientsStatement,
    allergenic_statement: meal.allergenicStatement,
    short_description: meal.shortDescription,
    heating_instructions_videos: meal.heatingInstructionsVideos,
    promotions: meal.promotions,
    assortment: meal.assortment,
    isBundle: false,
    imageUrl: meal.image,
    protein_type: meal.meatType,
    specifications_detail: meal.specificationsDetails
      ? meal.specificationsDetails.map(specification => ({
          ...specification
        }))
      : [],
    warning: meal.warnings?.message,
    warnings: {
      message: meal.warnings?.message,
      restrictions_applied: meal.warnings?.restrictionsApplied,
      allergens_not_matching: meal.warnings?.allergensNotMatching,
      diets_not_matching: meal.warnings?.dietsNotMatching
    },
    relatedMeals: meal.relatedMeals
  }

  if (
    meal.cookingSteps &&
    (meal.cookingSteps.ovenSteps || meal.cookingSteps.microwaveSteps)
  ) {
    newMeal.cookingSteps = {
      oven_steps: meal.cookingSteps.ovenSteps,
      microwave_steps: meal.cookingSteps.microwaveSteps
    }
  }
  if (meal.chef) {
    newMeal.chef_id = meal.chef.id
    newMeal.chef_firstname = meal.chef.firstName
    newMeal.chef_lastname = meal.chef.lastName
    newMeal.bannerpic = meal.chef.bannerPic
  }
  if (meal.userReviews) {
    newMeal.reviews = meal.userReviews.map(review => ({
      review_id: review.id,
      customer_name: review.customerFirstName,
      detail: review.feedback,
      created_at: review.createdAt,
      stars: review.rating
    }))
  }

  if (!!meal.nutrients && meal.servingsPerPackage && meal.servingSize) {
    newMeal.labelNutritionalFacts = {
      servingsPerPackage: meal.servingsPerPackage,
      servingSize: meal.servingSize,
      nutrients: meal.nutrients
    }
  }

  return { ...meal, ...newMeal }
}

const bundleMapper = bundle => {
  const newBundle = {
    inventoryId: bundle.inventoryId,
    entity_id: bundle.id,
    isBundle: true,
    name: bundle.name,
    short_description: bundle.subtitle,
    meal_story: bundle.description,
    image: {
      full_path: bundle.image
    },
    chefs: getChefs(bundle),
    inStock: bundle.stock > 0,
    category_id: bundle.meals.some(meal => meal.categoryId === MEALS_CATEGORY)
      ? MEALS_CATEGORY
      : ADDONS_CATEGORIES[0],
    meals: bundle.meals.map(meal => mealMapper(meal)),
    price: bundle.price,
    priceWithoutPlanMeal: bundle.priceWithoutPlanMeal,
    finalPrice: bundle.finalPrice,
    originalPriceWithoutPlanMeal: bundle.originalPriceWithoutPlanMeal
  }
  return { ...bundle, ...newBundle }
}
