import { getImpersonator, getTokenSilently } from '../auth0'

const SDUI_SERVICE_URL = process.env.REACT_APP_SDUI_SERVICE_URL

function isMobile() {
  const regex = /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i
  return regex.test(navigator.userAgent)
}

async function getAuthorizationHeaders() {
  const impersonator = getImpersonator()
  if (impersonator) {
    return {
      'x-cook-impersonation-secret': impersonator.secret,
      'x-cook-impersonation-email': impersonator.email
    }
  }
  const token = await getTokenSilently()
  return {
    Authorization: token ? `${token}` : ''
  }
}

async function getHeaders() {
  return {
    platform: isMobile() ? `mobile-web` : 'web',
    'accept-version': '1.20.0',
    ...(await getAuthorizationHeaders())
  }
}

export const fetchOrdersSwimlanes = async utm => {
  const headers = await getHeaders()
  return fetch(`${SDUI_SERVICE_URL}/view/tabs${utm ? `?utm=${utm}` : ''}`, {
    headers,
    credentials: 'include'
  })
}

export const fetchUserPreferencesData = async () => {
  const headers = await getHeaders()
  const response = await fetch(`${SDUI_SERVICE_URL}/preferences`, {
    headers,
    credentials: 'include'
  })

  return response.json()
}

export const fetchUserPreferencesView = async () => {
  const headers = await getHeaders()
  const response = await fetch(`${SDUI_SERVICE_URL}/view/preferences`, {
    headers,
    credentials: 'include'
  })

  return response.json()
}

export const saveUserPreferencesData = async payload => {
  const headers = {
    ...(await getHeaders()),
    'Content-type': 'application/json'
  }

  return fetch(`${SDUI_SERVICE_URL}/preferences`, {
    headers,
    method: 'POST',
    body: JSON.stringify(payload),
    credentials: 'include'
  })
}

export const removeUserProfileRequest = async payload => {
  const headers = {
    ...(await getHeaders()),
    'Content-type': 'application/json'
  }

  return fetch(`${SDUI_SERVICE_URL}/preferences/removeProfile`, {
    headers,
    method: 'POST',
    body: JSON.stringify(payload),
    credentials: 'include'
  })
}

export const getCartForDate = async date => {
  const headers = await getHeaders()
  return fetch(`${SDUI_SERVICE_URL}/menu/cart/${date}`, {
    headers,
    credentials: 'include'
  })
}

export const addProduct = async (date, inventoryId, quantity, entityId) => {
  const headers = {
    ...(await getHeaders()),
    'Content-type': 'application/json'
  }
  return fetch(
    `${SDUI_SERVICE_URL}/menu/v2/cart/${date}/product/${inventoryId}`,
    {
      headers,
      method: 'POST',
      body: JSON.stringify({ quantity, entityId }),
      credentials: 'include'
    }
  )
}

export const removeProduct = async (date, inventoryId, entityId) => {
  const headers = {
    ...(await getHeaders()),
    'Content-type': 'application/json'
  }
  return fetch(
    `${SDUI_SERVICE_URL}/menu/v2/cart/${date}/product/${inventoryId}`,
    {
      headers,
      method: 'DELETE',
      body: JSON.stringify({ entityId }),
      credentials: 'include'
    }
  )
}

export const removeAllProducts = async date => {
  const headers = await getHeaders()
  return fetch(`${SDUI_SERVICE_URL}/menu/cart/${date}`, {
    headers,
    method: 'DELETE',
    credentials: 'include'
  })
}

export const getProductsList = async params => {
  const headers = await getHeaders()
  return fetch(`${SDUI_SERVICE_URL}/products?${params}`, {
    headers,
    credentials: 'include'
  })
}

export const getUnifiedCartForDate = async date => {
  const headers = await getHeaders()
  return fetch(`${SDUI_SERVICE_URL}/cart/v2/${date}`, {
    headers,
    credentials: 'include'
  })
}

export const addProductsToUnifiedCart = async (cartId, products) => {
  const headers = {
    ...(await getHeaders()),
    'Content-type': 'application/json'
  }
  return fetch(`${SDUI_SERVICE_URL}/cart/v2/${cartId}/products`, {
    headers,
    method: 'POST',
    body: JSON.stringify({ products }),
    credentials: 'include'
  })
}

export const removeProductFromUnifiedCart = async (cartId, products) => {
  const headers = {
    ...(await getHeaders()),
    'Content-type': 'application/json'
  }
  return fetch(`${SDUI_SERVICE_URL}/cart/v2/${cartId}/products`, {
    headers,
    method: 'DELETE',
    body: JSON.stringify({ products }),
    credentials: 'include'
  })
}

export const getMealPrices = async (date, meals) => {
  const headers = await getHeaders()

  const response = await fetch(`${SDUI_SERVICE_URL}/menu/meals/price`, {
    headers: {
      ...headers,
      'Content-type': 'application/json'
    },
    method: 'POST',
    body: JSON.stringify({ date, meals }),
    credentials: 'include'
  })

  return response.json()
}

export const fetchChefs = async date => {
  const headers = await getHeaders()

  if (date) {
    const response = await fetch(`${SDUI_SERVICE_URL}/chef?date=${date}`, {
      headers,
      credentials: 'include'
    })

    return await response.json()
  }
}

export const addProfile = async payload => {
  const headers = {
    ...(await getHeaders()),
    'Content-type': 'application/json'
  }

  return fetch(`${SDUI_SERVICE_URL}/preferences/profile`, {
    headers,
    method: 'POST',
    body: JSON.stringify(payload),
    credentials: 'include'
  })
}

export const rateProduct = async (orderId, productId, payload) => {
  const headers = {
    ...(await getHeaders()),
    'Content-type': 'application/json'
  }

  return fetch(
    `${SDUI_SERVICE_URL}/order/${orderId}/product/${productId}/review`,
    {
      headers,
      method: 'POST',
      body: JSON.stringify(payload),
      credentials: 'include'
    }
  )
}

export const getPaymentChallenge = async payload => {
  const headers = {
    ...(await getHeaders()),
    'Content-type': 'application/json'
  }

  return fetch(`${SDUI_SERVICE_URL}/payments/challenges`, {
    headers,
    method: 'POST',
    body: JSON.stringify(payload),
    credentials: 'include'
  })
}

export const updateUserDateOfBirth = async dateOfBirth => {
  const headers = {
    ...(await getHeaders()),
    'Content-type': 'application/json'
  }

  return fetch(`${SDUI_SERVICE_URL}/user/date-of-birth`, {
    headers,
    method: 'PATCH',
    body: JSON.stringify({
      date_of_birth: dateOfBirth
    }),
    credentials: 'include'
  })
}

export const getViewCartForDate = async date => {
  const headers = await getHeaders()

  return fetch(`${SDUI_SERVICE_URL}/view/v1/cart/${date}`, {
    headers,
    credentials: 'include'
  })
}
