import * as R from 'ramda'
import {
  getOrderStatus,
  getBrowseEventCtaCopyFromOrderStatus
} from '../components/DeliveryCard/utils'
import tracking from '../shared/tracking'
import store from '../store'
import {
  findSpecificationDetail,
  formatMealRating,
  getProteinTag,
  isNew
} from '../componentsV2/MenuMealCard/utils'

import { checkIfMealExistIntoWishListProfiles, getUniqueChefs } from './utils'
import { FILTER_TYPES } from '../modules/MyPlan/components/Filters/constants'
import { orderToTrackMapper } from './mappers'
import { getCurrentDate } from 'src/modules/MyPlan/selectors'
import { isBundle, trackingProductType } from 'src/common/MealMenu/utils'
import {
  orderStatusForTrackingSelector,
  selectedDeliverySelector
} from 'src/redux/slices/selectedDelivery/selectors'
import {
  getCurrentChefFilter,
  getCurrentFilters,
  getCurrentQuickFilter,
  getCurrentSelectedProfilePreferences,
  getCurrentSortBy,
  hidePremiumFilter,
  getFullMenuProductTypeSelectedFilters,
  getRangeMacronutrientsFilters
} from 'src/redux-api-bridge/filters/selectors'
import { getActiveOrder } from 'src/redux-api-bridge/order/selectors'
import {
  getMenuSearch,
  mealsSearchExactMatchResults,
  mealsSearchRelatedResults
} from 'src/redux-api-bridge/menu/selectors'
import { getResurrectionTrackingData } from './resurrection-tracking-utils'

export const PRODUCT_ADD_SOURCES = {
  HOME_SWIMLANE: 'home-swimlane',
  SUBSCRIPTION_HOME: 'subscription-home',
  CART_DETAIL: 'Cart Detail',
  PRODUCT_LIST: 'Home Product List',
  SEARCH_VIEW: 'Menu / Search revamp',
  MENU: 'Menu',
  MEAL_DETAIL: 'Meal Detail',
  COLLECTION: 'Collection'
}

export const PRODUCT_LIST_TYPES = {
  REGULAR: 'Regular',
  COLLECTION: 'Collection',
  SWIMLANE: 'Home Swimlane',
  PRODUCT_LIST: 'Home Product List',
  MENU: 'Menu',
  HOME_MEAL_LIST: 'Home Meal List',
  CROSS_SELLING_MODAL: 'cross_selling_modal'
}

export const PAGE_NAMES = {
  PRODUCT_LIST: 'Home Product List',
  MENU: 'Menu / Product Selection',
  ORDERS: 'Orders'
}

export const ORDER_STATUS = {
  SHIPPED: 'shipped'
}

export const TAB_RATING_STATUS = {
  RATES_AVAILABLE: 'Rates available',
  NO_RATES_AVAILABLE: 'No Rates available'
}

export const EVENT_NAME = {
  CALENDAR_TAB_SELECTED: 'Calendar Tab Selected'
}

const PRODUCT_LIST_FILTERED = 'Product List Filtered'

export const getProductProps = product => {
  const isBundleProduct = isBundle(product)
  const isNewBundle = isBundleProduct ? product.isNew : null
  const mealIds = isBundleProduct
    ? product.meals.map(meal => String(meal.id))
    : null
  return {
    product_id: product.entity_id,
    inventory_id: product.inventoryId,
    product_type: trackingProductType(product),
    product_chef_name: isBundleProduct
      ? product.meals
          .map(meal => `${meal.chef_firstname} ${meal.chef_lastname}`)
          .join(', ')
      : `${product.chef_firstname} ${product.chef_lastname}`,
    product_category: product.category,
    collection: product.collectionName,
    product_image_url: product.imageUrl || product.imageFullUrl,
    product_ingredients:
      product.ingredients_data || product.ingredientsStatement,
    product_price: product.price,
    product_final_price: product.finalPrice,
    is_new_bundle: isNewBundle,
    bundle_meals: mealIds,
    product_rating: product.stars,
    product_rating_count: product.reviews,
    ratio_carb: R.path(['nutritional_facts', 'carbs'], product),
    ratio_fat: R.path(['nutritional_facts', 'fat'], product),
    ratio_protein: R.path(['nutritional_facts', 'protein'], product),
    product_specification:
      product.specificationsDetails &&
      product.specificationsDetails.map(({ label }) => label),
    product_premium: product.is_premium,
    product_line: product.menu_category_label
  }
}

// Tags: Rating, Calories, Protein and Spicy
export const getProductBottomTags = product => {
  return [
    !isNew(product) &&
      parseInt(
        Array.isArray(product.reviews) ? product.reviews_count : product.reviews
      ) > 0 &&
      parseInt(formatMealRating(product.stars)) > 0 &&
      'Rating',
    product.calories && 'Calories',
    getProteinTag(product.protein_type || product.meatType) && 'Protein',
    findSpecificationDetail(product.specificationsDetails, 'spicy') && 'Spicy'
  ].filter(tag => Boolean(tag))
}

const getProfilesInfo = state =>
  R.pathOr(null, ['api', 'subscription', 'info', 'profiles'], state)

const getSortName = currentSortBy => {
  const sortName = currentSortBy?.[0] && currentSortBy[0].name
  const sortLabel =
    (currentSortBy?.[0].order && `: ${currentSortBy[0].order.label}`) || ''
  return `${sortName}${sortLabel}`
}

const getOrderProductsCount = state => {
  const active = state.orders.active
  const items = active ? state.orders.data[active]?.items : []
  let cartTotalQuantity = 0
  items?.forEach(i => {
    cartTotalQuantity += i.qty
  })
  return cartTotalQuantity
}

const getProductListType = product => {
  if (product.collectionName) return PRODUCT_LIST_TYPES.COLLECTION
  if (product.swimlaneName) return PRODUCT_LIST_TYPES.SWIMLANE
  if (product.productListName) return PRODUCT_LIST_TYPES.PRODUCT_LIST
  return PRODUCT_LIST_TYPES.MENU
}

const getProductListName = product => {
  if (product.searchName) return product.searchName
  if (product.collectionName) return product.collectionName
  if (product.swimlaneName) return product.swimlaneName
  if (product.productListName) return product.productListName
  return product.menu_category_label
}

export const getProductPropsV2 = product => {
  const state = store.getState()
  const profiles = getProfilesInfo(state)
  const productType = trackingProductType(product)
  const isBundleProduct = isBundle(product)
  const isNewBundle = isBundleProduct ? product.isNew : null
  const mealIds = isBundleProduct
    ? product.meals.map(meal => String(meal.id))
    : null
  return {
    bundle_meals: mealIds,
    inventory_id: product.inventoryId,
    is_new_bundle: isNewBundle,
    product_category: product.category,
    product_chef_id: product.chef?.id,
    product_chef_name: `${product.chef?.firstName ||
      product.chef_firstname ||
      ''} ${product.chef?.lastName || product.chef_lastname || ''}`,
    product_final_price: product.finalPrice,
    product_id: product.entity_id,
    product_image_url: product.imageUrl || product.imageFullUrl,
    product_initial_capacity: product.stock,
    product_is_in_stock: (product.stock || 0) > 0,
    product_is_premium: product.is_premium,
    product_is_user_favorite: checkIfMealExistIntoWishListProfiles(
      product.id,
      profiles
    ),
    product_name: product.name,
    product_price: product.price,
    product_rating: product.stars,
    product_rating_count: product.reviews,
    product_sku: product.sku,
    product_type: productType,
    product_url: `/products/${product.inventoryId}`
  }
}

export const trackRemoveProduct = (
  product,
  quantityRemoved,
  pageName,
  productRemoveSource,
  tags,
  listName,
  listType
) => {
  const state = store.getState()

  const cartTotalQuantity = getOrderProductsCount(state) - quantityRemoved

  const productTags = tags?.map(tag => ({
    tag_positiion: 'Top',
    tag_name: tag.label
  }))

  const cartProductQuantity =
    (product.quantity || product.qty) - quantityRemoved

  tracking.track('Product Removed', {
    page_name: pageName,
    page_position: product.index,
    cart_product_quantity: cartProductQuantity,
    cart_quantity_removed: quantityRemoved,
    cart_total_quantity: cartTotalQuantity,
    list_name: listName || getProductListName(product),
    list_type: listType || getProductListType(product),
    product_ingredients:
      product.ingredients_data || product.ingredientsStatement,
    product_nutritional_info: product.nutritionalFacts,
    product_remaining_capacity: product.stock - cartProductQuantity,
    product_remove_source: productRemoveSource,
    product_specification:
      product.specificationsDetails &&
      product.specificationsDetails.map(({ label }) => label),
    ratio_carb: R.path(['nutritional_facts', 'carbs'], product),
    ratio_fat: R.path(['nutritional_facts', 'fat'], product),
    ratio_protein: R.path(['nutritional_facts', 'protein'], product),

    product_tags: productTags,
    ...getProductPropsBySource(productRemoveSource),
    ...getProductPropsV2(product),
    ...getMenuGenericProps()
  })
}

const getProductPropsBySource = productAddSource => {
  const state = store.getState()

  if (
    productAddSource === PRODUCT_ADD_SOURCES.HOME_SWIMLANE ||
    productAddSource === PRODUCT_ADD_SOURCES.CART_DETAIL
  )
    return {}

  if (productAddSource === PRODUCT_ADD_SOURCES.SEARCH_VIEW) {
    const searchView = getMenuSearch(state)
    const exactMatchMeals = mealsSearchExactMatchResults(state)
    const suggestedMeals = mealsSearchRelatedResults(state)
    const mealsSuggested = [...exactMatchMeals, ...suggestedMeals]
    return {
      count_products_displayed: mealsSuggested.length,
      filters_applied: [
        {
          filter_type: 'Search',
          filter_group: searchView.origin,
          filter_value: searchView.searchedValue
        }
      ]
    }
  }

  const currentFilters = getCurrentFilters(state)
  const currentChefFilter = getCurrentChefFilter(state)
  const currentQuickFilter = getCurrentQuickFilter(state)
  const currentSortBy = getCurrentSortBy(state)
  const currentSelectedProfilePreferences = getCurrentSelectedProfilePreferences(
    state
  )
  const hidePremiumFilterEnabled = hidePremiumFilter(state)
  const currentRangeMacronutrientsFilters = getRangeMacronutrientsFilters(state)

  const searchInput = state.filters?.searchInput || ''
  const { name: menuBySelectedName = '' } = state.filters.menuSelected || {}
  const countProductsDisplayed = state.filters?.filteredMeals?.length || 0

  return {
    count_products_displayed: countProductsDisplayed,
    filters_applied: getFilters(
      currentFilters,
      currentChefFilter,
      currentQuickFilter,
      searchInput,
      menuBySelectedName,
      currentSelectedProfilePreferences,
      hidePremiumFilterEnabled,
      [],
      [],
      currentRangeMacronutrientsFilters
    ),
    sort_name: getSortName(currentSortBy)
  }
}

export const trackAddProduct = (
  product,
  quantityAdded,
  pageName,
  productAddSource,
  tags,
  listName,
  listType
) => {
  const state = store.getState()
  const cartTotalQuantity = getOrderProductsCount(state) + quantityAdded
  const productTags = tags?.map(tag => ({
    tag_positiion: 'Top',
    tag_name: tag.label
  }))

  const cartProductQuantity = (product.quantity || product.qty) + quantityAdded

  tracking.track('Product Added', {
    page_name: pageName,
    page_position: product.index,
    cart_product_quantity: cartProductQuantity,
    cart_quantity_added: quantityAdded,
    cart_total_quantity: cartTotalQuantity,
    list_name: listName || getProductListName(product),
    list_type: listType || getProductListType(product),
    product_ingredients:
      product.ingredients_data || product.ingredientsStatement,
    product_nutritional_info: product.nutritionalFacts,
    product_remaining_capacity: product.stock - cartProductQuantity,
    product_added_source: productAddSource,
    product_specification:
      product.specificationsDetails &&
      product.specificationsDetails.map(({ label }) => label),
    ratio_carb: R.path(['nutritional_facts', 'carbs'], product),
    ratio_fat: R.path(['nutritional_facts', 'fat'], product),
    ratio_protein: R.path(['nutritional_facts', 'protein'], product),

    product_tags: productTags,
    ...getProductPropsBySource(productAddSource),
    ...getProductPropsV2(product),
    ...getMenuGenericProps()
  })
}

export const trackBackToTopBtnClicked = (props, context) => {
  tracking.track('BackToTop Clicked', {
    ...props,
    ...context
  })
}

export const trackCrossSellProductClicked = (
  relatedMealId,
  pageName,
  action
) => {
  const state = store.getState()
  const profiles = getProfilesInfo(state)
  const relatedMeal = state.menu.menu.meals
    .filter(_meal => _meal.id === relatedMealId)
    .pop()

  if (relatedMeal) {
    tracking.track('Cross Sell Product Clicked', {
      list_name: relatedMeal.collectionName || relatedMeal.menu_category_label,
      list_type: relatedMeal.collectionName
        ? PRODUCT_LIST_TYPES.COLLECTION
        : PRODUCT_LIST_TYPES.REGULAR,
      ...getProductProps(relatedMeal),
      product_add_source: 'Cross Sell In Meal Card',
      product_top_tag: R.path(['feature', 'name'], relatedMeal),
      product_bottom_tag: getProductBottomTags(relatedMeal),
      product_line:
        relatedMeal.categories &&
        relatedMeal.categories.map(({ label }) => label),
      product_is_favorite: checkIfMealExistIntoWishListProfiles(
        relatedMeal.id,
        profiles
      ),
      product_name: relatedMeal.name,
      page_name: pageName,
      page_position: relatedMeal.index + 1,
      menu_by: relatedMeal.Menu_by || '',
      menu_by_option: relatedMeal.Menu_by_option || '',
      action: action
    })
  }
}

export const trackClickedProduct = (product, href, pageName, source = '') => {
  tracking.track('Product Clicked', {
    href,
    page_name: pageName,
    page_position: product.index + 1,
    list_name: getProductListName(product),
    list_type: getProductListType(product),
    product_click_source: source,
    ...getProductProps(product),
    ...getMenuGenericProps()
  })
}

export const trackSeeMoreProducts = (categoryName, subcategoryName = null) => {
  tracking.track('See more products', {
    page_name: 'Meal Selection',
    category_name: categoryName,
    subcategory_name: subcategoryName,
    ...getMenuGenericProps()
  })
}

export const trackCategoryHeaderSeen = (
  categoryName,
  subcategoryName = null
) => {
  tracking.track('Category Viewed', {
    page_name: 'Meal Selection',
    category_name: categoryName,
    subcategory_name: subcategoryName,
    ...getMenuGenericProps()
  })
}

export const trackModuleViewed = (
  moduleName,
  pageName,
  moduleType,
  productId
) => {
  tracking.track('Content Module Viewed', {
    module_name: moduleName,
    page_name: pageName,
    module_type: moduleType,
    product_id: productId
  })
}

const PRODUCT_TILE_SEEN_TRACKED_MAP = {}

const shouldTrackProductTileSeen = (
  inventoryId,
  productCategory,
  productListType
) => {
  if (productListType === 'swimlane') return true
  const state = store.getState()
  const date = getCurrentDate(state)?.date || ''
  const alreadyTrackedCategoryMap =
    PRODUCT_TILE_SEEN_TRACKED_MAP[`${productCategory}-${date}`]
  if (!!alreadyTrackedCategoryMap) {
    if (!!alreadyTrackedCategoryMap[inventoryId]) {
      return false
    }
    PRODUCT_TILE_SEEN_TRACKED_MAP[`${productCategory}-${date}`] = {
      ...PRODUCT_TILE_SEEN_TRACKED_MAP[`${productCategory}-${date}`],
      [inventoryId]: true
    }
  } else {
    PRODUCT_TILE_SEEN_TRACKED_MAP[`${productCategory}-${date}`] = {
      [inventoryId]: true
    }
  }
  return true
}

export const trackProductTileSeen = (
  productId,
  pagePosition,
  countProductsDisplayed,
  pageName,
  stock,
  sku,
  premium,
  isLiked,
  bottomTag,
  topTag,
  productListName,
  productListType,
  productType,
  inventoryId,
  finalPrice,
  isNewBundle,
  mealIds,
  productCategory
) => {
  if (
    shouldTrackProductTileSeen(inventoryId, productCategory, productListType)
  ) {
    tracking.track('Product Tile Seen', {
      page_name: pageName,
      product_id: productId,
      page_position: pagePosition,
      count_products_displayed: countProductsDisplayed,
      product_is_in_stock: stock > 0,
      product_sku: sku,
      product_is_user_favorite: isLiked,
      product_is_premium: premium,
      product_top_tag: topTag,
      product_bottom_tag: bottomTag,
      list_name: productListName,
      list_type: productListType,
      product_type: productType,
      inventory_id: inventoryId,
      product_final_price: finalPrice,
      is_new_bundle: isNewBundle,
      bundle_meals: mealIds,
      ...getMenuGenericProps()
    })
  }
}

export const trackClearedCart = (allProducts, mealsCount, pageName) => {
  const products = allProducts.map(product => getProductProps(product))
  tracking.track('Cart Cleared', {
    products,
    cart_quantity_removed: mealsCount,
    page_name: pageName
  })
}

export const trackEventOrderConfirmed = (
  currentOrder,
  currentDate,
  selectedTimeslot,
  pageName
) => {
  const state = store.getState()
  const orderStatus = orderStatusForTrackingSelector(state)
  const orderDetail = getActiveOrder(state)?.orderDetail
  tracking.track(
    'Order Confirmed',
    orderToTrackMapper({
      currentOrder,
      currentDate,
      selectedTimeslot,
      pageName,
      orderStatus,
      orderDetail
    })
  )
}

/*
page_name - if it's web, name of the page where the user saw the experiment - doesn't apply if it's the mobile app
screen_name - if it's mobile app, name of the screen where the user saw the experiment - doesn't apply if it's web
experiment_id - gro-sc-52902
experiment_name - Growth - Resurrection - Redirect to Meal Selection
variation_name - control / treatment-1
*/

export const trackReactivatedUserRedirectedToOrder = screenName => {
  tracking.trackExperiment(
    'gro-sc-52902',
    'Growth - Resurrection - Redirect to Meal Selection',
    'treatment-1',
    screenName
  )
}

export const trackReactivatedUserNotRedirectedToOrder = screenName => {
  tracking.trackExperiment(
    'gro-sc-52902',
    'Growth - Resurrection - Redirect to Meal Selection',
    'control',
    screenName
  )
}

export const trackResurrectionFunnelExperiment = (
  screenName,
  variant = 'CONTROL'
) => {
  tracking.track('Growth - Resurrection - Funnel Experiment', {
    screen_name: screenName,
    variant
  })
}

export const trackSadPageRefreshExperiment = (
  screenName,
  variant = 'control',
  brightbackData = null
) => {
  tracking.track('Growth - Resurrection - Sad Page Refresh Test', {
    screen_name: screenName,
    variant,
    ...(brightbackData && {
      brightback_sentiment: brightbackData.sentiment,
      brightback_reason: brightbackData.reason,
      brightback_feedback: brightbackData.feedback,
      brightback_competition: brightbackData.competition,
      from_brightback: true
    })
  })
}

export const trackSadPageRefreshButtonClicked = (action, screenName) => {
  tracking.track('Growth - Resurrection - Sad Page Refresh Button Clicked', {
    action,
    screen_name: screenName
  })
}

export const trackResurrectionButtonClicked = (action, screenName) => {
  tracking.track(action, {
    page_name: screenName
  })
}

export const trackResurrectionLandingPage = (action, screenName) => {
  tracking.track(action, {
    page_name: screenName
  })
}

const getFilters = (
  currentFilters,
  currentChefFilter,
  currentQuickFilter,
  searchInput,
  menuBySelectedName,
  currentPersonalizedFilter,
  premiumFilterApplied,
  chefs = [],
  fullMenuProductTypeSelectedFilters = [],
  macronutrientFilters = {}
) => {
  const filterTypeLabel = FILTER_TYPES.QUICK

  const listCurrentFilters = Object.entries(currentFilters).flatMap(
    ([key, value]) =>
      value.map(item => {
        return {
          filter_type: key.includes(menuBySelectedName.toLowerCase())
            ? filterTypeLabel
            : FILTER_TYPES.ADVANCED,
          filter_group: key,
          filter_value: item.name
        }
      })
  )

  const listCurrentChefs = currentChefFilter.reduce((accum, elem) => {
    if (elem.value) {
      const chef = chefs.find(chef => chef.id === elem.value)
      return [
        ...accum,
        {
          filter_type:
            'chef' === menuBySelectedName?.toLowerCase()
              ? filterTypeLabel
              : FILTER_TYPES.ADVANCED,
          filter_group: 'Chef',
          filter_value: `${chef?.firstName} ${chef?.lastName}`
        }
      ]
    }
    return accum
  }, [])

  const currentQuick = currentQuickFilter
    ? [
        {
          filter_type: filterTypeLabel,
          filter_group: 'All',
          filter_value: currentQuickFilter?.label ?? 'All'
        }
      ]
    : []

  const currentSearchInput =
    searchInput && searchInput !== ''
      ? [
          {
            filter_type: FILTER_TYPES.SEACRH,
            filter_group: '',
            filter_value: searchInput
          }
        ]
      : []

  const currentPersonalized = currentPersonalizedFilter
    ? [
        {
          filter_type: FILTER_TYPES.PERSONALIZED,
          filter_group: '',
          filter_value: currentPersonalizedFilter?.firstname || ''
        }
      ]
    : []

  const forYouFilter =
    menuBySelectedName === 'For you'
      ? [
          {
            filter_type: FILTER_TYPES.QUICK,
            filter_group: 'For you',
            filter_value: 'For you'
          }
        ]
      : []

  const premiumFilter = premiumFilterApplied
    ? [
        {
          filter_type: FILTER_TYPES.ADVANCED,
          filter_group: 'premium',
          filter_value: 'Hide'
        }
      ]
    : []
  const fullMenuProductTypeFilters = []

  fullMenuProductTypeSelectedFilters.forEach(filter => {
    if (filter) {
      if (!filter.filterTypes || filter.filterTypes.length === 0) {
        fullMenuProductTypeFilters.push({
          filter_type: FILTER_TYPES.ADVANCED,
          filter_group: 'Categories',
          filter_value: filter?.filterName
        })
      } else {
        filter.filterTypes.forEach(filterType => {
          fullMenuProductTypeFilters.push({
            filter_type: FILTER_TYPES.ADVANCED,
            filter_group: 'Categories',
            filter_value: filterType?.name
          })
        })
      }
    }
  })

  const macronutrientFilter = Object.values(macronutrientFilters || {})
    .filter(mf => mf.applied)
    .map(mf => {
      const capitalizedGroupName = mf.name[0].toUpperCase() + mf.name.slice(1)
      return {
        filter_type: FILTER_TYPES.ADVANCED,
        filter_group: `${capitalizedGroupName}-Range`,
        filter_value: `${mf.currentMinRange}-${mf.currentMaxRange}`,
        filter_range_min: mf.currentMinRange,
        filter_range_max: mf.currentMaxRange
      }
    })

  return [
    ...listCurrentFilters,
    ...listCurrentChefs,
    ...currentQuick,
    ...currentSearchInput,
    ...currentPersonalized,
    ...forYouFilter,
    ...premiumFilter,
    ...fullMenuProductTypeFilters,
    ...macronutrientFilter
  ]
}

export const trackFilterSelected = (
  filteredMeals,
  currentFilters,
  currentChefFilter,
  currentQuickFilter,
  selectedCategory,
  currentSortBy,
  currentPreferenceProfile,
  searchInput
) => {
  const state = store.getState()
  const { name: menuBySelectedName = '' } = state.filters.menuSelected || {}
  const pastFilteredMeals = state.filters.filteredMeals
  const isDefaultMenuByFilter = true // TODO: Ask if Necessary to product team
  const hidePremiumFilterEnabled = hidePremiumFilter(state)
  const currentRangeMacronutrientsFilters = getRangeMacronutrientsFilters(state)

  const uniqueChefs = getUniqueChefs(state.menu?.menu?.meals)
  const fullMenuProductTypeSelectedFilters = getFullMenuProductTypeSelectedFilters(
    state
  )

  const filters_applied = getFilters(
    currentFilters,
    currentChefFilter,
    currentQuickFilter,
    searchInput,
    menuBySelectedName,
    currentPreferenceProfile,
    hidePremiumFilterEnabled,
    uniqueChefs,
    fullMenuProductTypeSelectedFilters,
    currentRangeMacronutrientsFilters
  )

  if (filters_applied.length > 0) {
    tracking.track(PRODUCT_LIST_FILTERED, {
      filters_applied,
      filter_default_menu_by: isDefaultMenuByFilter,
      sort_name: getSortName(currentSortBy),
      list_name: selectedCategory.title,
      list_type: !!selectedCategory
        ? PRODUCT_LIST_TYPES.REGULAR
        : PRODUCT_LIST_TYPES.COLLECTION,
      list_menu_by: `Show Menu By ${menuBySelectedName}`,
      count_products_displayed_before_filter: pastFilteredMeals?.length ?? 0,
      count_products_displayed_after_filter: filteredMeals?.length ?? 0,
      count_products_available_before_filter:
        pastFilteredMeals?.filter(item => item.stock > 0).length ?? 0,
      count_products_available_after_filter:
        filteredMeals?.filter(item => item.stock > 0).length ?? 0,
      page_name: PAGE_NAMES.MENU,
      ...getMenuGenericProps()
    })
  }
}

export const trackSearchRevampFilterApplied = (filterGroup, searchedValue) => {
  const state = store.getState()
  const exactMatchMeals = mealsSearchExactMatchResults(state)
  const suggestedMeals = mealsSearchRelatedResults(state)
  const mealsSuggested = [...exactMatchMeals, ...suggestedMeals]
  const suggestedMealsWithAvailableStock =
    mealsSuggested.filter(item => item.stock > 0).length ?? 0

  tracking.track(PRODUCT_LIST_FILTERED, {
    filters_applied: [
      {
        filter_type: 'Search',
        filter_group: filterGroup,
        filter_value: searchedValue
      }
    ],

    sort_name: 'by fuzze search score',
    list_name: 'search-menu',
    list_type: 'regular',
    count_products_displayed_after_filter: mealsSuggested.length,
    count_products_available_after_filter:
      suggestedMealsWithAvailableStock.length,
    page_name: 'Menu / Search revamp'
  })
}

export const trackListSorted = sortBy => {
  tracking.track('Product List Sorted', {
    sort_name: sortBy.map(sort => sort.name),
    list_name: 'Meals',
    page_name: PAGE_NAMES.MENU,
    ...getMenuGenericProps()
  })
}

export const trackMenuCategoryClicked = tab_selected => {
  tracking.track('Menu Category Clicked', {
    tab_selected,
    page_name: PAGE_NAMES.MENU,
    ...getMenuGenericProps()
  })
}

export const formatProduct = products => {
  return products.map(elem => {
    return {
      id: elem.entity_id,
      sku: elem.sku,
      category: elem.category,
      name: elem.title,
      chef_name: elem.chefName,
      price: elem.price,
      url: `/products/${elem.entity_id}`,
      image_url: elem.imageUrl
    }
  })
}

export const trackExperimentViewed = experimentTrackingProperties => {
  tracking.track('Experiment Viewed', experimentTrackingProperties)
}

export const trackProductListViewed = (
  pageName,
  mealsData = [],
  props = {}
) => {
  const state = store.getState()
  const currentDate = getCurrentDate(state)
  const currentFilters = getCurrentFilters(state)
  const currentChefFilter = getCurrentChefFilter(state)
  const currentQuickFilter = getCurrentQuickFilter(state)
  const currentSortBy = getCurrentSortBy(state)
  const currentSelectedProfilePreferences = getCurrentSelectedProfilePreferences(
    state
  )
  const hidePremiumFilterEnabled = hidePremiumFilter(state)
  const currentRangeMacronutrientsFilters = getRangeMacronutrientsFilters(state)

  const searchInput = state.filters?.searchInput || ''
  const { name: menuBySelectedName = '' } = state.filters.menuSelected || {}

  props = {
    ...props,
    delivery_date_cutoff_time: currentDate?.cutoff?.time,
    delivery_display_date: currentDate?.displayDate,
    delivery_date_is_paused: currentDate?.isPaused,
    delivery_date_order_status: currentDate?.order?.orderStatus?.status,
    delivery_date_recommendation_date: currentDate?.recommendation?.date,
    delivery_date_recommendation_id: currentDate?.recommendation?.id,
    delivery_date_rescheduled: currentDate?.rescheduled,
    delivery_date_scheduled: currentDate?.scheduled,
    delivery_date_skip: currentDate?.skip
  }

  const isMenuListSelection = pageName === PAGE_NAMES.MENU

  tracking.track('Product List Viewed', {
    page_name: pageName,
    products_viewed: isMenuListSelection
      ? mealsData.map(({ magento_id, weight, inventoryId }) => ({
          magento_id: magento_id ? magento_id : undefined,
          weight,
          inventory_id: inventoryId
        }))
      : mealsData,
    count_products_displayed: mealsData.length,
    count_products_available: isMenuListSelection
      ? mealsData.filter(item => item.stock > 0).length
      : mealsData.length,
    filters_applied: getFilters(
      currentFilters,
      currentChefFilter,
      currentQuickFilter,
      searchInput,
      menuBySelectedName,
      currentSelectedProfilePreferences,
      hidePremiumFilterEnabled,
      [],
      [],
      currentRangeMacronutrientsFilters
    ),
    sort_name: getSortName(currentSortBy),
    ...props,
    ...getMenuGenericProps()
  })
}

export const trackMealInBundleDetailClick = () => {
  tracking.track('CTA Button Clicked', {
    cta_text: 'See details'
  })
}

export const trackRelatedMealTooltipViewed = () => {
  tracking.track('Overlay Viewed', {
    overlay_name: 'Cross Sell Product Tooltip',
    overlay_class: 'Tooltip'
  })
}

export const trackSortingFilterViewed = () => {
  tracking.track('Overlay Viewed', {
    overlay_name: 'Sort and Filters'
  })
}

export const trackFilterViewed = categoryName => {
  tracking.track('Overlay Viewed', {
    overlay_name: categoryName
  })
}

export const trackClearAllFiltersOverlayButtonClicked = () => {
  tracking.track('CTA Button Clicked', {
    cta_text: 'Clear all Overlay'
  })
}

export const trackClearAllFiltersButtonClicked = () => {
  tracking.track('CTA Button Clicked', {
    cta_text: 'Clear all'
  })
}

export const trackClearFilterButtonClicked = categoryName => {
  tracking.track('CTA Button Clicked', {
    cta_text: `Clear ${categoryName}`
  })
}

export const trackSeeAllFiltersButtonClicked = () => {
  tracking.track('CTA Button Clicked', {
    cta_text: 'View more'
  })
}

export const trackSortingFilterClosed = () => {
  tracking.track('Overlay Closed', {
    overlay_name: 'Sort and Filters'
  })
}

export const trackSortingFilterClearAllClicked = () => {
  tracking.track('Overlay Clicked', {
    overlay_name: 'Sort and Filters',
    overlay_action: `Clear All`
  })
}

export const trackSeeAllNutritionalFactsClicked = () => {
  tracking.track('CTA Button Clicked', {
    cta_text: `See Nutrition Facts label`
  })
}

export const trackSeeAllNutritionalFactsOverlayOpenedViewed = (
  overlay_name = 'Full Nutritional Label'
) => {
  tracking.track('Overlay Viewed', {
    overlay_name
  })
}

export const trackSeeAllNutritionalFactsOverlayClosedViewed = (
  overlay_name = 'Full Nutritional Label'
) => {
  tracking.track('Overlay Closed', {
    overlay_name
  })
}

export const trackNutritionalInfoCardClicked = () => {
  tracking.track('CTA Button Clicked', {
    cta_text: 'Meal Card Nutrition Info Icon'
  })
}

/** Tracking event for Content Tile Clicked
 * @param {Object} props
 * @param {String} props.pageName
 * @param {Number} props.pagePosition
 * @param {String} props.contentName
 * @param {String} props.contentDescription
 * @param {String} props.deliveryDate
 * @param {String} props.productId
 */
export const trackContentTileClicked = props => {
  const {
    pageName,
    pagePosition,
    contentName,
    contentDescription,
    deliveryDate = '',
    productId
  } = props

  tracking.track('Content Tile Clicked', {
    page_name: pageName,
    page_position: pagePosition,
    content_name: contentName,
    content_description: contentDescription,
    delivery_date: deliveryDate,
    product_id: productId || undefined
  })
}

export const trackRaffleViewed = (
  pageName,
  bannerName,
  bannerType,
  bannerCampaign
) => {
  tracking.track('Banner Viewed', {
    page_name: pageName,
    banner_name: bannerName,
    banner_type: bannerType,
    banner_campaign: bannerCampaign
  })
}

export const trackRaffleClicked = (
  pageName,
  bannerName,
  bannerType,
  bannerCampaign
) => {
  tracking.track('Banner Clicked', {
    page_name: pageName,
    banner_name: bannerName,
    banner_type: bannerType,
    banner_campaign: bannerCampaign
  })
}

export const trackMenuByClicked = ({ pageName = '', filterName = '' }) => {
  tracking.track('Show Menu By Clicked', {
    page_name: pageName,
    filter_name: filterName
  })
}

export const trackMenuBySelected = ({ selected = '' }) => {
  tracking.track('Show Menu By Selected', {
    page_name: 'Menu',
    list_name: 'Meals',
    menu_by_selected: selected
  })
}

export const trackBrowseMenu = ({
  shippingDate,
  deliveryDate,
  ...orderStatusProps
}) => {
  const orderStatus = getOrderStatus(orderStatusProps)
  const ctaDescription = getBrowseEventCtaCopyFromOrderStatus(orderStatus)
  tracking.track('Browse Menu Clicked', {
    page_name: 'Orders',
    shipping_date: shippingDate,
    order_status: orderStatus,
    cta_description: ctaDescription,
    delivery_date: deliveryDate
  })
}

export const trackViewReceiptClicked = ({ orderStatus, orderId }) => {
  tracking.track('View Receipt Clicked', {
    page_name: 'History',
    order_status: orderStatus,
    order_id: orderId
  })
}

export const trackPersonalizedFilterViewed = () => {
  tracking.track('Overlay Viewed', {
    overlay_name: 'Personalized Filter',
    overlay_class: 'modal',
    overlay_source: 'custom',
    overlay_type: 'button',
    page_name: 'Meal Selection',
    overlay_trigger: 'manually',
    overlay_step: 1
  })
}

export const trackPersonalizedFilterClicked = ({ optionSelected }) => {
  tracking.track('Overlay Clicked', {
    overlay_name: 'Personalized Filter',
    overlay_class: 'modal',
    overlay_source: 'custom',
    overlay_type: 'button',
    page_name: 'Meal Selection',
    overlay_trigger: 'manually',
    overlay_step: 1,
    overlay_options: [
      'Select a Partner: when the user select one of the partners or none',
      'Add a Partner: when the user clicks on "add partner"',
      'Set Preferences: when the user clicks on "Set Preferences Now"',
      'Edit Preferences: when the user clicks on "Edit"'
    ],
    overlay_action: optionSelected
  })
}

export const trackSwitchViewButton = ({
  prev_view,
  view_selected,
  list_name
}) => {
  tracking.track('Visual Switcher Clicked', {
    prev_view,
    view_selected,
    list_name
  })
}

export const trackRecipeClicked = ({
  page_name,
  product_id,
  product_line,
  recipe_id,
  recipe_name
}) => {
  tracking.track('Recipe Clicked', {
    page_name,
    product_id,
    product_line,
    recipe_id,
    recipe_name
  })
}

export const trackFullMenuAnchorClicked = () => {
  tracking.track('See Categories Clicked', {})
}

export const trackFullMenuCategoryAnchorClicked = categoryNameClicked => {
  tracking.track('Category Anchor Selected', {
    name: categoryNameClicked
  })
}

export const trackCollectionClicked = ({ list_name }) => {
  tracking.track('Collection Clicked', {
    page_name: 'Meal Selection',
    list_name,
    ...getMenuGenericProps()
  })
}

export const trackContinueClicked = () => {
  tracking.track('Continue Clicked', {
    page_name: 'Meal Selection',
    ...getMenuGenericProps()
  })
}

export const trackMenuMealsModalArrowButtonClicked = ({
  productCategory,
  action,
  modalName
}) => {
  tracking.track('Overlay Clicked', {
    type: 'track',
    overlay_name: `${productCategory} - ${modalName}`,
    product_add_source: `${productCategory} - ${modalName}`,
    overlay_class: 'custom',
    overlay_source: 'custom',
    overlay_type: 'custom',
    page_name: 'Checkout',
    overlay_trigger: 'manual',
    overlay_action: action
  })
}

export const trackDownloadAppBannerViewed = bannerName => {
  const state = store.getState()
  const orderStatusForTracking = orderStatusForTrackingSelector(state)
  tracking.track(`Banner Viewed`, {
    order_status: orderStatusForTracking,
    banner_name: bannerName,
    banner_type: 'Mobile Adoption',
    page_name: 'Orders'
  })
}

export const trackDownloadAppBannerClicked = bannerName => {
  const state = store.getState()
  const orderStatusForTracking = orderStatusForTrackingSelector(state)
  tracking.track(`Banner Clicked`, {
    order_status: orderStatusForTracking,
    banner_name: bannerName,
    banner_type: 'Mobile Adoption',
    page_name: 'Orders',
    cta_text: 'Download APP'
  })
}

export const getMenuGenericProps = () => {
  const state = store.getState()
  const currentDate = getCurrentDate(state)
  const selectedDelivery = selectedDeliverySelector(state)
  const orderStatusForTracking = orderStatusForTrackingSelector(state)
  return {
    delivery_date: currentDate?.displayDate || selectedDelivery?.displayDate,
    shipping_date: currentDate?.date || selectedDelivery?.date,
    order_status: orderStatusForTracking
  }
}

export const trackNewResurrectionFlowEvent = (screenName, action) => {
  tracking.track('New Resurrection Flow Event', {
    page_name: screenName,
    action
  })
}

export const trackSearchInputClick = () => {
  tracking.track('Search Filter Revamp Clicked', {})
}

export const trackFreePremiumMealAdded = meal => {
  tracking.track('Free Premium Added', {
    id: meal.id,
    inventory_id: meal.inventory_id || meal.inventoryId,
    batchId: meal.batchId,
    name: meal.name,
    shortDescription: meal.shortDescription
  })
}

export const trackFreePremiumMealRemoved = meal => {
  tracking.track('Free Premium Removed', {
    id: meal.id,
    inventory_id: meal.inventory_id || meal.inventoryId,
    batchId: meal.batchId,
    name: meal.name,
    shortDescription: meal.shortDescription
  })
}

export const trackFreePremiumMealsBannerButtonClicked = ctaTxt => {
  tracking.track('CTA button clicked', {
    cta_text: ctaTxt
  })
}

export const trackFreePremiumOverlayViewed = () => {
  tracking.track('Overlay Viewed', {
    overlay_name: 'Free premium meal for life modal',
    page_name: 'Confirmation'
  })
}

export const trackFreePremiumOverlayClosed = () => {
  tracking.track('Overlay Closed', {
    overlay_name: 'Free premium meal for life modal',
    page_name: 'Confirmation'
  })
}

export const trackFreePremiumOverlayClicked = action => {
  tracking.track('Overlay Clicked', {
    overlay_name: 'Free premium meal for life modal',
    page_name: 'Confirmation',
    overlay_action: action
  })
}

export const trackBannerViewed = bannerType => {
  tracking.track('Banner Viewed', {
    banner_type: bannerType
  })
}

export const trackOverlayViewed = overlayName => {
  tracking.track('Overlay Viewed', {
    overlay_name: overlayName
  })
}

export const trackOverlayClicked = (overlayName, overlayAction) => {
  tracking.track('Overlay Clicked', {
    overlay_name: overlayName,
    overlay_action: overlayAction
  })
}

export const trackGrossResurrectionEvent = async ({
  resurrectionCampaign = {},
  couponCode,
  prepaidOfferId,
  subscriptionData = {},
  metaData = {}
} = {}) => {
  try {
    // Gather all tracking data
    const trackingData = {
      page_name: 'Reactivate Subscription',
      ...(await getResurrectionTrackingData(
        subscriptionData,
        resurrectionCampaign,
        couponCode,
        prepaidOfferId
      )),
      ...metaData
    }

    if (!trackingData.subscriber_id) {
      console.error('Missing subscriber_id in resurrection tracking')
    }

    // Track the event with clean data
    tracking.track('New Gross Resurrection', trackingData)
  } catch (error) {
    console.error('Failed to track gross resurrection:', error)
    tracking.track('New Gross Resurrection', {
      page_name: 'Reactivate Subscription',
      error: 'Failed to track resurrection',
      error_message: error.message
    })
  }
}

export const trackMealCardNutritionCoachmarkCta = () => {
  tracking.track('Overlay Clicked', {
    overlay_name: 'Nutritional Info Card - Coachmark',
    overlay_action: 'Nutritional Info Card - Coachmark - CTA'
  })
}

export const trackMealCardNutritionCoachmarkClose = () => {
  tracking.track('Overlay Clicked', {
    overlay_name: 'Nutritional Info Card - Coachmark',
    overlay_action: 'Nutritional Info Card - Coachmark - Close'
  })
}

export const trackRemoveRecommendations = (products, pageName) => {
  tracking.track('Recommendation Removed', {
    products: products.map(product => getProductProps(product)),
    cart_quantity_removed: products.length,
    page_name: pageName
  })
}

export const trackPauseFromSettings = () => {
  tracking.track('CTA Button Clicked', {
    cta_text: 'Pause Plan'
  })
}

export const trackMealRatingCardClicked = ({
  mealName,
  chefName,
  ratingStatus,
  position
}) => {
  tracking.track('Swimlane Clicked', {
    swimlane_name: 'Meal Rating',
    swimlane_type: 'Rating Cards',
    meal_name: mealName,
    chef_name: chefName,
    rating_status: ratingStatus,
    position: position
  })
}

export const tackSeeAllRatingCardSwimlane = ({
  count_meals_available,
  count_meals_displayed
}) => {
  tracking.track('See All Clicked', {
    carousel_name: 'Meal Rating',
    carousel_type: 'Rating Cards',
    click_action: 'See All Clicked',
    count_meals_available,
    count_meals_displayed
  })
}
