import './MealsGrid.scss'
import { useMeals } from '../useMeals'
import { RegularMealCard } from 'src/componentsV2/MenuMealCard/RegularMealCard/RegularMealCard'
import { checkIfMealExistIntoWishListProfiles } from 'src/utils/utils'
import { useIsMobile } from 'src/hooks/useIsMobile'
import { MealCardSlider } from 'src/components/MealCardSlider/MealCardSlider'
import { RegularBundleCard } from 'src/componentsV2/MenuBundleCard/RegularBundleCard/RegularBundleCard'

export interface Meal {
  id: number
  inventoryId: string | undefined
  mealName: string
  mealDescription: string
  name: string
  image: string
  chef_firstname: string
  chef_lastname: string
  chefId: number
  is_celebrity_chef: boolean
  chefImage: string
  quantity: number
  meatType: string | undefined
  calories: number
  tags: Array<{ label: string }>
  premium_special: boolean | null
  items: any[]
  product: any
  stock: number
  qty: number
}

export const mapCarrouselToOrderItems = (items: any): Meal[] => {
  if (!Array.isArray(items)) return []
  return items.map((item: any) => {
    const cardChildren = item?.children || []
    const chef = cardChildren.find((c: any) => c.props?.subType === 'chef_link')
    const quantity = cardChildren.find(
      (c: any) => c.props?.subType === 'quantity'
    )
    const badge = cardChildren.find((c: any) => c.type === 'BADGE')
    const chefId = chef?.props?.actions?.[0]?.data?.path?.split('/')?.[1] || '0'

    return {
      id: parseInt(item.props.mealProperties?.id || '0'),
      inventoryId: item.props.id,
      mealName: item.props.title,
      mealDescription: item.props.subTitle || '',
      name: item.props.title,
      image: item.props.image.name,
      chef_firstname: chef?.props?.text?.split(' ')[0] || '',
      chef_lastname: chef?.props?.text?.split(' ')[1] || '',
      chefId: parseInt(chefId),
      is_celebrity_chef: false,
      chefImage: chef?.props?.image?.name || '',
      quantity: parseInt(quantity?.props?.text || '0'),
      meatType: undefined,
      calories: parseInt(item.props.calories || '0'),
      // TODO: validate fields for bundle
      tags: [],
      premium_special: badge?.props?.text === 'Premium' ? true : null,
      items: item.props.items || [],
      product: item.props.product || null,
      stock: item.props.stock,
      qty: item.props.qty
    }
  })
}

export const CarrouselMealsGrid = meals => {
  const {
    onMealClick,
    onChefClick,
    onLikeMeal,
    onUnLikeMeal,
    onBundleClick,
    profiles
  } = useMeals()
  const notMappedMeals = Object.values(meals)
  const mappedMeals = mapCarrouselToOrderItems(notMappedMeals)
  const isMobile = useIsMobile()

  const renderMeals = () => {
    return mappedMeals.map((meal, i) => {
      const isBundleMeal =
        meal?.items?.length > 0 || meal?.product?.items?.length > 0
      if (isBundleMeal) {
        const bundle = meal.product ?? meal
        return (
          <RegularBundleCard
            bundle={meal.product ?? meal}
            isEditable={false}
            planIsCompleted={false}
            stock={meal.stock}
            quantity={meal.qty}
            onBundleClick={() => onBundleClick({ ...bundle, i })}
            onAddItem={() => {}}
            onRemoveItem={() => {}}
          />
        )
      }
      return (
        <RegularMealCard
          key={`${meal.id}-${i}`}
          meal={{
            ...meal,
            full_path_meal_image: meal.image,
            full_path_chef_image: meal.chefImage,
            short_description: meal.mealDescription,
            name: meal.mealName,
            premium_special: meal.premium_special,
            magento_id: meal.id
          }}
          quantity={meal.quantity || 0}
          isLoading={false}
          isEditable={false}
          isExtra={false}
          onLikeMeal={onLikeMeal}
          onUnLikeMeal={onUnLikeMeal}
          // @ts-ignore
          isLikeMarked={checkIfMealExistIntoWishListProfiles(meal.id, profiles)}
          onMealClick={() => onMealClick(meal)}
          onChefClick={() => onChefClick(meal.chefId)}
          index={i}
          onAddItem={() => {}}
          onRemoveItem={() => {}}
          planIsCompleted={false}
        />
      )
    })
  }

  return isMobile ? (
    <MealCardSlider>{renderMeals()}</MealCardSlider>
  ) : (
    <div className="meals-grid">{renderMeals()}</div>
  )
}
