import { RoutePaths } from '../../../../routes/routePaths'

const BASE_URL = process.env.REACT_APP_SITE_BASE_URL

interface MenuLink {
  label: string
  icon?: string
  url?: string
  pathname: string | null
}

interface MenuCategory {
  label: string
  icon: string
  links: MenuLink[]
}

export const getMenuLinks = (
  isUserEligibleForMembership: Boolean,
  eaterProfileEnabled: Boolean,
  isPrepaidPlan: Boolean,
  isFlexPlan: Boolean
): Array<MenuLink | MenuCategory> => {
  const menuLinks = [
    {
      label: 'header.tab.orders',
      pathname: RoutePaths.ORDERS,
      icon: 'archive'
    },
    {
      label: 'header.tab.my_account',
      icon: 'layers',
      links: [
        {
          label: isFlexPlan
            ? 'header.tab.switch_to_weekly_plan'
            : 'header.tab.myPlan',
          pathname: RoutePaths.PLAN,
          icon: 'layers'
        },
        {
          label: eaterProfileEnabled
            ? 'header.tab.taste_profile'
            : 'header.tab.meal_preferences',
          pathname: RoutePaths.MEAL_PREFERENCES
        },
        {
          label: 'header.tab.nutrition',
          pathname: RoutePaths.NUTRITION
        },
        {
          label: 'header.tab.favorites',
          pathname: RoutePaths.FAVORITES
        }
      ]
    },
    {
      label: 'header.tab.history',
      pathname: RoutePaths.HISTORY,
      icon: 'timer'
    },
    {
      label: 'header.tab.wallet',
      icon: 'billing',
      links: [
        {
          label: 'header.tab.billing_and_receipts',
          pathname: RoutePaths.BILLING_AND_RECEIPTS
        },
        {
          label: 'header.tab.credits_and_promotions',
          pathname: RoutePaths.CREDITS_AND_PROMOTIONS
        }
      ]
    },
    {
      label: 'header.tab.unity_pass',
      pathname: RoutePaths.UNITYPASS,
      icon: 'star'
    },
    {
      label: 'header.tab.settings',
      pathname: RoutePaths.ACCOUNT,
      icon: 'options'
    },
    {
      label: 'header.tab.notifications',
      pathname: RoutePaths.NOTIFICATIONS,
      icon: 'bell'
    },
    {
      label: 'header.tab.refer_a_friend',
      pathname: RoutePaths.REFER_A_FRIEND,
      icon: 'friend'
    },
    {
      label: 'header.tab.how_it_works',
      pathname: null,
      url: `${BASE_URL}${RoutePaths.HOW_IT_WORKS}`,
      icon: 'questionMarkCircle'
    }
  ]
  if (isFlexPlan || isPrepaidPlan) {
    return menuLinks.filter(link => link.label !== 'header.tab.refer_a_friend')
  }
  if (!isUserEligibleForMembership) {
    return menuLinks.filter(link => link.label !== 'header.tab.unity_pass')
  }
  return menuLinks
}
