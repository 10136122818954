export type Component = {
  type: ComponentType
  props: ComponentProps
  children?: Component[]
}

export type ComponentProps = any

export enum ComponentType {
  TAB = 'TAB',
  BUTTON = 'BUTTON',
  INFO_CONTAINER = 'INFO_CONTAINER',
  TITLE_BAR = 'TITLEBAR',
  CARROUSEL = 'CARROUSEL',
  MEAL_RATING_CARD = 'MEAL_RATING_CARD',
  BOTTOM_SHEET = 'BOTTOM_SHEET',
  LINK = 'LINK',
  RATING_DETAIL = 'RATING_DETAIL',
  LOADING_INDICATOR = 'LOADING_INDICATOR',
  ORDER_STATUS_HEADER = 'ORDER_STATUS_HEADER',
  ORDER_TRACKING_DETAIL = 'ORDER_TRACKING_DETAIL',
  STEPPER = 'STEPPER',
  STEP = 'STEP',
  FOOTER = 'FOOTER',
  TABS = 'TABS',
  MEAL_RATING_CARD_SWIMLANE = 'MEAL_RATING_CARD_SWIMLANE'
}
