import { createAction } from 'redux-actions'

export const getUserMenuStart = createAction('GET_USER_MENU_START')
export const getUserMenuSuccess = createAction('GET_USER_MENU_SUCCEED')
export const getUserMenuFail = createAction('GET_USER_MENU_FAILED')

export const getUserAlreadyRequestedMealsStart = createAction(
  'GET_USER_ALREADY_REQUESTED_MEALS_START'
)
export const getUserAlreadyRequestedMealsSuccess = createAction(
  'GET_USER_ALREADY_REQUESTED_MEALS_SUCCEED'
)
export const getUserAlreadyRequestedMealsFail = createAction(
  'GET_USER_ALREADY_REQUESTED_MEALS_FAILED'
)

export const resetUserMenu = createAction('RESET_USER_MENU')
export const setIsCrossSellingModalOpen = createAction(
  'SET_IS_CROSS_SELLING_MODAL_OPEN'
)
export const setInitialScrollTo = createAction('SET_MENU_INITIAL_SCROLL_TO')
export const setMenuViewMode = createAction('SET_MENU_VIEW_MODE')

export const getCrossSellingItemsStart = createAction('GET_CROSS_SELLING_START')
export const getCrossSellingItemsSuccess = createAction(
  'GET_CROSS_SELLING_SUCCEED'
)
export const getCrossSellingItemsFail = createAction('GET_CROSS_SELLING_FAILED')

export const setCrossSellingModalSeen = createAction(
  'SET_CROSS_SELLING_MODAL_SEEN'
)

export const setFullMenuMealInitialPosition = createAction(
  'SET_FULL_MENU_MEAL_INITIAL_POSITION'
)

//--------- MENU SEARCH VIEW ACTIONS --------- //

export const setShowMenuSearchView = createAction('SET_SHOW_MENU_SEARCH_VIEW')

export const setStartMenuTransitionToSearchView = createAction(
  'SET_START_MENU_TRANSITION_TO_SEARCH_VIEW'
)

export const setEndMenuTransitionToSearchView = createAction(
  'SET_END_MENU_TRANSITION_TO_SEARCH_VIEW'
)
export const suggestionPillClicked = createAction('SUGGESTION_PILL_CLICKED')

export const recentSearchClicked = createAction('RECENT_SEARCH_CLICKED')

export const setSearchViewValue = createAction('SET_SEARCH_VIEW_VALUE')

//--------- END MENU SEARCH VIEW ACTIONS --------- //

//--------- FREE PREMIUM MEALS ACTIONS --------- //

export const freePremiumMealsAdd = createAction('FREE_PREMIUM_MEALS_ADD')
export const freePremiumMealsRemove = createAction('FREE_PREMIUM_MEALS_REMOVE')
export const clearFreePremiumMeals = createAction('CLEAR_FREE_PREMIUM_MEALS')

//--------- END PREMIUM MEALS ACTIONS --------- //

//--------- WINES DOB ACTIONS --------- //

export const setWinesDobModalOpen = createAction('SET_WINES_DOB_MODAL_OPEN')
export const setWinesDobModalClose = createAction('SET_WINES_DOB_MODAL_CLOSE')

//--------- END WINES DOB ACTIONS --------- //
