import { CuiButton } from 'front-lib'
import { useTranslation } from 'react-i18next'
import { useFlag } from '@unleash/proxy-client-react'
import { useHistory } from 'react-router-dom'

import { useCancelDeflection } from 'src/hooks/useCancelDeflection'

import './CancelSubscription.scss'

import { FF_PAUSE_PLAN_FROM_SETTINGS } from 'src/constants/feature-flags'
import { RoutePaths } from 'src/routes/routePaths'
import { trackPauseFromSettings } from 'src/utils/tracking'
import { useCanPause } from 'src/hooks/useCanPause'

export function CancelSubscription({ isButton = false, pageName, disabled }) {
  const { t } = useTranslation()
  const history = useHistory()
  const ffPausePlanFromSettings = useFlag(FF_PAUSE_PLAN_FROM_SETTINGS)
  const userCanPause = useCanPause()
  const { handleCancelSubscription } = useCancelDeflection({
    pageName
  })

  function handleCancel() {
    if (!window.Brightback || disabled) {
      return
    }
    handleCancelSubscription()
  }

  function handlePausePlan() {
    trackPauseFromSettings()
    history.push(RoutePaths.PLAN)
  }

  const showPauseButon = ffPausePlanFromSettings && userCanPause

  return (
    <div className="cancel_subscription">
      {isButton && !showPauseButon ? (
        <CuiButton
          size="large"
          color="dark"
          fill="outline"
          disabled={disabled}
          onClick={handleCancel}>
          {t('pages.my_plan.cancelSubscriptionButton')}
        </CuiButton>
      ) : (
        <div className="action_link">
          {showPauseButon && (
            <CuiButton
              size="large"
              fill="outline"
              disabled={disabled}
              onClick={handlePausePlan}>
              {t('pages.my_plan.pause_plan')}
            </CuiButton>
          )}
          <CuiButton
            fill="clear"
            size="large"
            disabled={disabled}
            onClick={handleCancel}>
            {t('pages.my_plan.cancelSubscriptionButton')}
          </CuiButton>
        </div>
      )}
    </div>
  )
}
