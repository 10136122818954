import actionCreator from './utils/actionCreator'

export const PFX = 'USER'
export const setLocalStorage = actionCreator(PFX, 'set_local_storage')

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem('CookUnityState')
    if (serializedState === null) {
      return undefined
    }
    return JSON.parse(serializedState)
  } catch (err) {
    return undefined
  }
}
