import { useSelector } from 'react-redux'
import { useMemo } from 'react'
import { getUpcomingDeliveries } from 'src/modules/MyDeliveries/selectors'
import { isPrepaidPlanSelector } from 'src/redux-api-bridge/selectors'

export function useCanPause() {
  const upcomingDeliveries = useSelector(getUpcomingDeliveries)
  const isPrepaidPlan = useSelector(isPrepaidPlanSelector)

  const userCanPause = useMemo(() => {
    const pendingFirstDelivery = upcomingDeliveries.some(
      delivery => delivery.isFirstOrder
    )

    return !pendingFirstDelivery && !isPrepaidPlan
  }, [upcomingDeliveries, isPrepaidPlan])

  return userCanPause
}
