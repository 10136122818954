export const SIDES_CATEGORY_ID = 3632
export const SAUCES_CATEGORY_ID = 5808
export const DRINKS_CATEGORY_ID = 33
export const TREATS_CATEGORY_ID = 34
export const MEALS_CATEGORY_ID = 0
export const MEALS_CATEGORY_LABEL = 'Meals'
export const BREAKFAST_CATEGORY_LABEL = 'Breakfast'
export const SIDES_CATEGORY_LABEL = 'Sides'
export const BREAKFAST_CATEGORY_ID = 3110
export const DEFAULT_OPTION_ID = '1-all'
export const MOBILE_SCROLL_POSITION = 530
export const DESKTOP_SCROLL_POSITION = 515
export const DEFAULT_OPTION_LABEL = 'All'

export const CATEGORY_PROMOTIONS_MAP = {
  [SIDES_CATEGORY_ID]: 'Sides',
  [SAUCES_CATEGORY_ID]: 'Sauces',
  [DRINKS_CATEGORY_ID]: 'Drinks',
  [TREATS_CATEGORY_ID]: 'Treats',
  [MEALS_CATEGORY_ID]: 'Meals',
  [BREAKFAST_CATEGORY_ID]: 'Breakfast'
}

export const FILTER_TYPES = {
  STICKY: 'Sticky',
  QUICK: 'Quick',
  ADVANCED: 'Advanced',
  SEACRH: 'Search',
  PERSONALIZED: 'Personalized'
}
