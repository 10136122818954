import { pathOr } from 'ramda'
import { createSelector } from 'reselect'
import { selectedDeliverySelector } from 'src/redux/slices/selectedDelivery/selectors'
import { getUserPlanSelector } from 'src/redux-api-bridge/selectors'

export const getUseUnifiedCart = state =>
  pathOr(null, ['cart', 'useUnifiedCart'], state)

export const isCartLoadingSelector = state =>
  pathOr(false, ['cart', 'isLoading'], state)

export const isCartOpenSelector = state =>
  pathOr(false, ['cart', 'isOpen'], state)

export const getCartErroredSelector = state =>
  pathOr('', ['cart', 'isError'], state)

export const getUnifiedCarts = state =>
  pathOr(null, ['cart', 'unifiedCarts'], state)

export const isUnifiedCartOperationInFlight = state =>
  pathOr(null, ['cart', 'isCrudOperationInFlight'], state)

export const getUnifiedCartsIsLoading = state =>
  pathOr(null, ['cart', 'isLoading'], state)

export const getHomeUnifiedCarts = state =>
  pathOr(null, ['cart', 'homeUnifiedCarts'], state)

export const getAllGiftsSelector = state => pathOr({}, ['cart', 'gifts'], state)

export const getAllCartsSelector = state => pathOr([], ['cart', 'carts'], state)

export const cartByDateSelector = createSelector(
  getAllCartsSelector,
  getHomeUnifiedCarts,
  getUseUnifiedCart,
  selectedDeliverySelector,
  (carts, homeUnifiedCarts, useUnifiedCart, selectedDelivery) => {
    if (!selectedDelivery) {
      return null
    }

    if (useUnifiedCart) {
      if (homeUnifiedCarts && homeUnifiedCarts[selectedDelivery.date]) {
        return (homeUnifiedCarts[selectedDelivery.date].products || []).concat(
          (
            homeUnifiedCarts[selectedDelivery.date].recommendations || []
          ).concat(homeUnifiedCarts[selectedDelivery.date].addOns || [])
        )
      }

      return null
    }

    return carts ? carts[selectedDelivery.date] : null
  }
)

export const getHomeUnifiedCartByDateSelector = createSelector(
  selectedDeliverySelector,
  getHomeUnifiedCarts,
  (selectedDelivery, homeUnifiedCarts) =>
    !selectedDelivery || !homeUnifiedCarts
      ? null
      : homeUnifiedCarts[selectedDelivery.date]
)

export const getCurrentCartDate = createSelector(
  state => state.api.schedulingStatus.newDate,
  date => date
)

export const shouldLoadGiftsSelector = createSelector(
  isCartLoadingSelector,
  getCartErroredSelector,
  getAllGiftsSelector,
  getHomeUnifiedCarts,
  getUseUnifiedCart,
  getCurrentCartDate,
  (
    isLoading,
    isError,
    gifts,
    homeUnifiedCarts,
    useUnifiedCart,
    currentDate
  ) => {
    if (isLoading) return false
    if (isError) return false

    if (useUnifiedCart) {
      return (
        !!homeUnifiedCarts &&
        !!currentDate?.date &&
        !homeUnifiedCarts[currentDate.date]
      )
    }

    return !!gifts && !!currentDate?.date && !gifts[currentDate.date]
  }
)

const getTotalQuantity = products => {
  return products?.reduce((quantity, product) => quantity + product.qty, 0) || 0
}

export const cartQuantityByDateSelector = createSelector(
  getAllCartsSelector,
  getHomeUnifiedCarts,
  getUseUnifiedCart,
  selectedDeliverySelector,
  (carts, homeUnifiedCarts, useUnifiedCart, selectedDelivery) => {
    if (!selectedDelivery?.date) {
      return null
    }

    if (useUnifiedCart) {
      return homeUnifiedCarts && homeUnifiedCarts[selectedDelivery.date]
        ? getTotalQuantity(homeUnifiedCarts[selectedDelivery.date].products) +
            getTotalQuantity(
              homeUnifiedCarts[selectedDelivery.date].recommendations
            )
        : null
    }

    return carts && carts[selectedDelivery.date]
      ? getTotalQuantity(carts[selectedDelivery.date])
      : null
  }
)

export const getGiftsByDateSelector = createSelector(
  getAllGiftsSelector,
  getHomeUnifiedCarts,
  getUseUnifiedCart,
  selectedDeliverySelector,
  (gifts, homeUnifiedCarts, useUnifiedCart, selectedDelivery) => {
    if (useUnifiedCart) {
      return homeUnifiedCarts &&
        !!selectedDelivery?.date &&
        homeUnifiedCarts[selectedDelivery.date]
        ? homeUnifiedCarts[selectedDelivery.date].gifts
        : null
    }

    return gifts && !!selectedDelivery?.date && gifts[selectedDelivery.date]
      ? gifts[selectedDelivery.date]
      : null
  }
)

const filterProductsByStock = (products = [], inStock = true) =>
  products.filter(product => (inStock ? product.stock > 0 : product.stock <= 0))

export const productsWithStockInCartSelector = date =>
  createSelector(
    getAllCartsSelector,
    getHomeUnifiedCarts,
    getUseUnifiedCart,
    (carts, homeUnifiedCarts, useUnifiedCart) => {
      if (useUnifiedCart) {
        return homeUnifiedCarts &&
          homeUnifiedCarts[date] &&
          homeUnifiedCarts[date].products
          ? filterProductsByStock(homeUnifiedCarts[date].products)
              .concat(
                filterProductsByStock(
                  homeUnifiedCarts[date].recommendations || []
                )
              )
              .concat(
                filterProductsByStock(homeUnifiedCarts[date].addOns || [])
              )
          : []
      }

      return filterProductsByStock(carts?.[date] || [])
    }
  )

export const productsWithoutStockInCartSelector = date =>
  createSelector(
    getAllCartsSelector,
    getHomeUnifiedCarts,
    getUseUnifiedCart,
    (carts, homeUnifiedCarts, useUnifiedCart) => {
      if (useUnifiedCart) {
        return homeUnifiedCarts?.[date]?.products
          ? filterProductsByStock(homeUnifiedCarts[date].products, false)
              .concat(
                filterProductsByStock(
                  homeUnifiedCarts[date].recommendations || [],
                  false
                )
              )
              .concat(
                filterProductsByStock(
                  homeUnifiedCarts[date].addOns || [],
                  false
                )
              )
          : []
      }

      return filterProductsByStock(carts?.[date] || [], false)
    }
  )

export const isOrderHomeCartCompleted = date =>
  createSelector(
    [getUserPlanSelector, getHomeUnifiedCarts],
    (userPlan, homeUnifiedCarts) => {
      if (!!homeUnifiedCarts && !!date) {
        const cartQuantity =
          homeUnifiedCarts[date]?.products.reduce((sum, product) => {
            if (product.entityType === 'MEAL') {
              return sum + product.cart.quantity
            }
            return sum
          }, 0) || 0
        const recommendationQuantity =
          homeUnifiedCarts[date]?.recommendations.length
        const cartMealsTotal = cartQuantity + recommendationQuantity
        return cartMealsTotal >= userPlan.mealsPerDelivery
      }
      return false
    }
  )
