import { trackClickedProduct } from 'src/utils/tracking'
import { addMealWish, removeMealWish } from 'src/redux-api-bridge'
import { useHistory, useLocation } from 'react-router-dom'
import { getProfiles } from 'src/modules/MyDeliveries/selectors'
import { useDispatch, useSelector } from 'react-redux'
import { selectedDeliveryMealsSelector } from 'src/redux/slices/selectedDelivery/selectors'
import { useFlag } from '@unleash/proxy-client-react'
import { FF_USE_MEALS_LITE } from 'src/constants/feature-flags'

export function useMeals() {
  const dispatch = useDispatch()
  const profiles = useSelector(getProfiles)
  const history = useHistory()
  const meals = useSelector(selectedDeliveryMealsSelector)
  const useMealsLiteFF = useFlag(FF_USE_MEALS_LITE)
  const location = useLocation()

  function handleMealClick(meal, sendQueryParams?: boolean) {
    const idToRedirect = useMealsLiteFF
      ? meal.inventoryId || meal.id
      : meal.id || meal.inventoryId
    const url =
      `/products/${idToRedirect}?` + (sendQueryParams ? location.search : '')
    trackClickedProduct(meal, url, 'My Deliveries')
    history.push(url)
  }

  function handleBundleClick(meal) {
    const url = `/products/${meal.inventoryId}`
    trackClickedProduct(meal, url, 'My Deliveries')
    history.push(url)
  }

  function handleChefClick(id: number) {
    history.push(`/chefs/${id}`)
  }

  function handleLikeClick(mealId: number) {
    if (!profiles?.length) {
      return
    }
    profiles.forEach(profile => {
      dispatch(addMealWish({ meal: mealId, profile: profile.id }))
    })
  }

  function handleUnlikeClick(mealId: number) {
    if (!profiles?.length) {
      return
    }
    profiles.forEach(profile =>
      dispatch(removeMealWish({ meal: { mealId }, profile: profile.id }))
    )
  }

  return {
    onMealClick: handleMealClick,
    onChefClick: handleChefClick,
    onLikeMeal: handleLikeClick,
    onUnLikeMeal: handleUnlikeClick,
    onBundleClick: handleBundleClick,
    meals,
    profiles
  }
}
