export const FILTER_IDS = {
  FULL_MENU_ALL: '44-full-menu-all',
  FULL_MENU_ADD_AND_SAVE: '45-full-menu-add-and-save',
  FULL_MENU_MAIN_WITH_SIDES: '46-full-menu-main-with-sides',
  FULL_MENU_SALADS_AND_BOWLS: '47-full-menu-salads-and-bowls',
  FULL_MENU_PASTA_AND_NOODLES: '48-full-pasta-and-noodles',
  FULL_MENU_TACOS_AND_BURRITOS: '49-full-menu-tacos-and-burritos',
  FULL_MENU_VEGGIE: '50-full-veggie',
  FULL_MENU_SANDWICHES: '51-full-menu-sandwiches',
  FULL_MENU_PIZZA: '52-full-menu-pizza',
  FULL_MENU_SOUPS_AND_STEWS: '53-full-menu-soups-and-stews',
  FULL_MENU_BREAKFAST: '54-full-breakfast',
  FULL_MENU_SIDES: '55-full-menu-sides',
  FULL_MENU_PROTEIN_ONLY: '56-full-menu-protein-only',
  FULL_MENU_TREATS: '57-full-menu-treats',
  FULL_MENU_SNACKS: '58-full-menu-snacks',
  FULL_MENU_DRINKS: '59-full-menu-drinks',
  FULL_MENU_BUNDLES: '60-full-menu-bundles',
  FULL_MENU_SAUCES_AND_CONDIMENTS: '61-full-menu-sauces-and-condiments',
  FULL_MENU_WELLNESS: '62-full-menu-wellness',
  FULL_MENU_HIDE_PREMIUM: '63-full-menu-hide-premium',
  FULL_MENU_GREAT_FOR_LUNCH: '64-full-menu-wellness-great-for-lunch',
  FULL_MENU_FAMILY_FAVORITES: '65-full-menu-family-favorites', // RENAMED TO KIDS MEALS
  FULL_MENU_ENERGY_BOOST: '66-full-menu-energy-boost',
  FULL_MENU_FINE_DINING: '67-full-menu-fine-dining',
  FULL_MENU_SEASONAL: '68-full-menu-seasonal',
  FULL_MENU_GREEN_EATING: '69-full-menu-green-eating',
  FULL_MENU_TRENDING_NOW: '70-full-menu-trending-now',
  FULL_MENU_TOP_RATED: '71-full-menu-top-rated',
  FULL_MENU_WHATS_NEW: '72full-menu-whats-new',
  FULL_MENU_FOR_YOU: '45-full-menu-for-you',
  FULL_MENU_YOUVE_TRIED: '73-full-menu-youvetried',
  FULL_MENU_FAVORITES: '74-full-menu-favorites',
  FULL_MENU_COLLECTIONS: '75-full-menu-collections',
  FULL_MENU_BEST_PERFORMERS: '78-full-menu-best-performers',
  FULL_MENU_CLEAN_EATING: '81-full-menu-clean-eating',
  FULL_MENU_GLP_1: '83-full-menu-glp-1',
  FULL_MENU_WINES: '84-full-menu-wines'
}

export const FULL_MENU_FILTERS = [
  FILTER_IDS.FULL_MENU_ALL,
  FILTER_IDS.FULL_MENU_ADD_AND_SAVE,
  FILTER_IDS.FULL_MENU_MAIN_WITH_SIDES,
  FILTER_IDS.FULL_MENU_SALADS_AND_BOWLS,
  FILTER_IDS.FULL_MENU_PASTA_AND_NOODLES,
  FILTER_IDS.FULL_MENU_TACOS_AND_BURRITOS,
  FILTER_IDS.FULL_MENU_VEGGIE,
  FILTER_IDS.FULL_MENU_SANDWICHES,
  FILTER_IDS.FULL_MENU_PIZZA,
  FILTER_IDS.FULL_MENU_SOUPS_AND_STEWS,
  FILTER_IDS.FULL_MENU_BREAKFAST,
  FILTER_IDS.FULL_MENU_SIDES,
  FILTER_IDS.FULL_MENU_PROTEIN_ONLY,
  FILTER_IDS.FULL_MENU_TREATS,
  FILTER_IDS.FULL_MENU_SNACKS,
  FILTER_IDS.FULL_MENU_DRINKS,
  FILTER_IDS.FULL_MENU_BUNDLES,
  FILTER_IDS.FULL_MENU_SAUCES_AND_CONDIMENTS,
  FILTER_IDS.FULL_MENU_WELLNESS,
  FILTER_IDS.FULL_MENU_HIDE_PREMIUM,
  FILTER_IDS.FULL_MENU_GREAT_FOR_LUNCH,
  FILTER_IDS.FULL_MENU_FAMILY_FAVORITES,
  FILTER_IDS.FULL_MENU_ENERGY_BOOST,
  FILTER_IDS.FULL_MENU_FINE_DINING,
  FILTER_IDS.FULL_MENU_SEASONAL,
  FILTER_IDS.FULL_MENU_GREEN_EATING,
  FILTER_IDS.FULL_MENU_TRENDING_NOW,
  FILTER_IDS.FULL_MENU_TOP_RATED,
  FILTER_IDS.FULL_MENU_WHATS_NEW,
  FILTER_IDS.FULL_MENU_FOR_YOU,
  FILTER_IDS.FULL_MENU_YOUVE_TRIED,
  FILTER_IDS.FULL_MENU_FAVORITES,
  FILTER_IDS.FULL_MENU_COLLECTIONS,
  FILTER_IDS.FULL_MENU_BEST_PERFORMERS,
  FILTER_IDS.FULL_MENU_CLEAN_EATING,
  FILTER_IDS.FULL_MENU_GLP_1,
  FILTER_IDS.FULL_MENU_WINES
]

export const FILTER_INDEX = {
  ALL: 0,
  CUISINE: 1,
  CHEF: 2,
  DIET: 3,
  PROTEIN: 4
}

export const FILTER_INDEX_EXPERIMENT = {
  ALL: 0,
  YOUVE_TRIED: 1,
  FAVORITES: 2,
  PREMIUM: 3,
  DIET: 4,
  PROTEIN: 5,
  CUISINE: 6,
  CHEF: 7
}

export const TAB_ALL_IDS = {
  ALL: FILTER_IDS.ALL,
  YOU_HAVE_TRIED: FILTER_IDS.YOUVE_TRIED,
  FAVORITES: FILTER_IDS.FAVORITES,
  PREMIUM: FILTER_IDS.PREMIUM
}

export const MENU_EMPTY_STATES = {
  FAVORITES: {
    title: 'Nothing here yet...',
    subtitle:
      "Use the heart icon to save your favorite meals. Once you do, you'll find them here.",
    image:
      'https://d1ureao5dcqpo4.cloudfront.net/media/catalog/product/cache/x600/c/o/corn-nut-shrimp-jgv.jpeg',
    buttonText: 'View all meals',
    isFavorite: true,
    isTopRated: false
  },
  YOU_HAVE_TRIED: {
    title: 'Nothing here yet...',
    subtitle:
      "It's a great opportunity to explore and try new things from our menu!",
    image:
      'https://d1ureao5dcqpo4.cloudfront.net/media/catalog/product/cache/x600/c/o/corn-nut-shrimp-jgv.jpeg',
    buttonText: 'View all meals',
    isFavorite: false,
    isTopRated: false
  },
  YOU_HAVE_TRIED_NO_ORDER: {
    title: 'Nothing here yet...',
    subtitle:
      "After you receive your first order, we will show you here the meals you've tried.",
    image:
      'https://d1ureao5dcqpo4.cloudfront.net/media/catalog/product/cache/x600/c/o/corn-nut-shrimp-jgv.jpeg',
    buttonText: 'View all meals',
    isFavorite: false,
    isTopRated: false
  },
  TOP_RATED: {
    title: 'Nothing here yet...',
    subtitle:
      'We will show your top rated meals here. You can start rating them now.',
    image:
      'https://d1ureao5dcqpo4.cloudfront.net/media/catalog/product/cache/x600/c/o/corn-nut-shrimp-jgv.jpeg',
    buttonText: 'Rate meals',
    isFavorite: false,
    isTopRated: true
  },
  TOP_RATED_NO_ORDER: {
    title: 'Nothing here yet...',
    subtitle:
      "We will show your top rated meals here. You don't have anything to rate yet. ",
    image:
      'https://d1ureao5dcqpo4.cloudfront.net/media/catalog/product/cache/x600/c/o/corn-nut-shrimp-jgv.jpeg',
    buttonText: 'View all meals',
    isFavorite: false,
    isTopRated: true
  }
}

export const SORTING_NAMES = {
  MY_PREFERENCES: 'My preferences'
}
