import { createAction } from 'redux-actions'

export const setFilters = createAction('FILTERS_CHANGED')
export const setChefFilter = createAction('CHEF_FILTER_CHANGED')
export const setSortBy = createAction('SORT_BY_CHANGED')
export const clearSortBy = createAction('CLEAR_SORT_BY')

export const setProfilePreferenceFilter = createAction(
  'SET_PROFILE_PREFERENCE_FILTER_CHANGED'
)

export const getSortingTypesStart = createAction('GET_SORTING_TYPES_STARTED')
export const getSortingTypesSuccess = createAction('GET_SORTING_TYPES_SUCCEED')
export const getSortingTypesFail = createAction('GET_SORTING_TYPES_FAILED')
export const setSortingMenuSelected = createAction('SET_SORTING_MENU_SELECTED')

export const getFilterTypesStart = createAction('GET_FILTER_TYPES_STARTED')
export const getFilterTypesSuccess = createAction('GET_FILTER_TYPES_SUCCEED')
export const getFilterTypesFail = createAction('GET_FILTER_TYPES_FAILED')

export const setFilteredMeals = createAction('SET_FILTERED_MEALS')

export const setFullMenuQuickfilters = createAction(
  'SET_FULL_MENU_QUICKFILTERS'
)

export const setFullMenuProductTypeSelected = createAction(
  'SET_FULL_MENU_PRODUCT_TYPE_FILTER'
)

export const clearAllMacronutrientsFilter = createAction(
  'CLEAR_ALL_MACRONUTRIENTS_FILTERS'
)

export const setMacronutrientFilter = createAction('SET_MACRONUTRIENT_FILTER')
