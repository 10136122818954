import { useSelector } from 'react-redux'

import { Formik, Field, Form } from 'formik'
import { CuiButton, CuiSpinner, CuiCheckbox } from 'front-lib'
import { useSetAtom } from 'jotai'
import * as Yup from 'yup'

import { PlanOptionCard } from '../../PlanOptionCard/PlanOptionCard'
import { useGetAvailablePlans } from 'src/api/plan/useGetAvailablePlans'
import { manageOrderModalAtom } from 'src/atoms/modals/manageOrderModal.atom'
import { useChangePlanModal } from 'src/hooks/useChangePlanModal'
import { useChangePlan } from 'src/pages/myPlan/hooks/useChangePlan'
import {
  getAllowPlanChange,
  getUserPlanSelector
} from 'src/redux-api-bridge/selectors'
import { selectedDeliverySelector } from 'src/redux/slices/selectedDelivery/selectors'
import { useGetUserDelivery } from 'src/hooks/useGetUserDelivery'

export function ChangePlanForm() {
  const selectedDelivery = useSelector(selectedDeliverySelector)
  const userDefaultPlan = useSelector(getUserPlanSelector)
  const allowPlanChange = useSelector(getAllowPlanChange)
  const { delivery } = useGetUserDelivery({
    shippingDate: selectedDelivery?.date
  })
  const { type, close } = useChangePlanModal()
  const { availablePlans } = useGetAvailablePlans()
  const setManageYourOrderModal = useSetAtom(manageOrderModalAtom)
  const { changePlan, loading } = useChangePlan()

  const changePlanOneTimeModal = type === 'one-time'
  const currentPlan = changePlanOneTimeModal ? delivery?.plan : userDefaultPlan
  const currentPlanId = String(currentPlan?.id)

  function handleSubmit({ newPlanId, setDefaultPlan }) {
    changePlan({
      planId: Number(newPlanId),
      setDefault: setDefaultPlan
    })
    setManageYourOrderModal({ isOpen: false, deliveryDate: '' })
    close()
  }

  if (availablePlans?.length === 0 || !delivery) {
    return <CuiSpinner width="30px" data-testid="change-plan-loading-spinner" />
  }

  return (
    <Formik
      initialValues={{
        newPlanId: currentPlanId,
        setDefaultPlan: !changePlanOneTimeModal
      }}
      validationSchema={Yup.object().shape({
        newPlanId: Yup.string().notOneOf(
          [currentPlanId],
          'User is currently subscribed to this plan'
        )
      })}
      onSubmit={handleSubmit}>
      {({ values, setFieldValue }) => (
        <Form className="change-plan" data-testid="change-plan-form">
          <fieldset
            className="change-plan--option-container"
            data-testid="change-plan-options-container">
            <legend id="my-radio-group" className="sr-only">
              Plan Options
            </legend>
            {availablePlans
              .filter(plan => {
                const isBiggerPlan =
                  plan.mealsPerDelivery >= currentPlan?.mealsPerDelivery

                return type === 'upgrade' ? isBiggerPlan : true
              })
              .map(plan => {
                const planId = String(plan.id)
                return (
                  <label
                    className="change-plan--option"
                    key={planId}
                    data-testid={`change-plan-option-${planId}`}>
                    <Field
                      type="radio"
                      name="newPlanId"
                      value={planId}
                      data-testid={`change-plan-radio-${planId}`}
                    />
                    <PlanOptionCard
                      plan={plan}
                      selected={planId === values.newPlanId}
                      type={'button'}
                    />
                  </label>
                )
              })}
          </fieldset>
          {changePlanOneTimeModal && (
            <CuiCheckbox
              className="change-plan--set-default-plan"
              name="setDefaultPlan"
              onChange={() =>
                setFieldValue('setDefaultPlan', !values.setDefaultPlan)
              }
              data-testid="change-plan-default-checkbox">
              Set as the default plan size for all orders
            </CuiCheckbox>
          )}
          <div className="change-plan--footer" data-testid="change-plan-footer">
            <CuiButton
              type="submit"
              size="large"
              disabled={
                values.newPlanId === currentPlanId ||
                !allowPlanChange ||
                loading
              }
              data-testid="change-plan-submit-button">
              {loading ? (
                <CuiSpinner
                  width="20px"
                  data-testid="change-plan-submit-spinner"
                />
              ) : (
                'Update'
              )}
            </CuiButton>
            <CuiButton
              onClick={close}
              size="large"
              fill="outline"
              data-testid="change-plan-cancel-button">
              Cancel
            </CuiButton>
          </div>
        </Form>
      )}
    </Formik>
  )
}
