import { CuiTag, CuiMenuImage, CuiMealActions, CuiMealSummary } from 'front-lib'
import classnames from 'classnames'
import { ChefsRow } from '../../ChefsRow/ChefsRow'
import './RegularBundleCard.scss'
import { CardTitle } from 'src/componentsV2/MenuMealCard/components/CardTitle/CardTitle'
import {
  getChefs,
  getChefsUpcomingDays,
  getMainTags,
  getBundleAddButtonText,
  bundleStrikethroughPrice
} from '../utils'
import { useIsMobile } from 'src/hooks/useIsMobile'
import { isAddOn } from '../../../modules/MyPlan/utils'
import { getBundleCharacteristicsFromMeals } from 'src/componentsV2/MenuMealCard/utils'
import { CharacteristicsL1 } from 'src/componentsV2/MenuMealCard/components/CharacteristicsL1/CharacteristicsL1'
import { useBoxOrderingModel } from 'src/hooks/useBoxOrderingModel'
import { useFlag } from '@unleash/proxy-client-react'
import { FF_HOME_LAYOUT } from 'src/constants/feature-flags'

export const RegularBundleCard = ({
  bundle,
  isEditable,
  stock,
  quantity,
  onAddItem,
  onRemoveItem,
  onBundleClick,
  planIsCompleted,
  noExtraMealPlanIsCompleted = false,
  isMealGrid = false
}) => {
  const isNewHomeLayoutEnabled = useFlag(FF_HOME_LAYOUT)
  const mainTags = getMainTags(bundle, 2)
  const outOfStock = isEditable && stock <= 0
  const showActions = isEditable && !outOfStock
  const showSummary = !isEditable && quantity > 0
  const mealsQty = getMealsQty(bundle)

  const isMobile = useIsMobile()
  const isExtra = isAddOn(bundle)
  const { isBOM } = useBoxOrderingModel()

  const noExtraMealPlanStatus =
    !noExtraMealPlanIsCompleted || (noExtraMealPlanIsCompleted && quantity > 0)

  const clickAndPreventDefault = (e, fn) => {
    e.stopPropagation && e.stopPropagation()
    e.preventDefault && e.preventDefault()
    fn()
  }

  const strikethroughPrice = bundleStrikethroughPrice(bundle, planIsCompleted)
  let chefsList = getChefs(bundle)

  if (chefsList?.length === 0) {
    chefsList = getChefsUpcomingDays(bundle)
  }

  const characteristics = getBundleCharacteristicsFromMeals(bundle)

  return (
    <a
      onClick={e => clickAndPreventDefault(e, onBundleClick)}
      className={classnames('regular-bundle-card', {
        'regular-bundle-card-sold-out': outOfStock,
        'regular-bundle-card--new-home-layout':
          isNewHomeLayoutEnabled && isMealGrid
      })}
      href={`/products/${bundle.inventoryId}?edit=true`}>
      <div className="bundle-chef-image-container">
        <div className="bundle-chef-row-container">
          <ChefsRow
            chefs={chefsList}
            colorText="-dark"
            entityType={bundle.entityType}
          />
        </div>
        <div
          className={classnames('bundle-img-wrapper', {
            'bundle-img-wrapper--new-home-layout':
              isNewHomeLayoutEnabled && isMealGrid
          })}>
          <div className="bundle-tags-wrapper">
            {mainTags?.map((tag, idx) => (
              <CuiTag
                key={idx}
                color={tag.color}
                fill="solid"
                size="small"
                className={classnames('bundle-tag', {
                  'bundle-tag-with-icon': !!tag.icon
                })}>
                {tag.icon}
                {tag.label}
              </CuiTag>
            ))}
          </div>
          <CuiMenuImage
            primary_image={bundle.primaryImageUrl || bundle.image}
            className="bundle-card-img"
          />
        </div>
      </div>
      <div className="bundle-details">
        <CardTitle
          title={bundle.name}
          subtitle={bundle.subtitle}
          onClick={e => clickAndPreventDefault(e, onBundleClick)}
          size={isMobile ? 'large' : 'medium'}
        />
        <div className="bundle-details-line">
          <CharacteristicsL1
            list={characteristics}
            mealId={bundle.inventoryId}
            size="medium"
          />
          <p className="bundle-products-text">{mealsQty} products</p>
        </div>
        {showActions && (isExtra || noExtraMealPlanStatus) && (
          <div className="bundle-actions-container">
            <div onClick={e => clickAndPreventDefault(e, () => {})}>
              <CuiMealActions
                meal={bundle}
                priceText={getBundleAddButtonText(
                  bundle,
                  planIsCompleted,
                  isExtra,
                  noExtraMealPlanIsCompleted,
                  isBOM
                )}
                quantity={quantity}
                add={() => onAddItem(bundle)}
                remove={() => onRemoveItem(bundle)}
                isEditable={isEditable}
                strikethroughPrice={strikethroughPrice}
                reachedPlanSize={noExtraMealPlanIsCompleted && !isExtra}
              />
            </div>
          </div>
        )}
        {outOfStock && (
          <div className="regular-bundle-card-sold-out-text">SOLD OUT</div>
        )}
        <div
          className={classnames('bundle-summary-container', {
            'bundle-summary-container--new-home-layout':
              isNewHomeLayoutEnabled && isMealGrid
          })}>
          {showSummary && <CuiMealSummary quantity={quantity} />}
        </div>
      </div>
    </a>
  )
}

function getMealsQty(bundle) {
  if (bundle.meals) {
    return getMealsQtyFromMenu(bundle)
  }
  return getMealsQtyFromSubscription(bundle)
}

function getMealsQtyFromSubscription(bundle) {
  const qty = bundle.items.reduce((acc, item) => {
    return acc + item.qty
  }, 0)
  return qty
}

function getMealsQtyFromMenu(bundle) {
  return bundle.meals.length
}
