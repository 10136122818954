import React from 'react'
import { useSelector } from 'react-redux'
import { Component, ComponentProps, ComponentType } from 'src/types/component'
import { BottomSheet } from 'src/pages/orders/components/RatingTab/components'
import Card from 'src/pages/preferences/components/Card/Card'
import { MealRatingCardDetail } from 'src/pages/orders/components/RatingTab/components/MealRatingCardDetail/MealRatingCardDetail'
import {
  getCurrentProductId,
  isLoadingRatingProduct
} from 'src/redux-api-bridge/ratings/selectors'
import { useRatingProduct } from 'src/hooks/useRatingProduct'
import './MealRatingCard.scss'

interface MealRatingCardProps extends ComponentProps {
  id: string
  title: string
  image: {
    name: string
  }
  onClick?: () => void
  childrenAsProps?: Component[]
  source?: string
}

export const MealRatingCard: React.FC<MealRatingCardProps> = ({
  id,
  title,
  image,
  onClick,
  children,
  childrenAsProps = [],
  source = 'rating_tab'
}) => {
  const currentProductId: number | string = useSelector(getCurrentProductId)
  const isLoading: boolean = useSelector(isLoadingRatingProduct)
  const bottomSheet = childrenAsProps.find(
    child => child?.type === ComponentType.BOTTOM_SHEET
  )

  const {
    openRatingProductModal,
    closeRatingProductModal,
    submitRatingProductModal
  } = useRatingProduct(id, title, source)

  const isBottomSheetOpen = currentProductId === id

  const handleCardClick = (e: React.MouseEvent) => {
    if (
      (e.target as HTMLElement).closest('.meal-rating-card-detail__icons') ||
      (e.target as HTMLElement).closest('.meal-rating-card-detail__ratings')
    ) {
      return
    }
    openRatingProductModal()
    onClick?.()
  }

  return (
    <>
      {!!bottomSheet && (
        <BottomSheet
          isOpen={isBottomSheetOpen}
          onClose={closeRatingProductModal}
          onSubmit={submitRatingProductModal}
          isLoading={isLoading}
          {...bottomSheet.props}
          mealProps={{
            id,
            title,
            image,
            chef: children
          }}
          className="rating-detail-bottom-sheet"
          childrenAsProps={bottomSheet.children}
        />
      )}
      <Card className="meal-rating-card">
        <MealRatingCardDetail
          id={id}
          title={title}
          image={image}
          onRate={openRatingProductModal}
          onClick={handleCardClick}
          isBottomSheetOpen={isBottomSheetOpen}>
          {children}
        </MealRatingCardDetail>
      </Card>
    </>
  )
}
