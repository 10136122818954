import { getMetaTrackingData } from '../shared/tracking'
import { getUser } from '../api'
import store from '../store'

async function getUserTrackingData() {
  try {
    // Try to get user data from store first
    const state = store.getState()
    let user = state?.user?.info || {}
    const defaultAddress = state?.api?.subscription?.info?.addresses?.[0] || {}

    // If store data is not available, fetch from API
    if (!user.email) {
      const { data } = await getUser()
      user = data?.user || {}
    }

    // Clean phone number
    const cleanPhone = user.phone?.replace(/[^\d]/g, '') || null

    return {
      email: user.email || null,
      phone_number: cleanPhone,
      first_name: user.firstname || null,
      last_name: user.lastname || null,
      zip_code: defaultAddress.postcode || user.zipcode || null,
      date_of_birth: user.birthdate || null,
      city: defaultAddress.city || null,
      state: defaultAddress.region || null
    }
  } catch (error) {
    console.error('Error getting user tracking data:', error)
    return {}
  }
}

function getPrepaidOfferData(prepaidOfferId, resurrectionCampaign) {
  if (!prepaidOfferId && !resurrectionCampaign?.prepaidCampaign) {
    return {}
  }

  return {
    prepaid_offer_id: prepaidOfferId || resurrectionCampaign?.prepaidCampaign
  }
}

function cleanTrackingData(data) {
  return Object.fromEntries(
    Object.entries(data).filter(([, value]) => value != null)
  )
}

function getCampaignData(resurrectionCampaign) {
  if (!resurrectionCampaign) {
    return {}
  }

  return {
    resurrection_campaign_id: resurrectionCampaign.id || null,
    resurrection_chef_id: resurrectionCampaign.chefId || null,
    resurrection_chef_brand_id: resurrectionCampaign.chefBrandId || null,
    resurrection_chef_brand_ids: resurrectionCampaign.chefBrandIds || null,
    resurrection_highlighted_meals:
      resurrectionCampaign.highlightedMealsIds || [],
    resurrection_prepaid_campaign: resurrectionCampaign.prepaidCampaign || null
  }
}

function getCouponData(resurrectionCampaign, customCouponCode) {
  const couponCode = resurrectionCampaign?.couponCode || customCouponCode
  if (!couponCode) {
    return {}
  }

  return {
    coupon_code: couponCode,
    is_resurrection_coupon: couponCode !== 'RES-35X6'
  }
}

function getUserData(subscriptionData, userStore) {
  return {
    subscriber_id: subscriptionData.userId,
    email: subscriptionData.email || userStore?.email,
    resurrection_count: Number(subscriptionData.resurrectionCount) || 0,
    last_resurrection_date: subscriptionData.lastResurrectionDate
      ? new Date(subscriptionData.lastResurrectionDate).toISOString()
      : null,
    is_reactivation: true,
    timestamp: new Date().toISOString(),
    context: 'subscription',
    menu_store: userStore?.name || 'unknown'
  }
}

function getUtmParams() {
  const search = new URLSearchParams(window.location.search)
  const utms = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content']
  return Object.fromEntries(
    utms.map(key => [key, search.get(key)]).filter(([, value]) => value != null)
  )
}

export async function getResurrectionTrackingData(
  subscriptionData = {},
  resurrectionCampaign = {},
  couponCode = null,
  prepaidOfferId = null
) {
  try {
    const userStore = store.getState()?.user?.info || {}

    return cleanTrackingData({
      ...getUtmParams(),
      ...getCampaignData(resurrectionCampaign),
      ...getCouponData(resurrectionCampaign, couponCode),
      ...getUserData(subscriptionData, userStore),
      ...getPrepaidOfferData(prepaidOfferId, resurrectionCampaign),
      ...(await getUserTrackingData()),
      ...getMetaTrackingData()
    })
  } catch (error) {
    console.error('Error getting resurrection tracking data:', error)
    return {}
  }
}
