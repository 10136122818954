import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useAtom } from 'jotai'

import { useQueryParams } from 'src/hooks/useQueryParams'
import { getUpcomingDays } from 'src/redux-api-bridge'
import { HowCanISkipModal } from './components/HowCanISkipModal/HowCanISkipModal'
import { PauseDeflectionModal } from 'src/components/PauseDeflectionModal/PauseDeflectionModal'
import { CancelSubscriptionWarning } from '../CancelSubscription/CancelSubscriptionWarning'
import { UnpausePlanSettings } from './components/UnpausePlanSetting'
import { PausePlanForm } from './components/PausePlanForm'
import { pauseDeflectionModalAtom } from 'src/atoms/modals/pauseDeflectionModal.atom'
import { PausePlanInfo } from './components/PausePlanInfo'
import { howCanISkipModalAtom } from 'src/atoms/modals/howCanISkipModal.atom'
import { useLastSkips } from 'src/hooks/useLastSkips'
import { useShowCancelSubscription } from 'src/hooks/useShowCancelSubscription'
import { usePausePlan } from 'src/hooks/usePausePlan'

import './PausePlanSettings.scss'

export function PausePlanSettings() {
  const dispatch = useDispatch()
  const { query, deleteQueryParam } = useQueryParams()
  const showCancelSubscription = useShowCancelSubscription()

  const [pauseDeflectionModal] = useAtom(pauseDeflectionModalAtom)
  const [howCanISipModal, setHowCanISkipModal] = useAtom(howCanISkipModalAtom)
  const [showPausePlanSection, setShowPausePlanSection] = useState(false)
  const { planIsPaused } = useLastSkips()
  const { pausePlan } = usePausePlan()

  useEffect(() => {
    dispatch(getUpcomingDays())
  }, [dispatch])

  useEffect(() => {
    // Cancel page has a "pause plan" button whose rely on this page to pause the plan.
    if (query.get('pause-plan') !== null) {
      setShowPausePlanSection(true)
    }

    if (query.get('skip-education') !== null) {
      setHowCanISkipModal({ isOpen: true })
    }

    if (query.get('fixed-pause') !== null) {
      const weeks = query.get('fixed-pause')
      pausePlan({ reason: 'Brightback fixed pause', weeks: Number(weeks) })
      deleteQueryParam('fixed-pause')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query])

  if (planIsPaused) {
    return <UnpausePlanSettings />
  }

  return (
    <>
      {pauseDeflectionModal.isOpen && <PauseDeflectionModal />}
      {howCanISipModal.isOpen && <HowCanISkipModal />}
      {showPausePlanSection ? (
        <PausePlanForm
          onCancel={() => {
            setShowPausePlanSection(false)
          }}
        />
      ) : (
        <PausePlanInfo
          onPause={() => {
            setShowPausePlanSection(true)
          }}
        />
      )}
      <div className={'cookunity__setting-section-cancellation-warning'}>
        {showCancelSubscription && <CancelSubscriptionWarning />}
      </div>
    </>
  )
}
