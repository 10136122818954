import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import {
  isB2BUserSelector,
  isBoxPlanSelector,
  isEconomicPlanSelector
} from 'src/redux-api-bridge/selectors'
import { getUserSelector } from 'src/modules/MyAccount/selectors'
import { usePlanConfiguration } from '../../hooks/usePlanConfiguration'
import { EconomicPlanTag } from '../EconomicPlanTag/EconomicPlanTag'

function MyPlanHeader() {
  const { t } = useTranslation()
  const { deliveriesPerWeek, mealsPerWeek } = usePlanConfiguration()
  const user = useSelector(getUserSelector)
  const firstName = user?.firstname ?? ''
  const lastName = user?.lastname ?? ''
  const userEmail = user?.email ?? ''
  const userFullName = [
    ...(firstName ? [firstName.trim()] : []),
    ...(lastName ? [lastName.trim()] : [])
  ].join(' ')
  return (
    <>
      <h1 className="title">{t('pages.my_plan.my_plan')}</h1>
      <p className="subtitle">
        <strong>{userFullName},</strong> {userEmail} |{' '}
        <strong>
          {`${mealsPerWeek} ${
            mealsPerWeek > 1
              ? t('pages.my_plan.meals')
              : t('pages.my_plan.meal')
          }, ${deliveriesPerWeek} ${
            deliveriesPerWeek > 1
              ? t('pages.my_plan.deliveries')
              : t('pages.my_plan.delivery')
          } · `}
        </strong>
      </p>
    </>
  )
}

function MyBoxHeader() {
  const { t } = useTranslation()
  return <h1 className="title">{t('pages.my_plan.my_box')}</h1>
}

export function MyPlanSettingsHeader() {
  const isBoxPlan = useSelector(isBoxPlanSelector)
  const isEconomicPlan = useSelector(isEconomicPlanSelector)
  const isB2B = useSelector(isB2BUserSelector)

  return (
    <>
      {isBoxPlan ? <MyBoxHeader /> : <MyPlanHeader />}
      {isEconomicPlan && !isB2B && <EconomicPlanTag />}
    </>
  )
}
