import { useState } from 'react'
import './ImpersonationPanel.scss'
import { useSelector } from 'react-redux'
import { getUserEmail } from 'src/redux-api-bridge/selectors'
import { CuiButton } from 'front-lib'
import { deleteCookie } from '../../utils/browserCookies'
import { getInitialsSelector } from 'src/components/Header/selectors'

export const ImpersonationPanel = () => {
  const userEmail = useSelector(getUserEmail)
  const [isVisible, setIsVisible] = useState(true)
  const initials = useSelector(getInitialsSelector)

  const removeImpersonate = () => {
    deleteCookie('impersonationToken')
    window.location.reload()
  }

  return (
    <div>
      {isVisible && userEmail && (
        <div className="impersonation-panel">
          <span className="close-panel" onClick={() => setIsVisible(false)}>
            X
          </span>
          <p>
            You are impersonated as:{' '}
            <span className="impersonate">{userEmail}</span>
          </p>
          <CuiButton
            onClick={() => removeImpersonate()}
            className={'add-card-button'}
            type="button"
            size="medium">
            {'Remove Impersonate'}
          </CuiButton>
        </div>
      )}
      {!isVisible && userEmail && (
        <div className="minimized" onClick={() => setIsVisible(true)}>
          {initials}
        </div>
      )}
    </div>
  )
}
