import { useAtom } from 'jotai'

import {
  changePlanModalAtom,
  ChangePlanModalAtom
} from 'src/atoms/modals/changePlanModalAtom.atom'

export function useChangePlanModal() {
  const [{ isOpen, type }, setChangePlanModal] = useAtom(changePlanModalAtom)

  function open(type = '') {
    setChangePlanModal({
      isOpen: true,
      type: type as ChangePlanModalAtom['type']
    })
  }

  function close() {
    setChangePlanModal({ isOpen: false, type: '' })
  }

  return {
    isOpen,
    type,
    open,
    close
  }
}
