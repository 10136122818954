import { rateProduct } from 'src/api/sduiService'
import { trackEventReview } from 'src/utils/rating'
import {
  rateProductFailed,
  rateProductStart,
  rateProductSucceed
} from 'src/redux-api-bridge/ratings/actions'

export const sendRateProduct = (
  orderId: number,
  productId,
  productName,
  rating,
  reasons,
  review,
  source?: string
) => async dispatch => {
  dispatch(rateProductStart())
  try {
    const res = await rateProduct(orderId, productId, {
      rating,
      reasons,
      review,
      source
    })

    const { status, reviewId } = await res.json()

    if (status === 200) {
      trackEventReview(
        { id: productId, name: productName },
        { rating, reasons, review },
        reviewId,
        source
      )
      dispatch(rateProductSucceed())
    } else {
      console.error(`Rate product from SDUI Service sent ${status} status`)
      dispatch(rateProductFailed())
    }
  } catch (err) {
    console.error(err)
    dispatch(rateProductFailed())
  }
}
