import { createAction } from 'redux-actions'

export const fetchCartForDate = createAction('FETCH_CART_FOR_DATE')
export const fetchCartForDateSucceed = createAction(
  'FETCH_CART_FOR_DATE_SUCCEED'
)
export const fetchCartForDateFailed = createAction('FETCH_CART_FOR_DATE_FAILED')
export const setCartOpen = createAction('SET_CART_IS_OPEN')
export const addProductToCart = createAction('ADD_PRODUCT_TO_CART')
export const addProductToCartSucceed = createAction(
  'ADD_PRODUCT_TO_CART_SUCCEED'
)
export const addProductToCartFailed = createAction('ADD_PRODUCT_TO_CART_FAILED')

export const removeProductFromCart = createAction('REMOVE_PRODUCT_FROM_CART')
export const removeProductFromCartSucceed = createAction(
  'REMOVE_PRODUCT_FROM_CART_SUCCEED'
)
export const removeProductFromCartFailed = createAction(
  'REMOVE_PRODUCT_FROM_CART_FAILED'
)

export const clearCart = createAction('CLEAR_CART')
export const clearCartSucceed = createAction('CLEAR_CART_SUCCEED')
export const clearCartFailed = createAction('CLEAR_CART_FAILED')

/*
___________________________________________________
|                                                 |
|                  UNIFIED CART                   |
|_________________________________________________|
*/

export const fetchUnifiedCartForDate = createAction(
  'FETCH_UNIFIED_CART_FOR_DATE'
)

export const fetchUnifiedCartForDateSucceed = createAction(
  'FETCH_UNIFIED_CART_FOR_DATE_SUCCEED'
)
export const fetchUnifiedCartForDateFailed = createAction(
  'FETCH_UNIFIED_CART_FOR_DATE_FAILED'
)

export const addProductToUnifiedCart = createAction(
  'ADD_PRODUCT_TO_UNIFIED_CART'
)
export const addProductToUnifiedCartSucceed = createAction(
  'ADD_PRODUCT_TO_UNIFIED_CART_SUCCEED'
)
export const addProductToUnifiedCartFailed = createAction(
  'ADD_PRODUCT_TO_UNIFIED_CART_FAILED'
)

export const removeProductFromUnifiedCart = createAction(
  'REMOVE_PRODUCT_FROM_UNIFIED_CART'
)
export const removeProductFromUnifiedCartSucceed = createAction(
  'REMOVE_PRODUCT_FROM_UNIFIED_CART_SUCCEED'
)
export const removeProductFromUnifiedCartFailed = createAction(
  'REMOVE_PRODUCT_FROM_UNIFIED_CART_FAILED'
)

/*
___________________________________________________
|                                                 |
|                HOME UNIFIED CART                |
|_________________________________________________|
*/

export const setUseUnifiedCart = createAction('SET_USE_UNIFIED_CART')

export const fetchHomeUnifiedCartForDate = createAction(
  'FETCH_HOME_UNIFIED_CART_FOR_DATE'
)

export const fetchHomeUnifiedCartForDateSucceed = createAction(
  'FETCH_HOME_UNIFIED_CART_FOR_DATE_SUCCEED'
)
export const fetchHomeUnifiedCartForDateFailed = createAction(
  'FETCH_HOME_UNIFIED_CART_FOR_DATE_FAILED'
)

export const addProductToHomeUnifiedCart = createAction(
  'ADD_PRODUCT_TO_HOME_UNIFIED_CART'
)
export const addProductToHomeUnifiedCartSucceed = createAction(
  'ADD_PRODUCT_TO_HOME_UNIFIED_CART_SUCCEED'
)
export const addProductToHomeUnifiedCartFailed = createAction(
  'ADD_PRODUCT_TO_HOME_UNIFIED_CART_FAILED'
)

export const removeProductFromHomeUnifiedCart = createAction(
  'REMOVE_PRODUCT_FROM_HOME_UNIFIED_CART'
)
export const removeProductFromHomeUnifiedCartSucceed = createAction(
  'REMOVE_PRODUCT_FROM_HOME_UNIFIED_CART_SUCCEED'
)
export const removeProductFromHomeUnifiedCartFailed = createAction(
  'REMOVE_PRODUCT_FROM_HOME_UNIFIED_CART_FAILED'
)

export const deleteUnifiedCarts = createAction('DELETE_UNIFIED_CARTS')
