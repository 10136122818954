import { handleActions, createAction } from 'redux-actions'

export const openTrackDeliveryPanel = createAction<TrackDeliveryPanelTab>(
  'OPEN_TRACK_DELIVERY_PANEL'
)

export const closeTrackDeliveryPanel = createAction(
  'CLOSE_TRACK_DELIVERY_PANEL'
)

export enum TrackDeliveryPanelTab {
  ORDER = 'order',
  WINE = 'wine'
}

export interface State {
  isOpen: boolean
  activeTab: TrackDeliveryPanelTab
}

export const trackDeliveryPanelReducer = handleActions(
  {
    [openTrackDeliveryPanel]: (state, { payload }): State => ({
      isOpen: true,
      activeTab: payload || TrackDeliveryPanelTab.ORDER
    }),
    [closeTrackDeliveryPanel]: (): State => ({
      isOpen: false,
      activeTab: TrackDeliveryPanelTab.ORDER
    })
  },
  {
    isOpen: false,
    activeTab: TrackDeliveryPanelTab.ORDER
  } as State
)
