export const IMG_URL_SOURCE = process.env.REACT_APP_IMG_URL_SOURCE
export const CHEF_IMG_URL_SOURCE = process.env.REACT_APP_CHEF_IMG_URL_SOURCE
export const FEATURED_TITLE = 'Collections'
export const FEATURED_CATEGORY = 111
export const HIGHEST_MEALS_PER_DELIVERY_PLAN = 16
export const ORDER_CONFIRMATION_PAGE_NAME = 'Order Confirmation'
export const ORDER_CURRENCY = 'USD'
export const MEALS_CATEGORY_TAB_ID = 0

export const USER_STATUS = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive'
}

export const BUNDLE_TAG = 'Bundle & Save'
