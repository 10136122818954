import { startsWith } from 'ramda'
import { ADDONS_CATEGORIES, MEALS_CATEGORY } from 'src/constants/menu'
import { mealsAreEquals } from 'src/utils/utils'

export const mapProductProps = product => ({
  ...product.properties,
  title: product.properties.name,
  premium_special: product.properties.isPremiumMeal,
  imageUrl: product.properties.image,
  qty: product.properties.cart.quantity,
  quantity: product.properties.cart.quantity,
  categoryId: getCategoryId(product.properties),
  meals: product.properties.items,
  premium_fee: product.properties.premiumFee,
  chef_firstname:
    product.properties.chef?.firstname || product.properties.cartChef?.name,
  chef_lastname: product.properties.chef?.lastname,
  full_path_chef_image: product.properties.chef?.image,
  short_description: product.properties.description,
  specifications_detail: product.properties.specifications
})

const getCategoryId = properties => {
  if (properties.containsMeal) return MEALS_CATEGORY
  return properties.isExtra ? ADDONS_CATEGORIES[0] : MEALS_CATEGORY
}

export const getCartFromMenuCategories = (categories, cartItems) =>
  [].concat(
    ...categories.map(c =>
      c.meals
        .filter(product =>
          cartItems.find(item => mealsAreEquals(item, product))
        )
        .map(product => {
          const itemIndex = cartItems.findIndex(item =>
            mealsAreEquals(item, product)
          )

          return {
            ...cartItems[itemIndex],
            ...product,
            qty: cartItems[itemIndex].qty,
            quantity: cartItems[itemIndex].qty,
            stock: product.stock,
            specifications_detail: product.specifications_detail || []
          }
        })
    )
  )

export const deserializeUnifiedCart = cart => ({
  cartId: cart.cart_id,
  shippingDate: cart.shipping_date,
  storeId: cart.store_id,
  ephemeral: cart.ephemeral,
  products: cart.products.map(p => ({
    inventoryId: p.inventory_id,
    quantity: p.quantity,
    qty: p.quantity,
    source: p.source
  })),
  metadata: {
    pricing: {
      isComplete: cart.metadata.pricing.is_complete,
      total: Number(Number(cart.metadata.pricing.total).toFixed(2)),
      boxPlan: cart.metadata.pricing.box_plan
        ? {
            boxIdinCompletion:
              cart.metadata.pricing.box_plan.box_id_in_completion,
            boxes: cart.metadata.pricing.box_plan.boxes.map(box => ({
              boxId: box.box_id,
              total: Number(Number(box.total).toFixed(2)),
              minPrice: Number(Number(box.min_price).toFixed(2)),
              discount: box.discount * 100,
              isCompleted: box.is_completed,
              diffToCompleteBox: Number(
                Number(box.min_price - box.total).toFixed(2)
              )
            }))
          }
        : null,
      basePlan: cart.metadata.pricing.base_plan
        ? {
            minItems: cart.metadata.pricing.base_plan.min_items
          }
        : null
    }
  }
})

export const isBundleEntity = inventoryId => startsWith('bd-', inventoryId)

export const isAddOnProduct = product =>
  isBundleEntity(product.inventoryId)
    ? !product.containsMeal
    : product.categoryId !== MEALS_CATEGORY

export enum UNIFIED_CART_PRODUCT_SOURCES {
  RECOMMENDATION = 'RECOMMENDATION'
}

const buildProductPropertiesById = (
  homeUnifiedCart,
  addedProducts
): Record<string, any> => {
  const properties = {}

  homeUnifiedCart.products.forEach(p => (properties[p.inventoryId] = p))

  homeUnifiedCart.addOns.forEach(p => (properties[p.inventoryId] = p))

  homeUnifiedCart.recommendations.forEach(p => (properties[p.inventoryId] = p))

  addedProducts.forEach(p => (properties[p.inventoryId] = p))

  return properties
}

export const splitProductsBySource = (
  unifiedCart,
  homeUnifiedCart,
  modifiedProducts = []
) => {
  const products: any = []
  const addOns: any = []
  const recommendations: any = []

  const productPropertiesById = buildProductPropertiesById(
    homeUnifiedCart,
    modifiedProducts
  )

  unifiedCart.products.forEach(cartProduct => {
    const productProperties = productPropertiesById[cartProduct.inventory_id]

    if (productProperties) {
      productProperties.qty = cartProduct.quantity
      productProperties.quantity = cartProduct.quantity
      productProperties.cart = { quantity: cartProduct.quantity }
      if (isAddOnProduct(productProperties)) {
        addOns.push(productProperties)
      } else if (
        productProperties.source === UNIFIED_CART_PRODUCT_SOURCES.RECOMMENDATION
      ) {
        recommendations.push(productProperties)
      } else {
        products.push(productProperties)
      }
    }
  })

  return { products, addOns, recommendations }
}
