import moment from 'moment'
import { pathOr } from 'ramda'
import { createSelector } from 'reselect'

export const getUserInfo = state => pathOr(null, ['api', 'user', 'info'], state)

export const userInfoIsUpdating = state =>
  pathOr(false, ['api', 'updateUserInfoStatus', 'inFlight'], state)

export const userInfoStatus = state =>
  pathOr(false, ['api', 'updateUserInfoStatus'], state)

export const personalInfoStatus = state =>
  pathOr(false, ['account', 'updatePersonalInfo'], state)

// Soon to be deprecated - use useGetUserAddresses hook insted
export const getUserAddresses = state =>
  pathOr(null, ['api', 'subscription', 'info', 'addresses'], state)

export const getUpcomingDays = state =>
  pathOr(null, ['api', 'subscription', 'upcomingDays'], state)

// Soon to be deprecated - use useGetUserAddresses hook insted
export const userAddressesIsInFlight = state =>
  pathOr(false, ['api', 'getAddressesStatus', 'inFlight'], state)

// Soon to be deprecated - use useRemoveUserAddresses hook insted
export const userDeleteAddressesIsInFlight = state =>
  pathOr(false, ['api', 'deleteAddressStatus', 'inFlight'], state)

// Soon to be deprecated - use useGetUserAddresses hook insted
export const userAddressesIsUpdating = state =>
  pathOr(false, ['api', 'updateAddressStatus', 'inFlight'], state)

export const getUserDeliveryDays = state =>
  pathOr(null, ['api', 'subscription', 'info', 'deliveryDays'], state)

export const getAvailableDeliveryDays = state =>
  pathOr([], ['api', 'subscription', 'info', 'availableDeliveryDays'], state)

export const getAvailableTimeslots = state =>
  pathOr([], ['api', 'subscription', 'timeslots'], state)

export const getZipcodesByDeliveryZone = state =>
  pathOr({}, ['api', 'zipcodesByDeliveryZone'], state)

export const userDefaultAddressStatusIsUpdating = state =>
  pathOr(false, ['api', 'setDefaultAddressStatus', 'inFlight'], state)

export const getDeliveryCount = state =>
  pathOr(0, ['api', 'subscription', 'userPlan', 'deliveries'], state)

export const getDeliveryOptions = state =>
  pathOr(0, ['api', 'subscription', 'info', 'delivery_options'], state)

export const getRing = state =>
  pathOr(0, ['api', 'subscription', 'info', 'ring'], state)

export const getDeliveryTipeId = state =>
  pathOr(null, ['api', 'subscription', 'userPlan', 'deliveryType', 'id'], state)

export const userDeliveryInfoIsUpdating = state =>
  pathOr(false, ['api', 'updateSubscriptionDaysStatus', 'inFlight'], state)

export const getUserPlanTotalPrice = state =>
  pathOr(false, ['api', 'subscription', 'userPlan', 'price'], state)

export const getIsFlexUserPlan = state => {
  const userPlanType = pathOr(
    '',
    ['api', 'subscription', 'userPlan', 'type'],
    state
  )
  return userPlanType === 'FLEX'
}

export const getChangeAddress = state => {
  return state.changeAddress
}

export const getUpdateSubscriptionDaysStatus = state => {
  return state.api.updateSubscriptionDaysStatus
}

export const getUpdateAddressStatus = state => {
  return state.api.updateAddressStatus
}

export const userInfoIsLoading = createSelector(
  [getUserInfo, userInfoIsUpdating],
  (user, userIsUpdating) => !user || userIsUpdating
)

export const userAddressesIsLoading = createSelector(
  [
    getUserAddresses,
    getZipcodesByDeliveryZone,
    userDefaultAddressStatusIsUpdating,
    userAddressesIsUpdating,
    userAddressesIsInFlight,
    userDeleteAddressesIsInFlight
  ],
  (
    addresses,
    zipcodes,
    defaultAddressIsUpdating,
    addressIsUpdating,
    addressesInFlight,
    deleteAddressesInFlight,
    creatingAddress
  ) =>
    !addresses ||
    !zipcodes.succeeded ||
    zipcodes.inFlight ||
    defaultAddressIsUpdating ||
    addressIsUpdating ||
    addressesInFlight ||
    deleteAddressesInFlight ||
    creatingAddress
)

export const userDeliveryInfoIsLoading = createSelector(
  [getUserDeliveryDays, userDeliveryInfoIsUpdating],
  (deliveryDays, isUpdating) => !deliveryDays || isUpdating
)

export const getUserFirstName = createSelector(
  [getUserInfo],
  user => (user && user.firstname) || ''
)

export const getUserLastName = createSelector(
  [getUserInfo],
  user => (user && user.lastname) || ''
)

export const getUserTelephone = createSelector(
  [getUserInfo],
  user => (user && user.phone) || ''
)
// Soon to be deprecated - use useGetUserAddresses hook insted
export const getAddresses = createSelector([getUserAddresses], addresses => {
  if (addresses) {
    return addresses.map(address => {
      const streetComponents =
        (address.street && address.street.split('\n')) || []
      const street = streetComponents.length > 0 ? streetComponents[0] : ''
      const apt = streetComponents.length > 1 ? streetComponents[1] : ''
      const { city, region } = address
      return {
        ...address,
        label: `${apt ? `${apt},` : ''} ${city}, ${region}`,
        street,
        apt
      }
    })
  }

  return [{}]
})

export const getUpComingOrders = createSelector(
  [getUpcomingDays],
  upcomingDays => {
    const upcomingDaysSorted =
      upcomingDays &&
      upcomingDays.sort((a, b) => {
        const aTime = moment(a.date, 'YYYY-MM-DD')
        const bTime = moment(b.date, 'YYYY-MM-DD')

        if (aTime.isBefore(bTime)) return -1
        if (bTime.isBefore(aTime)) return 1
        return 0
      })
    const currentDate = moment()
    const upcomingOrderFiltered =
      upcomingDaysSorted &&
      upcomingDaysSorted.reduce(
        (accum, elem) =>
          !elem.skip &&
          elem.order &&
          moment(elem.date).isSameOrAfter(currentDate) &&
          elem.canEdit
            ? [...accum, { order: elem.order, cutoff: elem.cutoff }]
            : accum,
        []
      )
    return upcomingOrderFiltered && upcomingOrderFiltered
  }
)

export const getRescheduledUpcomingDays = createSelector(
  [getUpcomingDays],
  (upcomingDays = []) => {
    const currentDate = moment()

    return (
      upcomingDays &&
      upcomingDays.filter(
        day => moment(day.date).isSameOrAfter(currentDate) && day.rescheduled
      )
    )
  }
)

export const getValidZipcodes = createSelector(
  [getZipcodesByDeliveryZone],
  zipcodesByDeliveryZone => {
    const { zipcodes } = zipcodesByDeliveryZone
    if (zipcodes) {
      const keys = zipcodes && Object.keys(zipcodes)
      const zipcodesFormated = keys.reduce(
        (accum, key) => [...accum, ...zipcodes[key]],
        []
      )
      return zipcodesFormated
    }
    return []
  }
)

export const getUserDeliveryInfo = createSelector(
  [getUserDeliveryDays, getAvailableDeliveryDays],
  (deliveryDays, availableDeliveryDays) => {
    if (deliveryDays) {
      const day = deliveryDays.length > 0 ? deliveryDays[0] : null
      const start = day && moment(day.time_start, 'HH:mm').format('h:mm A')
      const end = day && moment(day.time_end, 'HH:mm').format('h:mm A')
      return {
        selectedDeliveryDays: deliveryDays.map(delivery =>
          availableDeliveryDays.find(day => day.day === delivery.day)
        ),
        selectedTimeslot: {
          label: day ? `${start} - ${end}` : '',
          value: day ? `${start} - ${end}` : '',
          start: day ? day.time_start : '',
          end: day ? day.time_end : ''
        }
      }
    }
    return null
  }
)

export const getTimeslots = createSelector([getAvailableTimeslots], timeslots =>
  timeslots.map(t => ({
    ...t,
    value: t.label
  }))
)
