import './Coachmark.scss'
import { CuiButton } from 'front-lib'
import { CoachmarkState } from 'src/types/coachmark'
import { ReactComponent as Close } from '../../assets/svg/close-v2.svg'

type Props = {
  coachmark: CoachmarkState
  onClick: () => void
  onClose: () => void
}

export const CoachmarkTooltip = ({ coachmark, onClick, onClose }: Props) => {
  return (
    <div
      className={`coachmark-tooltip coachmark-tooltip-${coachmark.tooltipPos}`}>
      <div className="coachmark-tooltip-header">
        <h3>{coachmark.title}</h3>
        <Close className="coachmark-tooltip-header-close" onClick={onClose} />
      </div>
      <p>{coachmark.content}</p>
      <CuiButton
        className="coachmark-tooltip-button"
        fill="solid"
        color="primary"
        size="small"
        onClick={onClick}>
        Got It!
      </CuiButton>
    </div>
  )
}
