export interface Benefit {
  benefit: string
  detail: string
}

export interface SpecialOfferMembership {
  title: string
  description: string
  bannerText: string
  checkoutSource: string
  primaryCta: string
}

export interface MembershipData {
  activation: MembershipActivation
  membership: Membership
}

export interface MembershipUsedAllowedToSkip {
  isAllowedToSkipMembership: {
    isAllowed: boolean
  }
}

export interface MembershipAllowedToSee {
  isAllowedToSeeMembership: {
    isAllowed: boolean
  }
}

export interface MembershipDiscount {
  rate: number
  imageUrl: string
  source: string
  expiration: string
  maxUses: number
  totalWithDiscount: number
}

export interface Membership {
  id?: number | null
  name?: string | null
  cost?: number
  active?: boolean | null
  deliveryDiscountRate?: number | null
  premiumMeals?: number | null
  addAndSave?: boolean | null
  discount?: MembershipDiscount | null
  /* Total accumulated savings across all periods */
  accumulatedSavings?: number | null
  skip?: {
    from: string | null
    to: string | null
    isActive: boolean | null
  }
}

export interface MembershipActivation {
  membershipId: number | null
  active: boolean | null
  startDate: string | null
  endDate: string | null
  stopDate: string | null
  /* Accumulated savings for the current active membership period */
  accumulatedSavings: number | null
  skip?: {
    from: string | null
    to: string | null
    isActive: boolean | null
  }
}

export type Step =
  | 'introduction'
  | 'benefits'
  | 'checkout'
  | 'confirmation'
  | 'cancel'
  | 'closed'
  | 'pause'
  | 'paused'
export interface State {
  isOpen: boolean
  membership: Membership[]
  inFlight: boolean
  error: boolean
  succeded: boolean
}

export interface MembershipType {
  type: Membership
  payload?: {
    membership?: number | string
  }
}

export enum MembershipOverlayName {
  introduction = 'UnityPass Introduction',
  benefits = 'UnityPass Benefits',
  checkout = 'UnityPass Checkout',
  welcome = 'UnityPass Welcome',
  cancel = 'UnityPass Cancel',
  pause = 'UnityPass Pause',
  paused = 'UnityPass Paused'
}

export enum MembershipOverlayActions {
  discover = 'Discover Unity Pass benefits',
  cancel = 'Cancel Unity Pass',
  noThanks = 'No Thanks',
  joinNow = 'Join now',
  rejoinNow = 'Rejoin now',
  confirm = 'Confirm purchase',
  nevermind = 'Never mind',
  yesCancelNow = 'Yes, cancel now',
  pause = 'Pause Unity Pass for one month',
  goBackToHomePage = 'Go back to home page',
  gotIt = 'Got it'
}

export interface MembershipBenefitModalData {
  title: string
  description: string
  list: {
    title: string
    items: string[]
  }
  points: number
  footer: string
}

interface MembershipBenefitCTA {
  text: string
  type: 'link' | 'modal'
  url: string
  data?: MembershipBenefitModalData
  confirmationProps?: {
    preConfirmationText: string
    postConfirmationText: string
    postConfirmationTitle?: string
  }
}

export interface MembershipBenefitData {
  ids: (number | string)[]
  type: 'benefit' | 'event'
  event?: {
    date: string
    location: string
    time: string
    tickets: number
  }
  title: string
  subtitle: string
  description: string
  imageProps?: {
    url: string
    position?: {
      desktop: string
      mobile: string
    }
  }
  primaryButton: MembershipBenefitCTA
  secondaryButton?: MembershipBenefitCTA
}

export interface MembershipBenefit {
  id: number
  benefit: string
  points: number
  active: boolean
  startDate: string
  endDate: string | null
  createdAt: string
  updatedAt: string
}
