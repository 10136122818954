import { useSelector } from 'react-redux'
import { CuiButton, CuiSpinner } from 'front-lib'
import { useAtom } from 'jotai'
import moment from 'moment'

import { ChangePlanSuccessBottomSheet } from './components/ChangePlanSuccessBottomSheet'
import { ChangePlanSuccessModal } from './components/ChangePlanSuccessModal'
import { PlanOptionCard } from '../PlanOptionCard/PlanOptionCard'
import { changePlanSuccessModalAtom } from 'src/atoms/modals/changePlanSuccessModalAtom.atom'
import { useIsMobile } from 'src/hooks/useIsMobile'
import { selectedDeliverySelector } from 'src/redux/slices/selectedDelivery/selectors'
import { useGetUserDelivery } from 'src/hooks/useGetUserDelivery'

import './ChangePlanSuccessModalOverlay.scss'
import { getUserPlanSelector } from '../../../../redux-api-bridge/selectors'
import { getSubscriptionDataStatus } from '../../../../modules/MyDeliveries/selectors'

export function ChangePlanSuccessModalOverlay() {
  const isMobile = useIsMobile()
  const { inFlight } = useSelector(getSubscriptionDataStatus)
  const selectedDelivery = useSelector(selectedDeliverySelector)
  const [{ isOpen, shippingDate }, setChangePlanSuccessModal] = useAtom(
    changePlanSuccessModalAtom
  )

  const { delivery, loading } = useGetUserDelivery({
    shippingDate: selectedDelivery?.date
  })
  const userDefaultPlan = useSelector(getUserPlanSelector)

  const Modal = isMobile ? ChangePlanSuccessBottomSheet : ChangePlanSuccessModal

  function handleClose() {
    setChangePlanSuccessModal({ isOpen: false, shippingDate: '' })
  }

  const title = shippingDate ? (
    <>
      <span className="title__action">Done! </span>
      Your changes have been saved.
    </>
  ) : (
    'You have successfully modified your plan!'
  )

  const subtitle = shippingDate
    ? `Plan details for your ${moment(shippingDate).format('MMM. D')} delivery`
    : 'Your selected plan'

  const plan = shippingDate ? delivery.plan : userDefaultPlan

  return (
    <Modal
      className="change-plan-success-modal"
      onClose={handleClose}
      isOpen={isOpen}>
      <div className="change-plan-success-modal--container">
        {((loading || inFlight || !delivery?.plan) && (
          <CuiSpinner
            className="change-plan-success-modal__spinner"
            width="30px"
          />
        )) || (
          <>
            <div className="title">{title}</div>
            <div className="description">
              <div className="description--text">{subtitle}</div>
              <PlanOptionCard plan={plan} type="plain" selected={true} />
              <CuiButton fill="outline" onClick={handleClose}>
                Close
              </CuiButton>
            </div>
          </>
        )}
      </div>
    </Modal>
  )
}
