import * as actions from './actions'
import { fetchOrdersSwimlanes } from 'src/api/sduiService'

const DayType = {
  orderCompleted: 'orderCompleted',
  orderBeingPrepared: 'orderBeingPrepared',
  partialCart: 'partialCart',
  menuAvailable: 'menuAvailable',
  lastOrder: 'lastOrder'
}

export const getOrdersSwimlanes = utm => dispatch => {
  dispatch(actions.fetchSwimlanes())
  fetchOrdersSwimlanes(utm)
    .then(res => res.json())
    .then(data => {
      const swimlanes = data.children
        .filter(
          child =>
            child.props.dayType === DayType.menuAvailable ||
            child.props.dayType === DayType.orderCompleted ||
            child.props.dayType === DayType.orderBeingPrepared ||
            child.props.dayType === DayType.partialCart
        )
        .reduce((prev, curr) => {
          const sw =
            curr.children.find(child => child.type === 'SWIMLANES')?.children ||
            curr.children ||
            []
          return {
            ...prev,
            [curr.props.date]: sw
          }
        }, {})

      const ratingTab = data.children.find(
        tab => tab.props.dayType === DayType.lastOrder
      )

      dispatch(
        actions.fetchSwimlanesSucceed({
          views: swimlanes,
          ratingTab
        })
      )
    })
    .catch(err => {
      console.error(err)
      dispatch(actions.fetchSwimlanesFailed())
    })
}
