import { FILTER_IDS } from '../Filters/constants'
import { ReactComponent as FullMenu } from '../../../../../assets/svg/fullMenu/full-menu.svg'
import { ReactComponent as AddAndSave } from '../../../../../assets/svg/fullMenu/Add-and-Save.svg'
import { ReactComponent as MainWithSides } from '../../../../../assets/svg/fullMenu/main-with-sides.svg'
import { ReactComponent as SaladsAndBowls } from '../../../../../assets/svg/fullMenu/salads-and-bowls.svg'
import { ReactComponent as PastaAndNoodles } from '../../../../../assets/svg/fullMenu/pasta-and-noodles.svg'
import { ReactComponent as TacosAndBurritos } from '../../../../../assets/svg/fullMenu/tacos-and-burritos.svg'
import { ReactComponent as Veggie } from '../../../../../assets/svg/fullMenu/veggie.svg'
import { ReactComponent as Sandwiches } from '../../../../../assets/svg/fullMenu/sandwiches.svg'
import { ReactComponent as FMPizza } from '../../../../../assets/svg/fullMenu/pizza.svg'
import { ReactComponent as SoupsAndStews } from '../../../../../assets/svg/fullMenu/soups-and-stews.svg'
import { ReactComponent as Breakfast } from '../../../../../assets/svg/fullMenu/breakfast.svg'
import { ReactComponent as Sides } from '../../../../../assets/svg/fullMenu/sides.svg'
import { ReactComponent as ProteinOnly } from '../../../../../assets/svg/fullMenu/protein-only.svg'
import { ReactComponent as Treats } from '../../../../../assets/svg/fullMenu/treats.svg'
import { ReactComponent as Snacks } from '../../../../../assets/svg/fullMenu/snacks.svg'
import { ReactComponent as Drinks } from '../../../../../assets/svg/fullMenu/drinks.svg'
import { ReactComponent as Bundles } from '../../../../../assets/svg/fullMenu/bundles.svg'
import { ReactComponent as SaucesAndCondiments } from '../../../../../assets/svg/fullMenu/sauces-and-condiments.svg'
import { ReactComponent as Wellness } from '../../../../../assets/svg/fullMenu/wellness.svg'
import { ReactComponent as GreatForLunch } from '../../../../../assets/svg/fullMenu/great-lunch.svg'
import { ReactComponent as KidsMeals } from '../../../../../assets/svg/fullMenu/kids-meals.svg'
import { ReactComponent as EnergyBoost } from '../../../../../assets/svg/fullMenu/energy-boost.svg'
import { ReactComponent as FineDining } from '../../../../../assets/svg/fullMenu/fine-dining.svg'
import { ReactComponent as SeasonalFall } from '../../../../../assets/svg/fullMenu/seasonal-fall.svg'
import { ReactComponent as SeasonalSpring } from '../../../../../assets/svg/fullMenu/seasonal-spring.svg'
import { ReactComponent as SeasonalSummer } from '../../../../../assets/svg/fullMenu/seasonal-summer.svg'
import { ReactComponent as SeasonalWinter } from '../../../../../assets/svg/fullMenu/seasonal-winter.svg'
import { ReactComponent as TrendingNow } from '../../../../../assets/svg/fullMenu/trending-now.svg'
import { ReactComponent as TopRated } from '../../../../../assets/svg/fullMenu/top-rated.svg'
import { ReactComponent as WhatsNew } from '../../../../../assets/svg/fullMenu/whats-new.svg'
import { ReactComponent as GreenEating } from '../../../../../assets/icons/menuby/green-eating.svg'
import { ReactComponent as ForYou } from '../../../../../assets/icons/menuby/foryou.svg'
import { ReactComponent as YouveTried } from '../../../../../assets/icons/menuby/you_ve-tried.svg'
import { ReactComponent as Favorites } from '../../../../../assets/icons/menuby/favorites.svg'
import { ReactComponent as Collections } from '../../../../../assets/svg/fullMenu/collections.svg'
import { ReactComponent as BestPerformers } from '../../../../../assets/svg/fullMenu/best-performers.svg'
import { ReactComponent as CleanEating } from '../../../../../assets/icons/menuby/clean-eating.svg'
import { ReactComponent as Glp1 } from '../../../../../assets/svg/fullMenu/glp-1.svg'
import { ReactComponent as Wine } from '../../../../../assets/svg/fullMenu/wine.svg'

import { MENU_CATEGORIES } from '../../../../../constants/menu'
import Noodles from '../../../../../assets/icons/menuby/Noodles.svg'
import Salad from '../../../../../assets/icons/menuby/Salad.svg'
import Soup from '../../../../../assets/icons/menuby/Soup.svg'
import Taco from '../../../../../assets/icons/menuby/Taco.svg'
import Pizza from '../../../../../assets/icons/menuby/pizza.svg'
import Burgers from '../../../../../assets/icons/menuby/Burgers.svg'
import Veggies from '../../../../../assets/svg/filters/sides-veggies-salads.svg'
import Grains from '../../../../../assets/svg/filters/sides-grains-starch.svg'
import Pasta from '../../../../../assets/svg/filters/sides-pasta-bread.svg'
import FingerFood from '../../../../../assets/svg/filters/sides-finger-food.svg'
import SidesSoup from '../../../../../assets/svg/filters/sides-soups.svg'
import Protein from '../../../../../assets/svg/filters/sides-protein.svg'
import MainWithSidesSvg from '../../../../../assets/svg/fullMenu/main-with-sides.svg'
import Other from '../../../../../assets/svg/fullMenu/full-menu.svg'
import KidsMealsSvg from '../../../../../assets/svg/fullMenu/kids-meals.svg'

export const OTHER_SUBTYPE = {
  // id:
  name: 'Other',
  pattern: 'other',
  label: 'Other',
  image: Other,
  fieldToFilter: 'typesTags',
  filterByPattern: 'other'
}

export const FULL_MENU_PRODUCT_TYPES = {
  MEALS: 0,
  BUNDLES: 1
}

const getSeasonalQuickFilter = ({ svg, pattern }) => {
  return {
    id: FILTER_IDS.FULL_MENU_SEASONAL,
    name: 'seasonal',
    label: 'Seasonal',
    quickfilterSvg: svg,
    fieldToFilter: 'filter_by',
    pattern,
    urlPattern: -1, // not defined
    urlQFPattern: '-1', // not defined
    header: {
      title: 'Seasonal',
      description: "Savor the season's best flavors and freshest ingredients.",
      svg
    }
  }
}

export const FULL_MENU_ALL_CATEGORIES = {
  MEALS: {
    productType: FULL_MENU_PRODUCT_TYPES.MEALS,
    label: 'Meals',
    categoryId: MENU_CATEGORIES.MEALS,
    filterByPattern: 'meals',
    image: 'https://cu-media.imgix.net/media/categories/icons/meals.svg',
    filterTypes: [
      {
        // id:
        name: 'Main With Sides',
        pattern: 'main_and_sides',
        label: 'Main With Sides',
        image: MainWithSidesSvg,
        fieldToFilter: 'typesTags',
        filterByPattern: 'main_and_sides'
      },
      {
        // id:
        name: 'Pasta',
        pattern: 'Pasta & Noodles',
        label: 'Pasta',
        image: Noodles,
        fieldToFilter: 'typesTags',
        filterByPattern: 'pasta_and_noodles'
      },
      {
        // id:
        name: 'Salads & Bowls',
        pattern: 'Salads & Bowls',
        label: 'Salads & Bowls',
        image: Salad,
        fieldToFilter: 'typesTags',
        filterByPattern: 'salad_and_bowls'
      },
      {
        // id:
        name: 'Soups & Stews',
        pattern: 'Soups & Stews',
        label: 'Soups & Stews',
        image: Soup,
        fieldToFilter: 'typesTags',
        filterByPattern: 'soups_and_stews'
      },
      {
        //id:
        name: 'Tacos & Burritos',
        pattern: 'Tacos & Burritos',
        label: 'Tacos & Burritos',
        image: Taco,
        fieldToFilter: 'typesTags',
        filterByPattern: 'tacos_and_burritos'
      },
      {
        //id:
        name: 'Sandwiches',
        pattern: 'Sandwich & Wraps, Burgers',
        label: 'Sandwiches',
        image: Burgers,
        fieldToFilter: 'typesTags',
        filterByPattern: 'sandwiches'
      },
      {
        // id:
        name: 'Pizza',
        pattern: 'Pizza',
        label: 'Pizza',
        image: Pizza,
        fieldToFilter: 'typesTags',
        filterByPattern: 'pizza'
      },
      {
        // id:
        name: 'Kids',
        pattern: 'Kids',
        label: 'Kids',
        image: KidsMealsSvg,
        fieldToFilter: 'typesTags',
        filterByPattern: 'family_favorites'
      }
    ]
  },
  BREAKFAST: {
    productType: FULL_MENU_PRODUCT_TYPES.MEALS,
    label: 'Breakfast',
    categoryId: MENU_CATEGORIES.BREAKFAST,
    filterByPattern: 'breakfast',
    image: 'https://cu-media.imgix.net/media/categories/icons/breakfast.svg',
    filterTypes: [
      {
        // id:
        name: 'Eggs',
        pattern: 'Eggs',
        label: 'Eggs',
        image:
          'https://static.cookunity.com/cross/front-lib/icons/breakfast-eggs.svg',
        filterByPattern: 'eggs'
      },
      {
        // id:
        name: 'Pancakes & Waffles',
        pattern: 'Pancakes & Waffles',
        label: 'Pancakes & Waffles',
        image:
          'https://static.cookunity.com/cross/front-lib/icons/breakfast-pancakes-waffles.svg',
        filterByPattern: 'pancakes_&_waffles'
      },
      {
        // id:
        name: 'Yogurt, Granola & Oatmeal',
        pattern: 'Yogurt',
        label: 'Yogurt, Granola & Oatmeal',
        image:
          'https://static.cookunity.com/cross/front-lib/icons/breakfast-yogurt-granola.svg',
        filterByPattern: 'yogurt,_granola_&_oatmeal'
      },
      {
        // id:
        name: 'Bread & Pastries',
        pattern: 'Bread & Pastries',
        label: 'Bread & Pastries',
        image:
          'https://static.cookunity.com/cross/front-lib/icons/breakfast-bread-pastries.svg',
        filterByPattern: 'bread_&_pastries'
      },
      {
        // id:
        name: 'Smoothies & Juices',
        pattern: 'Smoothies & Juices',
        label: 'Smoothies & Juices',
        image:
          'https://static.cookunity.com/cross/front-lib/icons/breakfast-juices.svg',
        filterByPattern: 'smoothies_&_juices'
      },
      {
        // id:
        name: 'Bars',
        pattern: 'Bars',
        label: 'Bars',
        image:
          'https://static.cookunity.com/cross/front-lib/icons/breakfast-bar.svg',
        filterByPattern: 'bars'
      }
    ]
  },
  BUNDLES: {
    productType: FULL_MENU_PRODUCT_TYPES.BUNDLES,
    label: 'Bundles',
    categoryId: MENU_CATEGORIES.BUNDLES,
    image: 'https://cu-media.imgix.net/media/categories/icons/bundles.svg',
    filterTypes: null,
    filterByPattern: 'bundles'
  },
  SIDES: {
    productType: FULL_MENU_PRODUCT_TYPES.MEALS,
    label: 'Sides',
    categoryId: MENU_CATEGORIES.SIDES,
    filterByPattern: 'mix_and_match',
    image: 'https://cu-media.imgix.net/media/categories/icons/sides.svg',
    filterTypes: [
      {
        // id:
        name: 'Veggies & Salads',
        pattern: 'Veggies & Salads',
        label: 'Veggies & Salads',
        image: Veggies,
        filterByPattern: 'veggies_&_salads'
      },
      {
        // id:
        name: 'Grains & Starch',
        pattern: 'Grains & Starches',
        label: 'Grains & Starch',
        image: Grains,
        filterByPattern: 'grains_&_starches'
      },
      {
        // id:
        name: 'Pasta & Bread',
        pattern: 'Pasta & Bread',
        label: 'Pasta & Bread',
        image: Pasta,
        filterByPattern: 'pasta_&_bread'
      },
      {
        // id:
        name: 'Finger Food',
        pattern: 'Finger Foods',
        label: 'Finger Food',
        image: FingerFood,
        filterByPattern: 'finger_foods'
      },
      {
        // id:
        name: 'Side Soups',
        pattern: 'Side Soups',
        label: 'Side Soups',
        image: SidesSoup,
        filterByPattern: 'side_soups'
      },
      {
        // id:
        name: 'Protein',
        pattern: 'Protein',
        label: 'Protein',
        image: Protein,
        filterByPattern: 'protein_only'
      }
    ]
  },
  DRINKS: {
    productType: FULL_MENU_PRODUCT_TYPES.MEALS,
    label: 'Juices & Smoothies',
    categoryId: MENU_CATEGORIES.DRINKS,
    image:
      'https://d1ureao5dcqpo4.cloudfront.net/media/categories/icons/drinks.svg',
    filterTypes: null,
    filterByPattern: 'drinks'
  },
  TREATS: {
    productType: FULL_MENU_PRODUCT_TYPES.MEALS,
    label: 'Treats',
    categoryId: MENU_CATEGORIES.TREATS,
    image:
      'https://d1ureao5dcqpo4.cloudfront.net/media/categories/icons/treats.svg',
    filterTypes: null,
    filterByPattern: 'treats'
  },
  SAUCES: {
    productType: FULL_MENU_PRODUCT_TYPES.MEALS,
    label: 'Sauces & Condiments',
    categoryId: MENU_CATEGORIES.SAUCES,
    filterByPattern: 'sauces_and_condiments',
    image: 'https://cu-media.imgix.net/media/categories/icons/sauces.svg',
    filterTypes: [
      {
        // id:
        name: 'Sauces',
        pattern: 'Sauces',
        label: 'Sauces',
        image:
          'https://static.cookunity.com/cross/front-lib/icons/qf_Sauces.svg',
        filterByPattern: 'sauces_and_condiments'
      },
      {
        // id:
        name: 'Dips',
        pattern: 'Dips',
        label: 'Dips',
        image: 'https://static.cookunity.com/cross/front-lib/icons/qf_Dips.svg',
        filterByPattern: 'dips'
      },
      {
        // id:
        name: 'Dressings',
        pattern: 'Condiments & Dressings',
        label: 'Dressings',
        image:
          'https://static.cookunity.com/cross/front-lib/icons/qf_Condiments_Dressings.svg',
        filterByPattern: 'condiments_&_dressings'
      },
      {
        // id:
        name: 'Spices',
        pattern: 'Spices & Seasonings',
        label: 'Spices',
        image:
          'https://static.cookunity.com/cross/front-lib/icons/qf_Spices_Seasonings.svg',
        filterByPattern: 'spices'
      }
    ]
  },
  SNACKS: {
    productType: FULL_MENU_PRODUCT_TYPES.MEALS,
    label: 'Snacks',
    categoryId: MENU_CATEGORIES.SNACKS,
    image:
      'https://d1ureao5dcqpo4.cloudfront.net/media/categories/icons/snacks.svg',
    filterTypes: null,
    filterByPattern: 'snacks'
  },
  WINES: {
    productType: FULL_MENU_PRODUCT_TYPES.MEALS, // We use same meal card
    label: 'Wines',
    categoryId: MENU_CATEGORIES.WINES,
    filterByPattern: 'wine',
    image: 'https://cu-media.imgix.net/media/categories/icons/wine_v2.svg',
    filterTypes: [
      {
        // id:
        name: 'Red Wine',
        pattern: 'red_wine',
        label: 'Red',
        image: 'https://cu-media.imgix.net/media/categories/icons/wine_v2.svg',
        filterByPattern: 'red_wine'
      },
      {
        // id:
        name: 'White Wine',
        pattern: 'white_wine',
        label: 'White',
        image: 'https://cu-media.imgix.net/media/categories/icons/wine_v2.svg',
        filterByPattern: 'white_wine'
      },
      {
        // id:
        name: 'Rosé Wine',
        pattern: 'pink_wine',
        label: 'Rosé',
        image: 'https://cu-media.imgix.net/media/categories/icons/wine_v2.svg',
        filterByPattern: 'pink_wine'
      },
      {
        // id:
        name: 'Themed Sets',
        pattern: 'wine_bundle',
        label: 'Themed Sets',
        image: 'https://cu-media.imgix.net/media/categories/icons/wine_v2.svg',
        filterByPattern: 'wine_bundle'
      }
    ]
  },
  OTHER: {
    productType: FULL_MENU_PRODUCT_TYPES.MEALS,
    label: 'Other',
    categoryId: null,
    image:
      'https://d1ureao5dcqpo4.cloudfront.net/media/categories/icons/full-menu.svg',
    filterTypes: null,
    filterByPattern: 'other'
  }
}

export const FULL_MENU_COLLECTION_QUICKFILTER = {
  id: FILTER_IDS.FULL_MENU_COLLECTIONS,
  name: 'full-menu-collections',
  label: 'Collections',
  quickfilterSvg: <Collections />,
  header: null,
  urlPattern: -1, // not defined
  urlQFPattern: '-1', // not defined
  categories: null
}

export const FULL_MENU_BEST_PERFORMER_QUICKFILTER = {
  id: FILTER_IDS.FULL_MENU_BEST_PERFORMERS,
  name: 'full-menu-best-performers',
  label: 'Top 100',
  quickfilterSvg: <BestPerformers />,
  fieldToFilter: 'filter_by',
  pattern: 'best_performers',
  urlPattern: -1, // not defined
  urlQFPattern: '-1', // not defined
  header: {
    title: 'Top 100',
    description: 'All of our most popular dishes, ready for you to discover.',
    svg: <BestPerformers />
  },
  // WORKAROUND TO FILTER BY PATTERN IN FIRST RENDER
  quickFilterSelected: {
    id: FILTER_IDS.FULL_MENU_BEST_PERFORMERS,
    pattern: 'best_performers',
    label: 'Top 100'
  }
}

export const FULL_MENU_QUICKFILTERS = [
  {
    id: FILTER_IDS.FULL_MENU_ALL,
    name: 'full-menu',
    label: 'Full Menu',
    quickfilterSvg: <FullMenu />,
    header: null,
    urlPattern: -1, // not defined
    urlQFPattern: '-1', // not defined
    categories: [
      FULL_MENU_ALL_CATEGORIES.MEALS,
      FULL_MENU_ALL_CATEGORIES.BREAKFAST,
      FULL_MENU_ALL_CATEGORIES.BUNDLES,
      FULL_MENU_ALL_CATEGORIES.SIDES,
      FULL_MENU_ALL_CATEGORIES.DRINKS,
      FULL_MENU_ALL_CATEGORIES.TREATS,
      FULL_MENU_ALL_CATEGORIES.SAUCES,
      FULL_MENU_ALL_CATEGORIES.SNACKS,
      FULL_MENU_ALL_CATEGORIES.WINES
    ]
  },
  {
    id: FILTER_IDS.FULL_MENU_ADD_AND_SAVE,
    name: 'add-and-save',
    label: 'Add & Save',
    quickfilterSvg: <AddAndSave />,
    fieldToFilter: 'filter_by',
    pattern: 'discount_by_sku',
    urlPattern: -1, // not defined
    urlQFPattern: 'add-and-save',
    header: {
      title: 'Add & Save',
      description:
        'Limited-time savings on some of our chefs’ top dishes — same quality, better prices!',
      svg: <AddAndSave />
    }
  },
  {
    id: FILTER_IDS.FULL_MENU_YOUVE_TRIED,
    name: 'yove-tried',
    label: 'Order Again',
    quickfilterSvg: <YouveTried />,
    fieldToFilter: 'filter_by',
    pattern: 'yove_tried',
    urlPattern: -1, // not defined
    urlQFPattern: 'youve-tried', // not defined
    header: {
      title: 'Order Again',
      description: 'Discover past picks available to order this week.',
      svg: <YouveTried />
    }
  },
  {
    id: FILTER_IDS.FULL_MENU_FAVORITES,
    name: 'Favorites',
    label: 'Favorites',
    quickfilterSvg: <Favorites />,
    fieldToFilter: 'filter_by',
    pattern: 'favorite',
    urlPattern: -1, // not defined
    urlQFPattern: 'favorites', // not defined
    header: {
      title: 'Favorites',
      description: 'The ultimate culinary playlist, curated by you.',
      svg: <Favorites />
    }
  },
  {
    id: FILTER_IDS.FULL_MENU_WINES,
    name: 'wines',
    label: 'Wine',
    quickfilterSvg: <Wine />,
    fieldToFilter: 'filter_by',
    pattern: 'wine',
    urlPattern: MENU_CATEGORIES.WINES,
    urlQFPattern: 'wine',
    header: {
      title: 'Wine',
      description:
        'Extraordinary pours that turn meals into moments. Wine orders arrive separately—with FREE delivery!',
      svg: <Wine />
    },
    categories: [FULL_MENU_ALL_CATEGORIES.WINES]
  },
  {
    id: FILTER_IDS.FULL_MENU_WHATS_NEW,
    name: 'whats-new',
    label: "What's New",
    quickfilterSvg: <WhatsNew />,
    fieldToFilter: 'filter_by',
    pattern: 'whats_new',
    urlPattern: -1, // not defined
    urlQFPattern: '-1', // not defined
    header: {
      title: "What's New",
      description: 'Explore the latest creations from our brilliant chefs. ',
      svg: <WhatsNew />
    }
  },
  {
    id: FILTER_IDS.FULL_MENU_WELLNESS,
    name: 'wellness',
    label: 'Wellness',
    quickfilterSvg: <Wellness />,
    fieldToFilter: 'filter_by',
    pattern: 'healthy',
    urlPattern: -1, // not defined
    urlQFPattern: '-1', // not defined
    header: {
      title: 'Wellness',
      description:
        'Stock up on nourishing bowls, vibrant salads, and other feel-good meals.',
      svg: <Wellness />
    }
  },
  {
    id: FILTER_IDS.FULL_MENU_GREEN_EATING,
    name: 'green-eating',
    label: 'Green Line',
    quickfilterSvg: <GreenEating />,
    fieldToFilter: 'filter_by',
    pattern: 'green_eating',
    urlPattern: -1, // not defined
    urlQFPattern: 'green-line',
    header: {
      title: 'Green Line',
      description:
        'Delicious and wholesome meals with the lowest impact on the environment.',
      svg: <GreenEating />
    }
  },
  {
    id: FILTER_IDS.FULL_MENU_CLEAN_EATING,
    name: 'clean-eating',
    label: 'Clean Line',
    quickfilterSvg: <CleanEating />,
    fieldToFilter: 'filter_by',
    pattern: 'clean_eating',
    urlPattern: -1, // not defined
    urlQFPattern: 'clean-line',
    header: {
      title: 'Clean Line',
      description:
        'Minimally processed meals made with ingredients of the highest standards.',
      svg: <CleanEating />
    }
  },
  {
    id: FILTER_IDS.FULL_MENU_GLP_1,
    name: 'glp-1',
    label: 'GLP-1 Balance',
    quickfilterSvg: <Glp1 />,
    fieldToFilter: 'filter_by',
    pattern: 'glp_1',
    urlPattern: -1, // not defined
    urlQFPattern: '-1', // not defined
    header: {
      title: 'GLP-1 Balance',
      description:
        'Nutrient-dense, fiber-packed, and high-protein portions crafted to support weight management goals.',
      svg: <Glp1 />
    }
  },
  {
    id: FILTER_IDS.FULL_MENU_GREAT_FOR_LUNCH,
    name: 'great-for-lunch',
    label: 'Great for Lunch',
    quickfilterSvg: <GreatForLunch />,
    fieldToFilter: 'filter_by',
    pattern: 'great_for_lunch',
    urlPattern: -1, // not defined
    urlQFPattern: '-1', // not defined
    header: {
      title: 'Great for Lunch',
      description:
        'These perfect midday meals are light yet energizing, plus quick and easy to eat.',
      svg: <GreatForLunch />
    }
  },
  {
    id: FILTER_IDS.FULL_MENU_FAMILY_FAVORITES,
    name: 'family-favorites',
    label: 'Kids',
    quickfilterSvg: <KidsMeals />,
    fieldToFilter: 'filter_by',
    pattern: 'family_favorites',
    urlPattern: -1, // not defined
    urlQFPattern: 'kids',
    header: {
      title: 'Kids',
      description:
        'Wholesome, kid-approved meals thoughtfully crafted by our chefs for your little foodies.',
      svg: <KidsMeals />
    }
  },
  {
    id: FILTER_IDS.FULL_MENU_TOP_RATED,
    name: 'top-rated',
    label: 'Top Rated',
    quickfilterSvg: <TopRated />,
    fieldToFilter: 'filter_by',
    pattern: 'top_rated',
    urlPattern: -1, // not defined
    urlQFPattern: '-1', // not defined
    header: {
      title: 'Top Rated',
      description:
        "Chef crafted, customer approved! Discover our community's top favorites.",
      svg: <TopRated />
    }
  },
  {
    id: FILTER_IDS.FULL_MENU_MAIN_WITH_SIDES,
    name: 'main-with-sides',
    label: 'Main With Sides',
    quickfilterSvg: <MainWithSides />,
    fieldToFilter: 'filter_by',
    pattern: 'main_and_sides',
    urlPattern: -1, // not defined
    urlQFPattern: '-1', // not defined
    header: {
      title: 'Main With Sides',
      description:
        'Balanced, satisfying, and flavor-packed plates ideal for lunch or dinner.',
      svg: <MainWithSides />
    }
  },
  {
    id: FILTER_IDS.FULL_MENU_PASTA_AND_NOODLES,
    name: 'pasta-and-noodles',
    label: 'Pasta & Noodles',
    quickfilterSvg: <PastaAndNoodles />,
    fieldToFilter: 'filter_by',
    pattern: 'pasta_and_noodles',
    urlPattern: -1, // not defined
    urlQFPattern: 'pasta', // not defined
    header: {
      title: 'Pasta & Noodles',
      description:
        'From rich pastas in all different shapes and sauces to a variety of savory Asian noodles.',
      svg: <PastaAndNoodles />
    }
  },
  {
    id: FILTER_IDS.FULL_MENU_SIDES,
    name: 'sides',
    label: 'Sides',
    quickfilterSvg: <Sides />,
    fieldToFilter: 'filter_by',
    pattern: 'mix_and_match',
    urlPattern: MENU_CATEGORIES.SIDES,
    urlQFPattern: 'sides',
    header: {
      title: 'Sides',
      description:
        'Mix and match these side dishes to enhance a meal or build your perfect feast.',
      svg: <Sides />
    },
    categories: [FULL_MENU_ALL_CATEGORIES.SIDES]
  },
  {
    id: FILTER_IDS.FULL_MENU_PROTEIN_ONLY,
    name: 'proteinOnly',
    label: 'Protein Only',
    quickfilterSvg: <ProteinOnly />,
    fieldToFilter: 'filter_by',
    pattern: 'protein_only',
    urlPattern: -1, // not defined
    urlQFPattern: 'protein-only',
    header: {
      title: 'Protein Only',
      description:
        'Add these protein-only dishes to enrich any meal or use as a base for homecooking.',
      svg: <ProteinOnly />
    }
  },
  {
    id: FILTER_IDS.FULL_MENU_BREAKFAST,
    name: 'breakfast',
    label: 'Breakfast',
    quickfilterSvg: <Breakfast />,
    fieldToFilter: 'filter_by',
    pattern: 'breakfast',
    urlPattern: MENU_CATEGORIES.BREAKFAST,
    urlQFPattern: 'breakfast',
    header: {
      title: 'Breakfast',
      description: 'Chef-crafted bites to kickstart your day with flavor.',
      svg: <Breakfast />
    },
    categories: [FULL_MENU_ALL_CATEGORIES.BREAKFAST]
  },
  {
    id: FILTER_IDS.FULL_MENU_SNACKS,
    name: 'snacks',
    label: 'Snacks',
    quickfilterSvg: <Snacks />,
    fieldToFilter: 'filter_by',
    pattern: 'snacks',
    urlPattern: MENU_CATEGORIES.SNACKS,
    urlQFPattern: 'snacks',
    header: {
      title: 'Snacks',
      description:
        'Snack happier anytime anywhere with chef-made bites, dips, platters, and more.',
      svg: <Snacks />
    }
  },
  {
    id: FILTER_IDS.FULL_MENU_TREATS,
    name: 'treats',
    label: 'Treats',
    quickfilterSvg: <Treats />,
    fieldToFilter: 'filter_by',
    pattern: 'treats',
    urlPattern: MENU_CATEGORIES.TREATS,
    urlQFPattern: '-1', // not defined
    header: {
      title: 'Treats',
      description: 'A little something special for a more scrumptious day.',
      svg: <Treats />
    }
  },
  {
    id: FILTER_IDS.FULL_MENU_SALADS_AND_BOWLS,
    name: 'salads-and-bowls',
    label: 'Salads & Bowls',
    quickfilterSvg: <SaladsAndBowls />,
    fieldToFilter: 'filter_by',
    pattern: 'salad_and_bowls',
    urlPattern: -1, // not defined
    urlQFPattern: 'salads-bowls', // not defined
    header: {
      title: 'Salads & Bowls',
      description:
        'For when you’re craving something light, bright, and refreshing.',
      svg: <SaladsAndBowls />
    }
  },
  {
    id: FILTER_IDS.FULL_MENU_VEGGIE,
    name: 'veggie',
    label: 'Veggie',
    quickfilterSvg: <Veggie />,
    fieldToFilter: 'filter_by',
    pattern: 'veggie',
    urlPattern: -1, // not defined
    urlQFPattern: 'veg-meals',
    header: {
      title: 'Veggie',
      description:
        'Mouthwatering (not boring!) vegetarian and vegan meals, sides, and snacks.',
      svg: <Veggie />
    }
  },
  {
    id: FILTER_IDS.FULL_MENU_TACOS_AND_BURRITOS,
    name: 'tacos-and-burritos',
    label: 'Tacos & Burritos',
    quickfilterSvg: <TacosAndBurritos />,
    fieldToFilter: 'filter_by',
    pattern: 'tacos_and_burritos',
    urlPattern: -1, // not defined
    urlQFPattern: 'tacos-burritos', // not defined
    header: {
      title: 'Tacos & Burritos',
      description:
        'Whether folded or rolled, these deliciously stuffed tortillas will satisfy any craving.',
      svg: <TacosAndBurritos />
    }
  },
  {
    id: FILTER_IDS.FULL_MENU_TRENDING_NOW,
    name: 'trending-now',
    label: 'Trending now',
    quickfilterSvg: <TrendingNow />,
    fieldToFilter: 'filter_by',
    pattern: 'trending_now',
    urlPattern: -1, // not defined
    urlQFPattern: '-1', // not defined
    header: {
      title: 'Trending Now',
      description: "Don't miss out on our bestsellers this week.",
      svg: <TrendingNow />
    }
  },
  {
    id: FILTER_IDS.FULL_MENU_SANDWICHES,
    name: 'sandwiches',
    label: 'Sandwiches',
    quickfilterSvg: <Sandwiches />,
    fieldToFilter: 'filter_by',
    pattern: 'sandwiches',
    urlPattern: -1, // not defined
    urlQFPattern: 'sandwiches',
    header: {
      title: 'Sandwiches',
      description: 'Immensely enjoyable handheld eats for any time of the day.',
      svg: <Sandwiches />
    }
  },
  {
    id: FILTER_IDS.FULL_MENU_PIZZA,
    name: 'pizza',
    label: 'Pizza',
    quickfilterSvg: <FMPizza />,
    fieldToFilter: 'filter_by',
    pattern: 'pizza',
    urlPattern: -1, // not defined
    urlQFPattern: 'pizza',
    header: {
      title: 'Pizza',
      description: 'Pizza! But make it gourmet.',
      svg: <FMPizza />
    }
  },
  getSeasonalQuickFilter({
    svg: <SeasonalFall />,
    pattern: 'seasonal_fall'
  }),
  getSeasonalQuickFilter({
    svg: <SeasonalSpring />,
    pattern: 'seasonal_spring'
  }),
  getSeasonalQuickFilter({
    svg: <SeasonalSummer />,
    pattern: 'seasonal_summer'
  }),
  getSeasonalQuickFilter({
    svg: <SeasonalWinter />,
    pattern: 'seasonal_winter'
  }),
  {
    id: FILTER_IDS.FULL_MENU_SOUPS_AND_STEWS,
    name: 'soups-and-stews',
    label: 'Soups & Stews',
    quickfilterSvg: <SoupsAndStews />,
    fieldToFilter: 'filter_by',
    pattern: 'soups_and_stews',
    urlPattern: -1, // not defined
    urlQFPattern: 'soups',
    header: {
      title: 'Soups & Stews',
      description: 'Comfort in a bowl, whenever you need it.',
      svg: <SoupsAndStews />
    }
  },
  {
    id: FILTER_IDS.FULL_MENU_FINE_DINING,
    name: 'fine-dining',
    label: 'Fine Dining',
    quickfilterSvg: <FineDining />,
    fieldToFilter: 'filter_by',
    pattern: 'fine_dining',
    urlPattern: -1, // not defined
    urlQFPattern: '-1', // not defined
    header: {
      title: 'Fine Dining',
      description: 'Indulge in superior ingredients and elevated techniques.',
      svg: <FineDining />
    }
  },
  {
    id: FILTER_IDS.FULL_MENU_DRINKS,
    name: 'drinks',
    label: 'Juices & Smoothies',
    quickfilterSvg: <Drinks />,
    fieldToFilter: 'filter_by',
    pattern: 'drinks',
    urlPattern: MENU_CATEGORIES.DRINKS,
    urlQFPattern: '-1', // not defined
    header: {
      title: 'Juices & Smoothies',
      description:
        'Get a satisfying and refreshing boost, at home or on the go.',
      svg: <Drinks />
    }
  },
  {
    id: FILTER_IDS.FULL_MENU_BUNDLES,
    name: 'bundles',
    label: 'Bundles',
    quickfilterSvg: <Bundles />,
    fieldToFilter: 'filter_by',
    pattern: 'bundles',
    urlPattern: MENU_CATEGORIES.BUNDLES,
    urlQFPattern: '-1', // not defined
    header: {
      title: 'Bundles',
      description:
        'Thoughtfully curated sets highlighting the best flavors from our chefs and creators.',
      svg: <Bundles />
    }
  },
  {
    id: FILTER_IDS.FULL_MENU_SAUCES_AND_CONDIMENTS,
    name: 'sauces-and-condiments',
    label: 'Sauces & Condiments',
    quickfilterSvg: <SaucesAndCondiments />,
    fieldToFilter: 'filter_by',
    pattern: 'sauces_and_condiments',
    urlPattern: MENU_CATEGORIES.SAUCES,
    urlQFPattern: '-1', // not defined
    header: {
      title: 'Sauces & Condiments',
      description:
        'Upgrade your meals with rich flavors crafted by the chefs themselves.',
      svg: <SaucesAndCondiments />
    }
  },
  {
    id: FILTER_IDS.FULL_MENU_ENERGY_BOOST,
    name: 'energy-boost',
    label: 'Energy Boost',
    quickfilterSvg: <EnergyBoost />,
    fieldToFilter: 'filter_by',
    pattern: 'energy_boost',
    urlPattern: -1, // not defined
    urlQFPattern: '-1', // not defined
    header: {
      title: 'Energy Boost',
      description:
        'Protein and carb-loaded meals to support physical activity and muscle recovery.',
      svg: <EnergyBoost />
    }
  }
]

export const FULL_MENU_FOR_YOU = {
  id: FILTER_IDS.FULL_MENU_FOR_YOU,
  name: 'for-you',
  label: 'For You',
  quickfilterSvg: <ForYou />,
  fieldToFilter: 'filter_by',
  pattern: 'for_you',
  urlPattern: -1, // not defined
  urlQFPattern: 'for-you',
  header: {
    title: 'For You',
    description: 'Delicious meal recommendations, personalized to your taste.',
    svg: <ForYou />
  }
}
